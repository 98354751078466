import React from "react";
import { Table, Thead, Tr, Th, Td, Tbody } from "@chakra-ui/react";
import _ from "lodash";
import moment from "moment";

export default function EstimateBillDetailLists({ history }) {
  const genEstimateBillLists = () =>
    _.map(history, (_history, index) => (
      <Tr key={_history._id}>
        {/* <Td>{index + 1}</Td> */}
        <Td>
          {" "}
          {moment(_history.date).format("DD/MM/YYYY")}{" "}
          <span>
            {_history.holiday > 0 ? (
              <span className="text-xs text-green-400">วันหยุด</span>
            ) : (
              <span className="text-xs text-red-300">วันปกติ</span>
            )}
          </span>
        </Td>
        <Td>
          {_history.all == 0 ? (
            <div> - </div>
          ) : (
            <>{parseFloat(_history.all).toFixed(2) + " หน่วย"}</>
          )}{" "}
        </Td>
        <Td>
          {" "}
          {_history.onPeak == 0 ? (
            <div> - </div>
          ) : (
            <>{parseFloat(_history.onPeak).toFixed(2) + " หน่วย"}</>
          )}{" "}
        </Td>
        <Td>
          {" "}
          {_history.offPeak == 0 ? (
            <div> - </div>
          ) : (
            <>{parseFloat(_history.offPeak).toFixed(2) + " หน่วย"}</>
          )}{" "}
        </Td>
        <Td>
          {" "}
          {_history.holiday == 0 ? (
            <div> - </div>
          ) : (
            <>{parseFloat(_history.holiday).toFixed(2) + " หน่วย"}</>
          )}{" "}
        </Td>
        <Td>{parseFloat(_history.power).toFixed(2) + " บาท"}</Td>
      </Tr>
    ));
  return (
    <div>
      
      <Table size="sm" variant="striped" className="whitespace-no-wrap">
        <Thead>
          <Tr>
            {/* <Th>#</Th> */}
            <Th>วันที่</Th>
            <Th>ทั้งหมด</Th>
            <Th>ช่วง On Peak</Th>
            <Th>ช่วง Off Peak</Th>
            <Th>ช่วง Holiday</Th>
            <Th>ประมาณค่าพลังงานไฟฟ้ารายวัน</Th>
          </Tr>
        </Thead>
        <Tbody>{genEstimateBillLists()}</Tbody>
      </Table>
    </div>
  );
}
