import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import _ from "lodash";
import { Box, Button, FormControl, FormLabel, Switch } from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import SpinnerLoading from "components/Loading/SpinnerLoading";
import * as actions from "../../../redux/actions";

export default function EditNotify({ systemInfo }) {
  const { register, handleSubmit, reset } = useForm();
  const dispatch = useDispatch();
  useEffect(() => {
    return () => {};
  }, []);
  const handleChangeSwitch = async (value) => {
    console.log(value);
    const data = { enable_notify: value };
    console.log(data);

    await dispatch(actions.systemInfoPut(systemInfo._id, data));
  };
  if (systemInfo) {
    return (
      <Box padding="4" borderRadius="lg" borderWidth="1px">
        {/* <form onSubmit={handleSubmit(onSubmit)}> */}
        <div className="w-full lg:w-6/12 ">
          <h6 className=" text-base mb-2  font-bold uppercase font-sans ">
            การแจ้งเตือนระบบ
          </h6>
          <div className="flex flex-wrap">
            <div className="w-full px-4 ">
              <FormControl display="flex">
                <FormLabel>
                  <div className="block uppercase  text-sm font-bold ">
                    เปิด/ปิด
                  </div>
                </FormLabel>
                <Switch
                  size="lg"
                  defaultChecked={systemInfo.enable_notify}
                  onChange={(e) => {
                    handleChangeSwitch(e.target.checked);
                  }}
                />
              </FormControl>
            </div>
          </div>
        </div>
      </Box>
    );
  } else {
    return <SpinnerLoading />;
  }
}
