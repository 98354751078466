import React, { useState } from 'react'
import {
    Badge,
    Box,
    Button,
    Checkbox,
    FormControl,
    Input,
    Select,
    Table,
    Tbody,
    Th,
    Thead,
    Tr,
} from '@chakra-ui/react'
import { useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import _ from 'lodash'

import * as actions from '../../../redux/actions'
import Config from '../../../config'

export default function ChartMakingComponent({
    index,
    stationData,
    chartData,
}) {
    const [isEditorMode, setIsEditorMode] = useState(false)
    const { register, handleSubmit } = useForm()
    const [rendered, setRendered] = useState(false)
    const [chartSource, setChartSource] = useState(chartData.chartSource)
    const [isAllowSensorOnSituation, setIsAllowSensorOnSituation] = useState(
        chartData.labelOnSituation?.enable
    )

    const dispatch = useDispatch()

    const genRefStringList = () => {
        return _.map(stationData.sensors, (sensor) => (
            <option value={sensor.refString}>{sensor.refString}</option>
        ))
    }

    const genVariableList = () => {
        return _.map(stationData.variables, (variable) => (
            <option value={variable.id}>{variable.variableName}</option>
        ))
    }

    // Merged the Sensor Data and Make new Station Data to Database
    const prepareUpdateDatabase = (data) => {
        const selectedChart = stationData.charts[index]
        const mergedChartData = _.merge(selectedChart, data)
        let editedStation = stationData
        editedStation.charts[index] = mergedChartData
        dispatch(actions.stationPut(stationData._id, editedStation)).then(
            () => {
                dispatch(actions.stationAll())
            }
        )
        setIsEditorMode(false)
    }

    const prepareDeleteChart = () => {
        let confirm = window.confirm('ยืนยันการลบตัวแปร')
        if (confirm) {
            let editedStation = stationData
            if (editedStation.charts) {
                editedStation.charts.splice(index, 1)
            }
            dispatch(actions.stationPut(stationData._id, editedStation)).then(
                () => {
                    dispatch(actions.stationAll())
                }
            )
            setIsEditorMode(false)
        }
    }

    return (
        <Box borderRadius="lg" padding="4" className="bg-white my-2">
            <form onSubmit={handleSubmit(prepareUpdateDatabase)}>
                <div className="flex gap-3">
                    <div className="w-5/6">
                        <h5 className="text-lg font-bold font-sans">
                            {chartData.chartLabel}{' '}
                            <Badge colorScheme="teal" size="sm">
                                {chartData.chartType} Chart
                            </Badge>{' '}
                        </h5>
                    </div>

                    <div className="flex gap-1 ">
                        {isEditorMode === true ? (
                            <>
                                <Button
                                    size="sm"
                                    type="button"
                                    colorScheme="gray"
                                    onClick={() => setIsEditorMode(false)}
                                >
                                    ยกเลิก
                                </Button>{' '}
                                <Button
                                    size="sm"
                                    colorScheme="green"
                                    type="submit"
                                >
                                    บันทึก
                                </Button>
                            </>
                        ) : (
                            <>
                                <Button
                                    size="sm"
                                    colorScheme="yellow"
                                    onClick={() => setIsEditorMode(true)}
                                    type="button"
                                >
                                    แก้ไข
                                </Button>
                                <Button
                                    size="sm"
                                    colorScheme="red"
                                    type="button"
                                    onClick={() => prepareDeleteChart()}
                                >
                                    ลบ
                                </Button>
                            </>
                        )}
                    </div>
                </div>

                <div className="w-full">
                    {isEditorMode === true ? (
                        <>
                            <FormControl>
                                <label className="text-sm font-bold">
                                    ชื่อกราฟ
                                </label>
                                <Input
                                    type="text"
                                    name="chartLabel"
                                    size="sm"
                                    defaultValue={chartData.chartLabel}
                                    ref={register}
                                />
                            </FormControl>
                            <FormControl>
                                <label className="text-sm font-bold">
                                    รูปแบบกราฟ
                                </label>
                                <Select
                                    defaultValue={chartData.chartType}
                                    name="chartType"
                                    ref={register}
                                    size="sm"
                                >
                                    <option value="">เลือกรูปแบบกราฟ</option>
                                    <option
                                        value={
                                            Config.ComponentType.ChartType.area
                                        }
                                    >
                                        กราฟพื้นที่
                                    </option>
                                    <option
                                        value={
                                            Config.ComponentType.ChartType.line
                                        }
                                    >
                                        กราฟเส้น
                                    </option>
                                    {/* <option
                                        value={
                                            Config.ComponentType.ChartType.bar
                                        }
                                    >
                                        กราฟแท่ง
                                    </option> */}
                                </Select>
                            </FormControl>
                            <FormControl>
                                <label className="text-sm font-bold">
                                    ที่มาของค่าในกราฟ
                                </label>
                                <Select
                                    defaultValue={chartData.chartSource}
                                    value={chartSource}
                                    onChange={(event) => {
                                        setChartSource(event.target.value)
                                        setRendered(!rendered)
                                    }}
                                    name="chartSource"
                                    ref={register}
                                    size="sm"
                                >
                                    <option value="">เลือกที่มาของค่า</option>
                                    <option
                                        value={
                                            Config.TypeOfSource.GaugeSource
                                                .sensor
                                        }
                                    >
                                        เซ็นเซอร์
                                    </option>
                                    <option
                                        value={
                                            Config.TypeOfSource.GaugeSource
                                                .variable
                                        }
                                    >
                                        ตัวแปร
                                    </option>
                                </Select>
                            </FormControl>

                            {chartSource ===
                            Config.TypeOfSource.GaugeSource.sensor ? (
                                <FormControl>
                                    <label className="text-sm font-bold">
                                        เซ็นเซอร์ที่ต้องการเชื่อมต่อ
                                    </label>
                                    <Select
                                        defaultValue={chartData.chartSensorRef}
                                        name="chartSensorRef"
                                        ref={register}
                                        size="sm"
                                    >
                                        <option value="">
                                            เลือกเซ็นเซอร์ที่ต้องการเชื่อมต่อ
                                        </option>
                                        {genRefStringList()}
                                    </Select>
                                </FormControl>
                            ) : (
                                <FormControl>
                                    <label className="text-sm font-bold">
                                        ตัวแปรที่ต้องการจะเชื่อมต่อ
                                    </label>
                                    <Select
                                        defaultValue={chartData.chartVariableId}
                                        name="chartVariableId"
                                        ref={register}
                                        size="sm"
                                    >
                                        <option value="">
                                            เลือกตัวแปรที่ต้องการเชื่อมต่อ
                                        </option>
                                        {genVariableList()}
                                    </Select>
                                </FormControl>
                            )}
                            <FormControl>
                                <label className="text-sm font-bold">
                                    ชื่อแกน Y ของกราฟ
                                </label>
                                <Input
                                    type="text"
                                    name="valueLabel"
                                    size="sm"
                                    defaultValue={chartData.valueLabel}
                                    ref={register}
                                />
                                <label className="text-sm font-bold">
                                    หน่วยของค่าในแกน Y
                                </label>
                                <Input
                                    type="text"
                                    name="valueUnit"
                                    size="sm"
                                    defaultValue={chartData.valueUnit}
                                    ref={register}
                                />
                            </FormControl>
                            <FormControl>
                                <label className="text-sm font-bold">
                                    รูปแบบเส้น / ความโค้งเส้น
                                </label>
                                <Select
                                    defaultValue={chartData.chartCurve}
                                    name="chartCurve"
                                    ref={register}
                                    size="sm"
                                >
                                    <option value="">เลือกรูปแบบเส้น</option>
                                    <option
                                        value={
                                            Config.ComponentType.ChartCurve
                                                .smooth
                                        }
                                    >
                                        เส้นโค้ง Smooth
                                    </option>
                                    <option
                                        value={
                                            Config.ComponentType.ChartCurve
                                                .straight
                                        }
                                    >
                                        เส้นตรง
                                    </option>
                                    <option
                                        value={
                                            Config.ComponentType.ChartCurve
                                                .stepline
                                        }
                                    >
                                        Stepline
                                    </option>
                                </Select>
                            </FormControl>
                            <FormControl>
                                <Checkbox
                                    defaultChecked={
                                        chartData.labelOnSituation?.enable
                                    }
                                    name="labelOnSituation.enable"
                                    ref={register}
                                    onChange={(event) =>
                                        setIsAllowSensorOnSituation(
                                            event.target.checked
                                        )
                                    }
                                >
                                    แทนที่ค่าในแกนด้วยคำ
                                </Checkbox>
                                <br />
                                {isAllowSensorOnSituation ? (
                                    <>
                                        <label className="text-sm font-bold">
                                            เมื่อมีค่าเป็น{' '}
                                            <Badge colorScheme="green">
                                                True
                                            </Badge>{' '}
                                        </label>
                                        <Input
                                            type="text"
                                            name="labelOnSituation.labelOnHigh"
                                            size="sm"
                                            defaultValue={
                                                chartData.labelOnSituation
                                                    ?.labelOnHigh
                                            }
                                            ref={register}
                                        />
                                        <label className="text-sm font-bold">
                                            เมื่อมีค่าเป็น{' '}
                                            <Badge colorScheme="red">
                                                False
                                            </Badge>{' '}
                                        </label>
                                        <Input
                                            size="sm"
                                            type="text"
                                            name="labelOnSituation.labelOnLow"
                                            defaultValue={
                                                chartData.labelOnSituation
                                                    ?.labelOnLow
                                            }
                                            ref={register}
                                        />
                                    </>
                                ) : (
                                    <></>
                                )}
                            </FormControl>
                        </>
                    ) : (
                        <>
                            <div className="flex flex-wrap ">
                                <div className="lg:w-2/3">
                                    <ul className="list-inside list-disc ml-6">
                                        <li>
                                            <span className="text-sm font-semibold text-gray-700">
                                                ที่มาของค่าในกราฟ :{' '}
                                            </span>
                                            <span className="text-sm font-semibold text-blue-700">
                                                {chartData.chartSource}
                                            </span>
                                        </li>
                                        <li>
                                            <span className="text-sm font-semibold text-gray-700">
                                                ค่าที่นำมาแสดง :{' '}
                                            </span>
                                            <span className="text-sm font-semibold text-blue-700">
                                                {chartData.chartSource ===
                                                Config.TypeOfSource.GaugeSource
                                                    .sensor
                                                    ? chartData.chartSensorRef
                                                    : chartData.chartVariableId}
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </form>
        </Box>
    )
}
