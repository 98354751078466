import React, { useState, useEffect } from "react";
import SpinnerLoading from "components/Loading/SpinnerLoading";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../redux/actions/";
import EstimateBillLists from "./components/EstimateBillLists";
import _ from "lodash";
export default function ElectricBillEstimator() {
  const dispatch = useDispatch();
  const electricConsumption = useSelector((state) => state.electricConsumption);
  const systemInfo = useSelector((state) => state.system);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    dispatch(actions.electricConsumptionAll()).then(() => {
      dispatch(actions.systemInfoGet()).then(() => {
        setIsLoading(true);
      });
    });

    return () => {};
  }, []);
  return isLoading ? (
    <div className="relative flex flex-col min-w-0 break-words bg-gray-100 w-full mb-6 shadow-lg rounded">
      <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
        <div className="flex flex-wrap items-center">
          <div className="relative w-full max-w-full flex-grow flex-1">
            <h6 className="uppercase text-gray-500 mb-1 text-xs font-semibold font-sans">
              ประมาณการบิลค่าไฟฟ้า
            </h6>
            <h2 className="text-gray-800 text-2xl font-bold font-sans">
              ประมาณการบิลค่าไฟฟ้า
            </h2>
          </div>
        </div>
        <div className="p-4 w-full">
          {_.size(electricConsumption.arr) ? (
            <div className="overflow-x-auto">
              <EstimateBillLists
                systemInfo={systemInfo}
                electricConsumption={electricConsumption.arr}
              />
            </div>
          ) : (
            <div className="flex justify-center p-4">
              <div>ไม่มีข้อมูล</div>
            </div>
          )}
        </div>
      </div>
    </div>
  ) : (
    <SpinnerLoading />
  );
}
