import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import _ from 'lodash'
import { Button, Checkbox, FormControl } from '@chakra-ui/react'
import { useSelector, useDispatch } from 'react-redux'
import SpinnerLoading from 'components/Loading/SpinnerLoading'
import { useParams, useHistory } from 'react-router-dom'
import hash from 'object-hash'

import * as actions from '../../../../redux/actions'
import ImageUploading from '../../../../components/ImageUpload/ImageUploading'

export default function EditStation() {
  const { register, handleSubmit, reset } = useForm()
  const [uploadStationImage, setUploadStationImage] = useState()
  const [isRequestEditImage, setIsRequestEditImage] = useState(false)

  const [isLoading, setIsLoading] = useState(false)
  const params = useParams()
  const station = useSelector((state) => state.station)
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    dispatch(actions.stationGet(params.id))
    return () => { }
  }, [params])

  useEffect(() => {
    setIsLoading(true)
    return () => { }
  }, [])

  const onSubmit = async (data, e) => {
    let confirm = window.confirm('ยืนยันการแก้ไขข้อมูลสถานี')
    if (confirm) {
      e.preventDefault()
      console.log('From Data', data)
      if (isRequestEditImage) {
        const imageName = hash({
          data: data.image[0].name,
          date: new Date(),
        })
        const imageURL = await ImageUploading(imageName, data.image[0])
        data.image = imageURL
      } else {
        data.image = data.image
      }
      dispatch(actions.stationPut(data._id, data)).then(() => {
        dispatch(actions.stationAll())
        history.goBack()
      })
    }
  }

  if (station && isLoading) {
    return (
      <>
        <div className="container mx-auto px-4 h-full">
          <div className="flex content-center items-center justify-center h-full">
            <div className="w-full lg:w-6/12 px-4">
              <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-100 border-0">
                <div className="rounded-t mb-0 px-6 py-6">
                  <div className="text-center mb-3">
                    <h6 className="text-gray-600 text-base font-sans font-bold">
                      แก้ไขสถานีน้ำประปา
                                        </h6>
                  </div>
                  <hr className="mt-6 border-b-1 border-gray-400" />
                </div>
                <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <h6 className="text-gray-500 text-base font-sans mt-3 mb-6 font-bold uppercase">
                      ข้อมูลสถานี
                                        </h6>
                    <div className="flex flex-wrap">
                      <div className="w-full px-4">
                        <div className="relative w-full mb-3">
                          <input
                            name="_id"
                            ref={register}
                            defaultValue={
                              station._id
                            }
                            hidden
                          />
                          <label
                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            ชื่อสถานี
                                                    </label>
                          <input
                            name="name"
                            type="text"
                            defaultValue={
                              station.name
                            }
                            required
                            ref={register}
                            className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                            placeholder="สถานีที่1"
                          />
                        </div>
                      </div>

                      <div className="w-full px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase  text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            รายละเอียดของสถานี
                                                    </label>
                          <textarea
                            name="description"
                            type="textarea"
                            defaultValue={
                              station.description
                            }
                            ref={register}
                            className="px-3 py-3  placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                            placeholder="สถานีประจำหมู่บ้าน ใกล้วัด ..."
                          />
                        </div>
                      </div>

                      <div className="w-full px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            ที่ตั้งสถานี
                                                    </label>

                          <input
                            name="location"
                            type="text"
                            defaultValue={
                              station.location
                            }
                            ref={register}
                            className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                            placeholder="ถ.กาญจนวณิชย์ อ.หาดใหญ่ จ.สงขลา"
                          />
                        </div>
                      </div>

                      <div className="w-full px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            รูปภาพสถานี
                                                    </label>
                          <FormControl>
                            <Checkbox
                              onChange={(event) =>
                                setIsRequestEditImage(
                                  event.target
                                    .checked
                                )
                              }
                            >
                              เปลี่ยนรูปภาพ
                                                        </Checkbox>
                          </FormControl>

                          {isRequestEditImage && (
                            <input
                              type="file"
                              name="image"
                              ref={register}
                            />
                          )}
                        </div>
                      </div>

                      <div className="w-full px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            ชื่ออ้างอิงสถานี
                                                    </label>
                          <label
                            className="block  text-gray-500 text-xs font-normal mb-2"
                            htmlFor="grid-password"
                          >
                            เป็นข้อมูล Topic ที่ใช้เชื่อมต่อกับระบบ MQTT Server ถ้าท่านไม่ทราบข้อมูลตรงนี้
                            กรุณาติดต่อผู้ดูแลระบบ
                                                    </label>
                          <input
                            name="externalRef"
                            type="text"
                            required
                            defaultValue={
                              station.externalRef
                            }
                            ref={register}
                            className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                            placeholder="001"
                          />
                        </div>
                      </div>

                      {/** End of Input */}
                    </div>

                    <div className="text-center px-4 gap-0 py-3 ">
                      <Button
                        isFullWidth
                        colorScheme="blue"
                        variant="solid"
                        type="submit"
                      >
                        บันทึก{' '}
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  } else {
    return <SpinnerLoading />
  }
}
