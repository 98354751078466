import React, { useEffect, useState } from 'react'
import { Switch, Route, Redirect, useHistory } from 'react-router-dom'

import { useDispatch, useSelector } from 'react-redux'
import * as actions from '../redux/actions'
import { ErrorBoundary } from 'react-error-boundary'

// components
import Sidebar from 'components/common/Sidebar/Sidebar-System'
import FooterAdmin from 'components/common/Footers/FooterAdmin.js'
import SpinnerLoading from 'components/Loading/SpinnerLoading'
import Fallback from '../components/error/Fallback'

// views

// Management
import ManagementUsers from '../views/system/management-users/ManagementUsers'
import ManagementStations from '../views/system/management-statations/ManagementStations'
import ManagementStationTemplate from '../views/system/management-template/ManagementTemplate'
import ManagementDashboard from '../views/system/management-dashboard/ManagementDashboard'
import ManagementCharts from '../views/system/management-statations/Pages/ManagementCharts'
import ManagementSystemInfo from '../views/system/management-system/ManagementSystem'
import ManagementVariables from '../views/system/management-statations/Pages/ManagementVariables'
import ManagementConnections from '../views/system/management-connections/ManagementConnections'
import ManagementNotify from '../views/system/management-notify/ManagementNotify'

// Create
import CreateUsers from 'views/system/CreateUser'
import CreateStation from 'views/system/management-statations/Pages/CreateStation'
import CreateSensor from 'views/system/management-statations/Pages/AddSensor'
import CreateSensorInTemplate from 'views/system/management-template/AddSensor'
import CreateTemplate from 'views/system/management-template/CreateTemplate'

import EditStation from '../views/system/management-statations/Pages/EditStation'
import EditSensor from '../views/system/management-statations/Pages/EditSensor'
import EditTemplateSensor from '../views/system/management-template/EditSensor'
import EditDiagramOrder from '../views/system/management-dashboard/Pages/EditDiagramOrder'
import EditGaugeOrder from '../views/system/management-dashboard/Pages/EditGaugeOrder'
import EditChartOrder from '../views/system/management-dashboard/Pages/EditChartOrder'
import GaugeList from '../views/system/management-dashboard/Pages/GaugeList'
import DiagramList from '../views/system/management-dashboard/Pages/DiagramList'

import * as UserRole from '../config/UserRoles'
export default function SystemLayout() {
    const history = useHistory()
    const authorize_role = [
        UserRole.ROLES.USER,
        UserRole.ROLES.GENERAL,
        UserRole.ROLES.ADMIN,
        UserRole.ROLES.SUPER_USER,
    ] // Role ที่ได้รับอนุญาต

    const storage_remember = JSON.parse(window.localStorage.eiot_remember) // ข้อมูลสำหรับ authorize จาก  local_storage
    const dispatch = useDispatch()
    const me = useSelector((state) => state.me) // ข้อมูลสำหรับ authorize จาก db server
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        console.log('SystemLayout : ACTIVE')
        dispatch(actions.meGet(storage_remember.uid)).then(() => {})
        dispatch(actions.systemInfoGet())
        return () => {
            dispatch(actions.meReset())
        }
    }, [])

    useEffect(() => {
        if (me) {
            if (me?.role == authorize_role[3]) {
                setIsLoading(true)
                console.log('ได้รับการอนุญาตเข้าถึง')
            } else {
                console.log('ไม่ได้รับอนุญาต')
                alert('ไม่ได้รับอนุญาตให้เข้าถึงส่วนนี้')
                history.push('/')
            }
        }
        return () => {}
    }, [me])

    return isLoading ? (
        <>
            <Sidebar />
            <div className="relative md:ml-64 bg-gray-200">
                <div className="px-4 py-4 md:px-10 mx-auto w-full min-h-screen ">
                    <ErrorBoundary
                        FallbackComponent={Fallback}
                        onReset={() => {
                            history.goBack()
                        }}
                    >
                        <Switch>
                            <Route
                                path="/system/users"
                                exact
                                component={ManagementUsers}
                            />
                            <Route
                                path="/system/user/create"
                                exact
                                component={CreateUsers}
                            />

                            <Route
                                path="/system/stations"
                                exact
                                component={ManagementStations}
                            />
                            <Route
                                path="/system/stations/create"
                                exact
                                component={CreateStation}
                            />
                            <Route
                                path="/system/stations/:id"
                                exact
                                component={EditStation}
                            />
                            <Route
                                path="/system/stations/sensors/add/:stationId"
                                exact
                                component={CreateSensor}
                            />

                            <Route
                                path="/system/stations/sensors/:id"
                                exact
                                component={EditSensor}
                            />
                            <Route
                                path="/system/template/"
                                exact
                                component={ManagementStationTemplate}
                            />
                            <Route
                                path="/system/template/create"
                                exact
                                component={CreateTemplate}
                            />
                            <Route
                                path="/system/template/sensors/add/:templateId"
                                exact
                                component={CreateSensorInTemplate}
                            />
                            <Route
                                path="/system/template/sensors/:id"
                                exact
                                component={EditTemplateSensor}
                            />

                            <Route
                                path="/system/dashboard/"
                                exact
                                component={ManagementDashboard}
                            />
                            <Route
                                path="/system/stations/gauge/:id"
                                exact
                                component={GaugeList}
                            />
                            <Route
                                path="/system/stations/diagram/arrange/:id"
                                exact
                                component={EditDiagramOrder}
                            />
                            <Route
                                path="/system/stations/diagram/:id"
                                exact
                                component={DiagramList}
                            />

                            <Route
                                path="/system/stations/arrange/:id"
                                exact
                                component={EditGaugeOrder}
                            />

                            <Route
                                path="/system/stations/variables/:id"
                                exact
                                component={ManagementVariables}
                            />

                            <Route
                                path="/system/stations/charts/arrange/:id"
                                exact
                                component={EditChartOrder}
                            />

                            <Route
                                path="/system/stations/charts/:id"
                                exact
                                component={ManagementCharts}
                            />
                            <Route
                                path="/system/connections"
                                exact
                                component={ManagementConnections}
                            />

                            <Route
                                path="/system/notify"
                                exact
                                component={ManagementNotify}
                            />
                            <Route
                                path="/system/info/"
                                exact
                                component={ManagementSystemInfo}
                            />

                            <Redirect from="/system" to="/system/users" />
                        </Switch>
                    </ErrorBoundary>
                </div>
                <FooterAdmin />
            </div>
        </>
    ) : (
        <>
            <SpinnerLoading />
        </>
    )
}
