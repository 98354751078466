import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import _ from 'lodash';
import { Button } from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import GaugeMakingComponent from '../../../../components/Gagues/Making/GaugeMakingComponent';
import SpinnerLoading from 'components/Loading/SpinnerLoading';

import { useParams, useHistory, Link } from 'react-router-dom';
import * as actions from '../../../../redux/actions';
import GagueTemplate from '../../../../template/GaugeTemplate.json';

export default function GaugeList() {
  const [isLoading, setIsLoading] = useState(false);
  const params = useParams();
  const allStations = useSelector((state) => state.station);
  const [currentStation, setCurrentStation] = useState();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(actions.stationAll()).then(() => { });
    return () => { };
  }, [params]);

  useEffect(() => {
    if (allStations) {
      const tempcurrentStatus = _.find(
        allStations.arr,
        (station) => station._id === params.id
      );
      setCurrentStation(tempcurrentStatus);
      setIsLoading(true);
      console.log('Curent Station', currentStation);
    }
    return () => { };
  }, [params, allStations]);

  const genGaugeList = () => {
    return _.map(currentStation.gauges, (gauge, index) => (
      <GaugeMakingComponent
        key={index}
        gaugeData={gauge}
        stationData={currentStation}
        placeToEdit="station"
      />
    ));
  };

  const handleCreateGauge = () => {
    let tempStation = currentStation;
    let tempGaugeTemplate = GagueTemplate;
    if (_.isArray(tempStation.gauges)) {
      console.log('Old Gauge List ', tempStation.gauges);
      tempStation.gauges.push(tempGaugeTemplate);
      console.log('New Gauge List ', tempStation.gauges);
    }
    console.log('New Station Gata will be', tempStation);
    dispatch(actions.stationPut(currentStation._id, tempStation)).then(
      () => {
        dispatch(actions.stationAll());
      }
    );
  };

  if (currentStation && isLoading) {
    return (
      <>
        <div className="relative flex flex-col min-w-0 break-words bg-gray-100 w-full mb-6 shadow-lg rounded">
          <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
            <div className="flex flex-wrap items-center">
              <div className="relative w-full max-w-full flex-grow flex-1">
                <h6 className="uppercase text-gray-500 mb-1 text-xs font-semibold font-sans">
                  จัดการระบบ
                </h6>
                <h2 className="text-gray-800 text-2xl font-bold font-sans">
                  เกจในแดชบอร์ด {currentStation.name}
                </h2>
              </div>
            </div>
          </div>
          <div className="flex justify-start gap-1 p-4">
            <Button
              colorScheme="blue"
              variant="solid"
              size="sm"
              onClick={() => handleCreateGauge()}
            >
              เพิ่ม
            </Button>

            {/* <Link to={'/system/dashboard/arrange/' + params.id}>
              <Button colorScheme="blue" variant="solid" size="sm">
                ปรับการจัดเรียง
              </Button>
            </Link> */}
          </div>
          <div className="p-4 w-full">{genGaugeList()}</div>
        </div>
      </>
    )
  } else {
    return <SpinnerLoading />
  }
}
