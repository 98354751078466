import { NumericOperation } from '../../config/MathOperation'

export default function NumberLogicResolve(operation, leftData, rightData) {
    const processedLeftData = typeof leftData === 'number' ? leftData : 0
    const processedRightData = typeof rightData === 'number' ? rightData : 0
    let result
    switch (operation) {
        case NumericOperation.ADD:
            console.log('ADD')
            result = processedLeftData + processedRightData
            break
        case NumericOperation.SUBTRACT:
            console.log('SUBTRACT')
            result = processedLeftData - processedRightData
            break
        case NumericOperation.MULTIPLY:
            console.log('MULTIPLY')
            result = processedLeftData * processedRightData
            break
        case NumericOperation.DIVIDE:
            console.log('DIVIDE')
            result = processedLeftData / processedRightData
            break
        default:
            break
    }
    return result
}
