import React, { useState } from 'react'
import {
    Badge,
    Box,
    Button,
    Checkbox,
    Code,
    Divider,
    FormControl,
    FormLabel,
    Input,
    Select,
    Slider,
    SliderFilledTrack,
    SliderThumb,
    SliderTrack,
    Switch,
} from '@chakra-ui/react'
import { useDispatch } from 'react-redux'
import * as actions from '../../../redux/actions'
import { useForm } from 'react-hook-form'
import _ from 'lodash'
import hash from 'object-hash'

import Config from '../../../config'
import DiagramAttributeMaking from './DiagramAttributeMaking'
import ImageUploading from '../../../components/ImageUpload/ImageUploading'

const plainAttribute = {
    label: 'String',
    preferDataType: 'String',
    isActiveHigh: true,
    labelActive: '',
    labelInactive: '',
    unit: 'String',
    isShowLabel: true,
    showLightStatus: true,
    dataSource: 'Sensor',
    sensorRef: '',
    variableId: '',
}

export default function DiagramMakingComponent({ stationData, diagramData }) {
    const [isEditorMode, setIsEditorMode] = useState(false)
    const [isRequestEditImage, setIsRequestEditImage] = useState(false)
    const [selectedDiagramLineSource, setSelectedDiagramLineSource] = useState(
        diagramData.outedLine?.enableSource ||
            Config.TypeOfSource.GaugeSource.sensor
    )
    const [rendered, setRendered] = useState(false)

    const { register, handleSubmit } = useForm()
    const dispatch = useDispatch()

    const genVariableList = () => {
        return _.map(stationData.variables, (variable) => (
            <option value={variable.id}>{variable.variableName}</option>
        ))
    }

    const genRefStringList = () => {
        return _.map(stationData.sensors, (sensor) => (
            <option value={sensor.refString}>{sensor.refString}</option>
        ))
    }

    // Merged the Sensor Data and Make new Station Data to Database
    const prepareUpdateDatabase = async (data) => {
        // Image Uploading
        if (isRequestEditImage) {
            const fileName = hash({
                name: data.diagramImage[0].name,
                date: new Date(),
            })
            const imageURL = await ImageUploading(
                fileName,
                data.diagramImage[0]
            )
            data.diagramImage = imageURL
        } else {
            data.diagramImage = diagramData.diagramImage
        }

        const selectedDiagram = _.find(
            stationData.diagrams,
            (diagram) => diagram._id === diagramData._id
        )

        const selectedDiagramIndex = _.findIndex(
            stationData.diagrams,
            (diagram) => diagram._id === diagramData._id
        )
        const mergeddiagramData = _.merge(selectedDiagram, data)
        let editedStation = stationData
        editedStation.diagrams[selectedDiagramIndex] = mergeddiagramData
        console.log('This is Station')
        console.log(data)
        dispatch(actions.stationPut(stationData._id, editedStation)).then(
            () => {
                dispatch(actions.stationAll())
            }
        )
        setIsEditorMode(false)
    }

    const prepateDeleteSensor = (diagramId) => {
        let confirm = window.confirm('ยืนยันการลบไดอะแกรม ')
        if (confirm) {
            const selectedSensorIndex = _.findIndex(
                stationData.diagrams,
                (diagram) => diagram._id === diagramId
            )
            let editedStation = stationData
            if (editedStation.diagrams) {
                console.log('Original Sensor Stack', editedStation.diagrams)
                editedStation.diagrams.splice(selectedSensorIndex, 1)
                console.log('SPLICED')
                console.log('New Sensor Stack', editedStation.diagrams)
            }
            console.log('This is Station')
            dispatch(actions.stationPut(stationData._id, editedStation)).then(
                () => {
                    dispatch(actions.stationAll())
                }
            )
            setIsEditorMode(false)
        }
    }

    const addNewAttributeToDiagram = () => {
        const currentAttribute = diagramData.attribute
        try {
            currentAttribute.push(plainAttribute)
            diagramData.attribute = currentAttribute
        } catch (error) {
            console.error('Cannot add new Attribute')
            console.error(error)
        }
    }

    const removeAttributeToDiagram = (attrIndex) => {
        const currentAttribute = diagramData.attribute
        try {
            currentAttribute.splice(attrIndex, 1)
            diagramData.attribute = currentAttribute
            setRendered(!rendered)
        } catch (error) {
            console.error('Cannot remove Attribute')
            console.error(error)
        }
    }

    return (
        <Box borderRadius="lg" padding="4" className="bg-white my-2">
            <form onSubmit={handleSubmit(prepareUpdateDatabase)}>
                <div className="flex gap-3">
                    <div className="w-5/6">
                        <h5 className="text-lg font-bold font-sans">
                            {' '}
                            {diagramData.diagramName}{' '}
                        </h5>
                    </div>

                    <div className="flex gap-1 ">
                        {isEditorMode === true ? (
                            <>
                                <Button
                                    size="sm"
                                    type="button"
                                    colorScheme="gray"
                                    onClick={() => setIsEditorMode(false)}
                                >
                                    ยกเลิก
                                </Button>
                                <Button
                                    size="sm"
                                    colorScheme="green"
                                    type="submit"
                                >
                                    บันทึก
                                </Button>
                            </>
                        ) : (
                            <>
                                <Button
                                    size="sm"
                                    colorScheme="yellow"
                                    onClick={() => setIsEditorMode(true)}
                                    type="button"
                                >
                                    แก้ไข
                                </Button>
                                <Button
                                    size="sm"
                                    colorScheme="red"
                                    type="button"
                                    onClick={() =>
                                        prepateDeleteSensor(diagramData._id)
                                    }
                                >
                                    ลบ
                                </Button>
                            </>
                        )}
                    </div>
                </div>

                <div className="flex gap-3">
                    <div className="w-full lg:w-1/4 font-sans mx-4">
                        {diagramData.diagramImage ? (
                            <img
                                src={diagramData.diagramImage}
                                alt={diagramData.diagramName}
                                className="h-16"
                            />
                        ) : (
                            ''
                        )}
                    </div>
                    <div className="w-full lg:w-3/4 text-sm">
                        {isEditorMode === true ? (
                            <>
                                <FormControl>
                                    <label className="font-semibold">
                                        ชื่อไดอะแกรม
                                    </label>
                                    <Input
                                        type="text"
                                        name="diagramName"
                                        ref={register}
                                        defaultValue={diagramData.diagramName}
                                        size="sm"
                                    />
                                </FormControl>
                                <FormControl>
                                    <Checkbox
                                        size="sm"
                                        value={isRequestEditImage}
                                        onChange={(event) =>
                                            setIsRequestEditImage(
                                                event.target.checked
                                            )
                                        }
                                    >
                                        แก้ไขภาพอุปกรณ์ในไดอะแกรม
                                    </Checkbox>
                                </FormControl>
                                {isRequestEditImage && (
                                    <FormControl>
                                        <label className="font-semibold">
                                            ภาพอุปกรณ์ในไดอะแกรม
                                        </label>
                                        <input
                                            type="file"
                                            name="diagramImage"
                                            ref={register}
                                        />
                                    </FormControl>
                                )}

                                <FormControl>
                                    <label className="font-semibold">
                                        ขนาดของรูปภาพ
                                    </label>
                                    <Slider
                                        defaultValue={diagramData.imageSize}
                                        min={0}
                                        max={100}
                                        step={20}
                                        name="imageSize"
                                        ref={register}
                                    >
                                        <SliderTrack>
                                            <SliderFilledTrack />
                                        </SliderTrack>
                                        <SliderThumb />
                                    </Slider>
                                </FormControl>
                                {diagramData.attribute &&
                                    _.map(
                                        diagramData.attribute,
                                        (attr, index) => (
                                            <DiagramAttributeMaking
                                                key={index}
                                                index={index}
                                                attr={attr}
                                                register={register}
                                                stationData={stationData}
                                                onDeleteAttribute={
                                                    removeAttributeToDiagram
                                                }
                                            />
                                        )
                                    )}

                                <Button
                                    isFullWidth
                                    colorScheme="blue"
                                    size="sm"
                                    onClick={() => {
                                        addNewAttributeToDiagram()
                                        setRendered(!rendered)
                                    }}
                                >
                                    เพิ่มรายการใหม่
                                </Button>
                                <div className="rounded-lg bg-gray-200 m-2">
                                    <div className="p-2">
                                        <FormControl>
                                            <label className="font-semibold">
                                                เส้นที่จะออกไปด้านหลัง
                                            </label>
                                            <br />
                                            <Checkbox
                                                name={`outedline.enable`}
                                                defaultChecked={
                                                    diagramData.outedline
                                                        ?.enable || true
                                                }
                                                ref={register}
                                                size="sm"
                                            >
                                                มีเส้นที่ต่ออกจากไดอะแกรม
                                            </Checkbox>
                                        </FormControl>
                                        <FormControl>
                                            <label className="font-semibold">
                                                คำหนดค่าเส้นนี้ด้วย{' '}
                                            </label>
                                            <Select
                                                name="outedLine.enableSource"
                                                defaultValue={
                                                    diagramData.outedLine
                                                        ?.enableSource
                                                }
                                                ref={register}
                                                onChange={(event) =>
                                                    setSelectedDiagramLineSource(
                                                        event.target.value
                                                    )
                                                }
                                                size="sm"
                                            >
                                                <option value="">
                                                    เลือกที่มาของค่า
                                                </option>
                                                <option
                                                    value={
                                                        Config.TypeOfSource
                                                            .GaugeSource.sensor
                                                    }
                                                >
                                                    เซ็นเซอร์
                                                </option>
                                                <option
                                                    value={
                                                        Config.TypeOfSource
                                                            .GaugeSource
                                                            .variable
                                                    }
                                                >
                                                    ตัวแปร
                                                </option>
                                            </Select>
                                        </FormControl>
                                        {selectedDiagramLineSource ===
                                        Config.TypeOfSource.GaugeSource
                                            .sensor ? (
                                            <>
                                                <label className="font-semibold">
                                                    จะทำงานเมื่อเซ็นเซอร์นี้เป็น
                                                    True
                                                </label>
                                                <Select
                                                    name="outedLine.enableSensorRef"
                                                    defaultValue={
                                                        diagramData.outedLine
                                                            ?.enableSensorRef
                                                    }
                                                    ref={register}
                                                    size="sm"
                                                >
                                                    <option value="">
                                                        เลือกเซ็นเซอร์
                                                    </option>
                                                    {genRefStringList()}
                                                </Select>
                                            </>
                                        ) : (
                                            <>
                                                <label className="font-semibold">
                                                    จะทำงานเมื่อตัวแปรนี้เป็น
                                                    True
                                                </label>
                                                <Select
                                                    name="outedLine.enableVariableId"
                                                    defaultValue={
                                                        diagramData.outedLine
                                                            ?.enableVariableId
                                                    }
                                                    ref={register}
                                                    size="sm"
                                                >
                                                    <option value="">
                                                        เลือกตัวแปร
                                                    </option>
                                                    {genVariableList()}
                                                </Select>
                                            </>
                                        )}

                                        <FormControl></FormControl>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                                <p className="text-sm ">
                                    {' '}
                                    <span className="text-sm font-semibold text-gray-700">
                                        ชื่อไดอะแกรม
                                    </span>{' '}
                                    {diagramData.diagramName}{' '}
                                </p>
                                {diagramData.attribute ? (
                                    _.map(
                                        diagramData.attribute,
                                        (attr, index) => (
                                            <ul key={index}>
                                                <li className="text-sm ">
                                                    <ul>
                                                        <li>
                                                            <span className="font-semibold text-blue-700">
                                                                {index + 1}.{' '}
                                                                {attr.label}
                                                            </span>{' '}
                                                            <Badge>
                                                                {
                                                                    attr.preferDataType
                                                                }
                                                            </Badge>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        )
                                    )
                                ) : (
                                    <></>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </form>
        </Box>
    )
}
