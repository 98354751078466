import _ from 'lodash'
import GenerateMathematicSymbol from '../../../../util/GenerateMathematicSymbol'
import MathematicResolver from '../../../../util/LogicResolve'
import SubVariableDataFetching from './ChartSubVariableDataFinding'

export default function CalculateVariableGraphData(
    dataArr,
    selectedVariable,
    stationData
) {
    // Uncomment these Console to see what's going on!

    // วิเคราะห์เซ็นเซอร์ และ องค์ประกอบจากตัวแปร
    const subVariable = selectedVariable?.multiLevelVariables
    const subOperation = selectedVariable?.multiLevelOperations

    //Display variable info
    console.log('Selected Variable is ', selectedVariable.variableName)
    console.log('They are ', _.size(subOperation), ' step of operation inside ')

    let latestStateData = []

    const operatedFinalData = (
        leftVariableData,
        rightVariableData,
        operation
    ) => {
        if (_.isArray(leftVariableData) && _.isArray(rightVariableData)) {
            return _.map(leftVariableData, (leftVariableElement, index) => {
                return {
                    x: leftVariableElement?.x,
                    y: MathematicResolver(
                        operation,
                        leftVariableElement?.y,
                        rightVariableData[index]?.y
                    ),
                }
            })
        } else if (
            _.isArray(leftVariableData) &&
            !_.isArray(rightVariableData)
        ) {
            return _.map(leftVariableData, (leftVariableElement, index) => {
                return {
                    x: leftVariableElement?.x,
                    y: MathematicResolver(
                        operation,
                        leftVariableElement?.y,
                        rightVariableData
                    ),
                }
            })
        } else if (
            !_.isArray(leftVariableData) &&
            _.isArray(rightVariableData)
        ) {
            return _.map(rightVariableData, (rightVariableElement, index) => {
                return {
                    x: rightVariableElement?.x,
                    y: MathematicResolver(
                        operation,
                        rightVariableElement?.y,
                        leftVariableData
                    ),
                }
            })
        } else {
            // ไม่มีโอกาสเกิดขึ้น
            console.warn('Program is Running on Unpredictable Method')
            return MathematicResolver(
                operation,
                rightVariableData,
                leftVariableData
            )
        }
    }

    // Main Function
    for (let i = 0; i < _.size(subOperation); i++) {
        console.log('-----------------')
        console.log('การดำเนินการครั้งที่', i + 1)
        console.log(
            'Operation : ',
            subOperation?.[i]?.leftVariable,
            '',
            GenerateMathematicSymbol(subOperation?.[i]?.operation),
            '',
            subOperation?.[i]?.rightVariable
        )
        if (i === 0) {
            // Find Left variable
            // console.log('กำลังหาตัวแปรด้านซ้าย ', subOperation[i].leftVariable)
            const leftVariable = _.find(
                subVariable,
                (subVar) =>
                    subVar?.subVariableName === subOperation?.[i]?.leftVariable
            )
            // console.log('ตัวแปรด้านซ้าย มาจาก', leftVariable?.typeOfSource)

            // Calculate Data on the Left
            const leftVariableData = SubVariableDataFetching(
                stationData,
                leftVariable,
                dataArr
            )
            //  console.log('ได้ค่าฝั่งซ้ายมือเป็น ', leftVariableData)

            // Find Right variable
            // console.log('กำลังหาตัวแปรด้านขวา ', subOperation[i].rightVariable)
            const rightVariable = _.find(
                subVariable,
                (subVar) =>
                    subVar?.subVariableName === subOperation?.[i]?.rightVariable
            )
            //  console.log('ตัวแปรด้านขวา มาจาก', rightVariable?.typeOfSource)
            // Calculate Data on the Right
            const rightVariableData = SubVariableDataFetching(
                stationData,
                rightVariable,
                dataArr
            )
            console.log('ได้ค่าฝั่งขวามือเป็น ', rightVariableData)

            const operatedData = operatedFinalData(
                leftVariableData,
                rightVariableData,
                subOperation?.[i]?.operation
            )

            console.log('ในขั้นตอนนี้  คำนวณค่าได้เป็น ==> ', operatedData)
            latestStateData = operatedData
        } else {
            // When is not start period we need to find only right subvariable
            // and it due with latestStateData that I had declare variable above

            const tempLatestStateData = latestStateData
            // Find Right variable
            // console.log('กำลังหาตัวแปรด้านขวา ', subOperation[i].rightVariable)
            const rightVariable = _.find(
                subVariable,
                (subVar) =>
                    subVar?.subVariableName === subOperation?.[i]?.rightVariable
            )
            // console.log('ตัวแปรด้านขวา มาจาก', rightVariable?.typeOfSource)
            // Calculate Data on the Right
            const rightVariableData = SubVariableDataFetching(
                stationData,
                rightVariable,
                dataArr
            )
            //console.log('ได้ค่าฝั่งขวามือเป็น ', rightVariableData)

            const operatedData = operatedFinalData(
                tempLatestStateData,
                rightVariableData,
                subOperation?.[i]?.operation
            )

            //console.log('ในขั้นตอนนี้  คำนวณค่าได้เป็น ==> ', operatedData)
            latestStateData = operatedData
        }

        if (i === _.size(subOperation) - 1) {
            // Recheck it is in boolean or not
            if (typeof latestStateData[0].y === 'boolean') {
                console.log('จัดการให้เป็น Number')
                latestStateData = _.map(
                    latestStateData,
                    (eachLatestStateElement) => ({
                        x: eachLatestStateElement?.x,
                        y: eachLatestStateElement?.y === true ? 1 : 0,
                    })
                )
            }
            // console.log(
            //     'ถึงขั้นสุดท้าย การคำนวณเสร็จสิ้น ได้ผลลัพธ์เป็น ',
            //     latestStateData
            // )
            return latestStateData
        }
    }
}
