import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { Button } from '@chakra-ui/react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useHistory, Link } from 'react-router-dom'
import hash from 'object-hash'

import SpinnerLoading from 'components/Loading/SpinnerLoading'
import * as actions from '../../../../redux/actions'
import ChartMakingComponent from '../../../../components/Graphs/Making/ChartMakingComponents'
import ChartTemplate from '../../../../template/ChartTemplate.json'

export default function ManagementCharts() {
    const [isLoading, setIsLoading] = useState(false)
    const params = useParams()
    const allStations = useSelector((state) => state.station)
    const dispatch = useDispatch()
    const [currentStation, setCurrentStation] = useState()

    useEffect(() => {
        dispatch(actions.stationAll()).then(() => {})
        return () => {}
    }, [params])

    useEffect(() => {
        if (allStations) {
            const tempcurrentStatus = _.find(
                allStations.arr,
                (station) => station._id === params.id
            )
            setCurrentStation(tempcurrentStatus)
            console.log('Curent Station', currentStation)
        }
        setIsLoading(true)
        return () => {}
    }, [params, allStations])

    const genChartList = () => {
        return _.map(currentStation.charts, (chart, index) => (
            <>
                <ChartMakingComponent
                    key={index}
                    index={index}
                    chartData={chart}
                    stationData={currentStation}
                />
            </>
        ))
    }

    const handleCreateVariables = () => {
        setIsLoading(false)
        const tempStation = currentStation
        const tempChartTemplate = ChartTemplate
        tempChartTemplate.id = hash({
            date: new Date(),
            randomNum: Math.random(),
        })
        if (_.isArray(tempStation.charts)) {
            console.log('Old Chart List ', tempStation.charts)
            tempStation.charts.push(tempChartTemplate)
            console.log('New Chart List ', tempStation.charts)
        }
        console.log('New Station Data will be', tempStation)
        dispatch(actions.stationPut(currentStation._id, tempStation)).then(
            () => {
                dispatch(actions.stationAll()).then(() => {})
            }
        )
    }

    if (currentStation && isLoading) {
        return (
            <>
                <div className="relative flex flex-col min-w-0 break-words bg-gray-100 w-full mb-6 shadow-lg rounded">
                    <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
                        <div className="flex flex-wrap items-center">
                            <div className="relative w-full max-w-full flex-grow flex-1">
                                <h6 className="uppercase text-gray-500 mb-1 text-xs font-semibold font-sans">
                                    จัดการระบบ
                                </h6>
                                <h2 className="text-gray-800 text-2xl font-bold font-sans">
                                    จัดการกราฟในสถานี {currentStation.name}
                                </h2>
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-start gap-1 p-4">
                        <Button
                            colorScheme="blue"
                            variant="solid"
                            size="sm"
                            onClick={() => handleCreateVariables()}
                        >
                            เพิ่ม
                        </Button>

                        {/* <Link to={'/system/dashboard/arrange/' + params.id}> */}
                        {/* <Button colorScheme="blue" variant="solid" size="sm">
              ปรับการจัดเรียง
            </Button> */}
                        {/* </Link> */}
                    </div>
                    <div className="p-4 w-full">{genChartList()}</div>
                </div>
            </>
        )
    } else {
        return <SpinnerLoading />
    }
}
