import React, { useState, useEffect } from "react";
import SpinnerLoading from "components/Loading/SpinnerLoading";
import { useSelector, useDispatch } from "react-redux";
import { useParams, Link } from "react-router-dom";
import * as actions from "../../redux/actions/";
// import EstimateBillLists from "./components/EstimateBillLists";
import _ from "lodash";
import moment from "moment";
import { Button } from "@chakra-ui/button";
import EstimateBillDetailLists from "./components/EstimateBillDeatailLists";
import { printPDF } from "./components/EstimateBillReport";
export default function ElectricBillEstimatorDetail() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const electricConsumption = useSelector((state) => state.electricConsumption);
  const systemInfo = useSelector((state) => state.system);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    dispatch(actions.electricConsumptionGet(id)).then(() => {
      dispatch(actions.systemInfoGet()).then(() => {
        setIsLoading(true);
      });
    });
    return () => {};
  }, []);
  return isLoading ? (
    <div className="relative flex flex-col min-w-0 break-words bg-gray-100 w-full mb-6 shadow-lg rounded ">
      <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
        <div className="flex flex-wrap items-center">
          <div className="relative w-full max-w-full flex-grow flex-1">
            <h6 className="uppercase text-gray-500 mb-1 text-xs font-semibold font-sans">
              ประมาณการบิลค่าไฟฟ้า
            </h6>
            <h2 className="text-gray-800 text-2xl font-bold font-sans">
              ประมาณการบิลค่าไฟฟ้าประจำเดือน{" "}
              {moment(electricConsumption.date).format("MMMM")}/
              {parseInt(moment(electricConsumption.date).format("YYYY"))}
            </h2>
          </div>
        </div>
        <div className="flex justify-between my-2">
          <Link to={"/electric-bill-estimator"}>
            <Button variant="outline" size="sm">
              กลับ
            </Button>
          </Link>
          <Button
            leftIcon={<i className="fas fa-print"></i>}
            colorScheme="blue"
            size="sm"
            onClick={() => {
              printPDF(electricConsumption, systemInfo);
            }}
          >
            พิมพ์
          </Button>
        </div>
        <div className="flex flex-wrap p-4 w-full bg-gray-200 rounded-sm">
          <div className="w-full md:w-1/2 ">
            <div className="my-2 ">
              <span className="font-bold text-xl">{"ค่าไฟฟ้าทั้งหมด : "}</span>
              <span className="font-bold text-xl">
                {"" + electricConsumption.summary.toFixed(2) + " บาท"}
              </span>
            </div>
            <div className="my-2 mx-2">
              <span>{"ค่าพลังงานไฟฟ้าทั้งหมด : "}</span>
              <span>{"" + electricConsumption.power.toFixed(2) + " บาท"}</span>
            </div>
            <div className="my-2 mx-2">
              <span className="">{"ค่าความต้องการไฟฟ้า PEAK : "}</span>
              <span>
                {"" + electricConsumption.needPower.toFixed(2) + " บาท"}
              </span>
            </div>
            <div className="my-2 mx-2">
              <span className="">{"ค่าบริการ : "}</span>
              <span>
                {"" + electricConsumption.service.toFixed(2) + " บาท"}
              </span>
            </div>
            <div className="my-2 mx-2">
              <span className="font-bold text-lg">{"รวมค่าไฟฟ้าฐาน : "}</span>
              <span className="font-bold text-lg">
                {"" + electricConsumption.base.toFixed(2) + " บาท"}
              </span>
            </div>
            <hr />
            <div className="my-2 mx-2">
              <span className="">{"ค่า ft : "}</span>
              <span>{"" + electricConsumption.ft.toFixed(2) + " บาท"}</span>
            </div>
            <div className="my-2 mx-2">
              <span className="">{"ค่าเพาเวอร์แฟคเตอร์ : "}</span>
              <span>
                {"" + electricConsumption.powerFactor.toFixed(2) + " บาท"}
              </span>
            </div>
            <div className="my-2 mx-2">
              <span className="font-bold text-lg">
                {"รวมค่าไฟฟ้าก่อนรวมภาษี : "}
              </span>
              <span className="font-bold text-lg">
                {"" +
                  (
                    parseFloat(electricConsumption.summary) -
                    parseFloat(electricConsumption.vat)
                  ).toFixed(2) +
                  " บาท"}
              </span>
            </div>
            <hr />
            <div className="my-2 mx-2">
              <span className="">{"ค่าภาษี : "}</span>
              <span>{"" + electricConsumption.vat.toFixed(2) + " บาท"}</span>
            </div>
            <div className="my-2 mx-2 ">
              <span className="font-bold text-lg">
                {"ค่าไฟฟ้าทั้งหมด (รวมภาษี) : "}
              </span>
              <span className="font-bold text-xl">
                {"" + electricConsumption.summary.toFixed(2) + " บาท"}
              </span>
            </div>
            <hr />
          </div>
          <div className="w-full md:w-1/2 overflow-x-auto ">
            <div className="mt-8 mb-2 text-sm font-semibold">
              ข้อมูลเพิ่มเติม
            </div>
            <div className="my-2 mx-2 ">
              <span className="text-sm">
                {"ช่วงที่ความต้องการพลังไฟฟ้าช่วง On Peak สูงสุด: "}
              </span>
              <span className="text-sm">
                {"" +
                  moment(electricConsumption.date_max_on_peak).format("LLLL") +
                  " น."}
              </span>
            </div>
            <div className="my-2 mx-2 ">
              <span className="text-sm">
                {"ความต้องการพลังไฟฟ้าสูงสุดช่วง On Peak : "}
              </span>
              <span className="text-sm">
                {"" + electricConsumption.max_on_peak.toFixed(2) + " กิโลวัตต์"}
              </span>
            </div>
            <div className="my-2 mx-2 ">
              <span className="text-sm">
                {"ความต้องการพลังไฟฟ้าสูงสุดช่วง Off Peak : "}
              </span>
              <span className="text-sm">
                {"" +
                  electricConsumption.max_off_peak.toFixed(2) +
                  " กิโลวัตต์"}
              </span>
            </div>
            <div className="my-2 mx-2 ">
              <span className="text-sm">
                {"ความต้องการพลังไฟฟ้าสูงสุดช่วง Holiday : "}
              </span>
              <span className="text-sm">
                {"" + electricConsumption.max_holiday.toFixed(2) + " กิโลวัตต์"}
              </span>
            </div>
            <hr />
            <div className="my-2 mx-2 ">
              <span className="text-sm">
                {"ปริมาณการใช้พลังงานไฟฟ้าทั้งหมด : "}
              </span>
              <span className="text-sm">
                {"" + electricConsumption.all.toFixed(2) + " หน่วย"}
              </span>
            </div>
            <div className="my-2 mx-2 ">
              <span className="text-sm">
                {"ปริมาณการใช้พลังงานไฟฟ้าช่วง On Peak : "}
              </span>
              <span className="text-sm">
                {"" + electricConsumption.onPeak.toFixed(2) + " หน่วย"}
              </span>
            </div>
            <div className="my-2 mx-2 ">
              <span className="text-sm">
                {"ปริมาณการใช้พลังงานไฟฟ้าช่วง Off Peak : "}
              </span>
              <span className="text-sm">
                {"" + electricConsumption.offPeak.toFixed(2) + " หน่วย"}
              </span>
            </div>
            <div className="my-2 mx-2 ">
              <span className="text-sm">
                {"ปริมาณการใช้พลังงานไฟฟ้าช่วง Holiday : "}
              </span>
              <span className="text-sm">
                {"" + electricConsumption.holiday.toFixed(2) + " หน่วย"}
              </span>
            </div>
          </div>
        </div>
        <div className="my-2 mx-2 text-lg font-bold">
          ประมาณการค่าไฟฟ้ารายวัน
        </div>
        <div className="p-4  w-full bg-gray-200 rounded-sm border-black border-2">
          {_.size(electricConsumption.history) ? (
            <div className="overflow-x-auto">
              <EstimateBillDetailLists history={electricConsumption.history} />
            </div>
          ) : (
            <div className="flex justify-center p-4">
              <div>ไม่มีข้อมูล</div>
            </div>
          )}
        </div>
      </div>
    </div>
  ) : (
    <SpinnerLoading />
  );
}
