import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import _ from "lodash";
import {
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Switch,
} from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import SpinnerLoading from "components/Loading/SpinnerLoading";
import { useHistory } from "react-router-dom";
import * as actions from "../../../../redux/actions";
// import hash from "object-hash";

export default function EditNotify({ systemInfo }) {
  const { register, handleSubmit, reset } = useForm();
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    return () => {};
  }, []);
  const onSubmit = async (data, e) => {
    let confirm = window.confirm("อัพเดทข้อมูล");
    if (confirm) {
      e.preventDefault();
      await dispatch(actions.systemInfoPut(systemInfo._id, data));
      alert("บันทึกสำเร็จ");
    }
  };
  if (systemInfo) {
    return (
      <>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-wrap justify-center pb-8">
            <div className="w-full lg:w-6/12 px-4">
              <h6 className="text-gray-500 text-base mt-3 mb-6 font-bold uppercase font-sans ">
                ข้อมูลการแจ้งเตือน
              </h6>
              <div className="flex flex-wrap">
                <div className="w-full px-4 pb-4">
                  <FormControl display="flex">
                    <FormLabel>
                      <div className="block uppercase text-gray-700 text-sm font-bold mb-2">
                        เปิด/ปิดการแจ้งเตือน
                      </div>
                    </FormLabel>
                    <Switch
                      size="lg"
                      name="enable_notify"
                      ref={register}
                      defaultChecked={systemInfo.enable_notify}
                    />
                  </FormControl>
                </div>
                <hr />

                <div className="w-full px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-gray-700 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Access Token สำหรับ Line
                    </label>
                    <input
                      name="line_access_token"
                      type="text"
                      required
                      ref={register}
                      defaultValue={systemInfo.line_access_token}
                      className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                      placeholder="Access Token"
                    />
                  </div>
                </div>
              </div>
              <div className="text-center px-4 gap-0 py-3 ">
                <Button
                  isFullWidth
                  colorScheme="blue"
                  variant="solid"
                  type="submit"
                >
                  บันทึก{" "}
                </Button>
              </div>
            </div>
          </div>{" "}
        </form>{" "}
      </>
    );
  } else {
    return <SpinnerLoading />;
  }
}
