import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import Chart from 'react-apexcharts'
import moment from 'moment'
import 'moment/locale/th'
import { Button, Stack } from '@chakra-ui/react'
import CalculateVariableGraphData from '../Functions/VariableGraphFunction/CalculateVariableGraphData'
import { useDispatch } from 'react-redux'
import * as actions from '../../../redux/actions'
export default function AreaChart({
    data,
    variableId,
    stationData,
    chartData,
    valueFormat,
    tickAmount,
    graphMax,
    graphMin,
    setValueFormat,
    setTickAmount,
    setGraphMax,
    setGraphMin,
    enableTimeSelect = true,
}) {
    const dispatch = useDispatch()
    console.log('Variable ID ', variableId)
    const variableData = _.find(
        stationData.variables,
        (variable) => variable?.id === variableId
    )
    const stationId = stationData?._id

    const [valueStack, setValueStack] = useState(
        CalculateVariableGraphData(data?.arr, variableData, stationData)
    )

    // Automate Render
    useEffect(() => {
        if (data && data.arr) {
            let tempValueStack = CalculateVariableGraphData(
                data?.arr,
                variableData,
                stationData
            )
            setValueStack(tempValueStack)
        }
        return () => {}
    }, [data])

    const lastHour = () => {
        dispatch(actions.logByHour(stationId))
    }

    const lastDay = () => {
        dispatch(actions.logByDay(stationId))
    }

    const lastWeek = () => {
        dispatch(actions.logByWeek(stationId))
    }

    const lastMonth = () => {
        dispatch(actions.logByMonth(stationId))
    }

    const lastYear = () => {
        dispatch(actions.logByYear(stationId))
    }

    //Default Options
    const options = {
        xaxis: {
            type: 'datetime',
            labels: {
                formatter: (value) => {
                    return moment(value).format(valueFormat)
                },
            },
            tickAmount: tickAmount,
            min: graphMin,
            max: graphMax,
        },
        yaxis: {
            labels: {
                formatter:
                    chartData?.labelOnSituation?.enable === true
                        ? (value) => {
                              if (value >= 1)
                                  return chartData?.labelOnSituation
                                      ?.labelOnHigh
                              else
                                  return chartData?.labelOnSituation?.labelOnLow
                          }
                        : (value) => {
                              return parseFloat(value).toFixed(2)
                          },
            },
            min: (min) => min - 0.03,
            max: (max) => max + 0.03,
            tickAmount: chartData?.labelOnSituation?.enable === true ? 1 : 5,
            title: {
                text:
                    chartData?.valueLabel +
                    (chartData?.labelOnSituation?.enable === true ? '' : ' (') +
                    chartData?.valueUnit +
                    (chartData?.labelOnSituation?.enable === true ? '' : ') '),
            },
        },
        tooltip: {
            x: {
                show: true,
                formatter: (value) => moment(value).format('D MMM - HH:mm'),
            },
            y: {
                formatter: (value) => {
                    return (
                        parseFloat(value).toFixed(2) + ' ' + chartData.valueUnit
                    )
                },
                title: {
                    formatter: (seriesName) => chartData?.valueLabel,
                },
                style: {
                    fontWeight: '400',
                },
            },
        },
        stroke: {
            curve: chartData?.chartCurve,
        },
        dataLabels: {
            formatter: (value, opts) => {
                return parseFloat(value).toFixed(2)
            },
            enabled: false,
            background: {
                enabled: true,
                foreColor: '#fff',
            },
            textAnchor: 'small',
        },
        chart: {
            toolbar: {
                tools: {
                    zoomin: false,
                    zoomout: false,
                },
                export: {
                    csv: {
                        headerCategory: 'Date',
                        headerValue: chartData.valueLabel,
                        dateFormatter: (timestamp) =>
                            moment(timestamp).format('DD/MM/YYYY HH:mm'),
                    },
                },
            },
        },
    }

    //Rendered Series
    const series = [
        {
            name: variableData?.variableName,
            data: _.reverse(valueStack),
        },
    ]

    // FIltering

    return (
        <div>
            <h4 className="font-bold text-xl font-sans">
                {chartData?.chartLabel}
            </h4>

            <Chart
                type="area"
                series={series}
                options={options}
                width="100%"
                height="400px"
            />

            {enableTimeSelect && (
                <Stack direction="row" spacing="4">
                    <Button
                        size="sm"
                        onClick={() => {
                            lastHour()
                            setGraphMin(undefined)
                            setGraphMax(undefined)
                            // setGraphMin(moment().subtract('hours').toDate().getTime())
                            // setGraphMax(moment().ad('hours').toDate().getTime())
                            setTickAmount(10)
                            setValueFormat('HH:mm')
                        }}
                    >
                        1 ชั่วโมงย้อนหลัง
                    </Button>
                    <Button
                        size="sm"
                        onClick={() => {
                            setGraphMin(
                                moment().subtract(1, 'days').toDate().getTime()
                            )
                            setGraphMax(
                                moment().add(2, 'hours').toDate().getTime()
                            )
                            lastDay()
                            setTickAmount(12)
                            setValueFormat('HH:mm')
                        }}
                    >
                        1 วันย้อนหลัง
                    </Button>
                    <Button
                        size="sm"
                        onClick={() => {
                            setGraphMin(
                                moment().subtract(1, 'weeks').toDate().getTime()
                            )
                            setGraphMax(
                                moment().add(1, 'days').toDate().getTime()
                            )
                            lastWeek()
                            setTickAmount(7)
                            setValueFormat('DD MMM YYYY')
                        }}
                    >
                        1 สัปดาห์ย้อนหลัง
                    </Button>
                    <Button
                        size="sm"
                        onClick={() => {
                            lastMonth()
                            setGraphMin(
                                moment()
                                    .subtract(1, 'months')
                                    .toDate()
                                    .getTime()
                            )
                            setGraphMax(
                                moment().add(1, 'weeks').toDate().getTime()
                            )
                            setTickAmount(6)
                            setValueFormat('DD MMM YYYY')
                        }}
                    >
                        1 เดือนย้อนหลัง
                    </Button>
                </Stack>
            )}

            <br />
        </div>
    )
}
