import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import _ from 'lodash'
import { Button } from '@chakra-ui/react'
import { useSelector, useDispatch } from 'react-redux'
import DiagramMakingComponent from '../../../../components/Diagram/Making/DiagramMakingComponents'
import SpinnerLoading from 'components/Loading/SpinnerLoading'

import { useParams, useHistory, Link } from 'react-router-dom'
import * as actions from '../../../../redux/actions'
import DiagramTemplate from '../../../../template/DiagramTemplate.json'

export default function DiagramList() {
    const [isLoading, setIsLoading] = useState(false)
    const params = useParams()
    const allStations = useSelector((state) => state.station)
    const dispatch = useDispatch()
    const [currentStation, setCurrentStation] = useState()

    useEffect(() => {
        dispatch(actions.stationAll()).then(() => {
            setIsLoading(true)
        })
        return () => { }
    }, [params])

    useEffect(() => {
        if (allStations) {
            const tempcurrentStatus = _.find(
                allStations.arr,
                (station) => station._id === params.id
            )
            setCurrentStation(tempcurrentStatus)
            console.log('Curent Station', currentStation)
        }
        return () => { }
    }, [params, allStations])

    const genDiagramList = () => {
        return _.map(currentStation.diagrams, (diagram, index) => (
            <DiagramMakingComponent
                key={index}
                diagramData={diagram}
                stationData={currentStation}
            />
        ))
    }

    const handleCreateDiagram = () => {
        let tempStation = currentStation
        let tempDiagramTemplate = DiagramTemplate
        if (_.isArray(tempStation.diagrams)) {
            console.log('Old Diagram List ', tempStation.diagrams)
            tempStation.diagrams.push(tempDiagramTemplate)
            console.log('New Diagram List ', tempStation.diagrams)
        }
        console.log('New Station Data will be', tempStation)
        dispatch(actions.stationPut(currentStation._id, tempStation)).then(
            () => {
                dispatch(actions.stationAll())
            }
        )
    }

    if (currentStation && isLoading) {
        return (
            <>
                <div className="relative flex flex-col min-w-0 break-words bg-gray-100 w-full mb-6 shadow-lg rounded">
                    <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
                        <div className="flex flex-wrap items-center">
                            <div className="relative w-full max-w-full flex-grow flex-1">
                                <h6 className="uppercase text-gray-500 mb-1 text-xs font-semibold font-sans">
                                    จัดการระบบ
                                </h6>
                                <h2 className="text-gray-800 text-2xl font-bold font-sans">
                                    ไดอะแกรมในสถานี {currentStation.name}
                                </h2>
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-start gap-1 p-4">
                        <Button
                            colorScheme="blue"
                            variant="solid"
                            size="sm"
                            onClick={() => handleCreateDiagram()}
                        >
                            เพิ่ม
                        </Button>

                        <Link
                            to={'/system/station/diagram/arrange/' + params.id}
                        >
                            <Button
                                colorScheme="blue"
                                variant="solid"
                                size="sm"
                            >
                                ปรับการจัดเรียง
                            </Button>
                        </Link>
                    </div>
                    <div className="p-4 w-full">{genDiagramList()}</div>
                </div>
            </>
        )
    } else {
        return <SpinnerLoading />
    }
}
