import React, { useRef } from "react";
import {
  Table,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
  Button,
  Switch,
} from "@chakra-ui/react";

import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../../redux/actions";
import Notifications from "../../../components/Notifications/Notifications";

export default function ControlLists({
  currentStation,
  isControlEnable,
  setIsLoadingControlsRes,
}) {
  const dispatch = useDispatch();
  const notifyRef = useRef();
  const handleSwitchControl = (
    eventValue,
    controlRef,
    sensorData,
    isHighSensitiveSensor = false,
    isMajorControl = false
  ) => {
    let confirm;
    if (isHighSensitiveSensor === true) {
      confirm = true;
      // confirm = window.confirm("ยืนยันการสั่งการ")
    } else {
      confirm = true;
    }
    if (confirm === true) {
      if (sensorData.controlWithPulse === true) {
        //สำหรับเซ็นเซอร์ที่เป็น pulse
        dispatch(
          actions.toggleSensorWithPulse(currentStation.externalRef, controlRef)
        );
      } else {
        if (eventValue === true) {
          //สำหรับทุก ๆ เซ็นเซอร์
          dispatch(
            actions.turnSensorOn(currentStation.externalRef, controlRef)
          );
        } else {
          if (isMajorControl === true) {
            //เมื่อปิดออนไลน์ทุกอย่างจะต้องการกลับมาเป็น default ของหน้างาน ( ไม่เกี่ยวกับซอฟแวร์แล้ว )
            console.log("Major");
            dispatch(
              actions.turnMajorSensorOff(currentStation.externalRef, controlRef)
            );
          } else {
            dispatch(
              actions.turnSensorOff(currentStation.externalRef, controlRef)
            );
          }
        }
      }
    }

    console.log("Event Value", eventValue);
  };

  const genControltLists = () =>
    _.map(
      _.filter(
        currentStation.sensors,
        (sensor) =>
          sensor.sensorType === "BOOLEAN" &&
          sensor.gaugeType === "OnOffGauge" &&
          sensor.controlRefString !== "CONTROLS_ENABLE"
      ),
      (sensor, index) => (
        <Tr key={index}>
          {/* <Td> {index + 1} </Td> */}
          <Td> {sensor.name} </Td>
          <Td>
            <Switch
              size="lg"
              defaultChecked={sensor.booleanStatus}
              isDisabled={!isControlEnable}
              onChange={(event) => {
                handleSwitchControl(
                  event.target.checked,
                  sensor.controlRefString,
                  sensor,
                  false
                );
                notifyRef.current.createNotification(
                  "info",
                  "",
                  "กำลังสั่งงาน..."
                );
                setIsLoadingControlsRes(false);
              }}
            />
          </Td>
        </Tr>
      )
    );

  const genMajorCOntrol = () =>
    _.map(
      _.filter(
        currentStation.sensors,
        (sensor) =>
          sensor.sensorType === "BOOLEAN" &&
          sensor.gaugeType === "OnOffGauge" &&
          sensor.controlRefString === "CONTROLS_ENABLE"
      ),
      (sensor, index) => (
        <Tr key={index}>
          {/* <Td> {index + 1} </Td> */}
          <Td className="text-lg font-semibold"> {sensor.name} </Td>
          <Td>
            <Switch
              size="lg"
              defaultChecked={sensor.booleanStatus}
              isDisabled={!isControlEnable}
              onChange={(event) => {
                handleSwitchControl(
                  event.target.checked,
                  sensor.controlRefString,
                  sensor,
                  false,
                  true
                );
                notifyRef.current.createNotification(
                  "info",
                  "",
                  "กำลังสั่งงาน..."
                );
                setIsLoadingControlsRes(false);
              }}
            />
          </Td>
        </Tr>
      )
    );

  const genOneValueControlList = () =>
    _.map(
      _.filter(
        currentStation.sensors,
        (sensor) =>
          sensor.sensorType === "BOOLEAN" && sensor.gaugeType === "TriggerGague"
      ),
      (sensor, index) => (
        <Tr key={index}>
          {/* <Td> {index + 1} </Td> */}
          <Td> {sensor.name} </Td>
          <Td>
            <Button
              size="sm"
              isDisabled={!isControlEnable}
              colorScheme="blue"
              defaultChecked={sensor.booleanStatus}
              onClick={(event) => {
                handleSwitchControl(
                  true,
                  sensor.controlRefString,
                  sensor,
                  true
                );

                notifyRef.current.createNotification(
                  "info",
                  "",
                  "กำลังสั่งงาน..."
                );

                setIsLoadingControlsRes(false);
              }}
            >
              สั่งงาน
            </Button>
          </Td>
        </Tr>
      )
    );

  return (
    <div>
      <Table size="sm" variant="striped">
        <Thead>
          <Tr>
            {/* <Th>#</Th> */}
            <Th>เซนเซอร์</Th>
            <Th>ปิด/เปิด</Th>
          </Tr>
        </Thead>
        <Tbody>
          {genMajorCOntrol()}
          {genControltLists()}
          {genOneValueControlList()}
        </Tbody>
      </Table>
      <Notifications ref={notifyRef} timeout={"1500"} />
    </div>
  );
}
