import { BooleanNumericOperation } from '../../config/MathOperation'

export default function BooleanNumericLogicResolver(
    operation,
    leftData,
    rightData
) {
    const processedLeftData = typeof leftData === 'number' ? leftData : 0
    const processedRightData = typeof rightData === 'number' ? rightData : 0
    let result
    switch (operation) {
        case BooleanNumericOperation.EQ:
            console.log('Equal')
            result = processedLeftData === processedRightData ? true : false
            break
        case BooleanNumericOperation.GE:
            console.log('Greater')
            result = processedLeftData > processedRightData ? true : false
            break
        case BooleanNumericOperation.GEQ:
            console.log('Greater or Equal')
            result = processedLeftData >= processedRightData ? true : false
            break
        case BooleanNumericOperation.LE:
            console.log('Less')
            result = processedLeftData < processedRightData ? true : false
            break
        case BooleanNumericOperation.LEQ:
            console.log('Less or equa')
            result = processedLeftData <= processedRightData ? true : false
            break
        case BooleanNumericOperation.NEQ:
            console.log('Not Equal')
            result = processedLeftData !== processedRightData ? true : false
        default:
            console.log(
                'Boolean Numeric Logic Resolver Cannot working with ',
                operation
            )
            break
    }
    return result
}
