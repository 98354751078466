import React, { forwardRef, useImperativeHandle } from "react";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

import "react-notifications/lib/notifications.css";

const Notifications = forwardRef((props, ref) => {
  // The component instance will be extended
  // with whatever you return from the callback passed
  // as the second argument

  useImperativeHandle(ref, () => ({
    createNotification(type, topic, message) {
      if (type === "info")
        NotificationManager.info(topic, message, props.timeout);
      else if (type == "success")
        NotificationManager.success(topic, message, props.timeout);
      else if (type == "warning")
        NotificationManager.warning(topic, message, props.timeout);
      else if (type == "warning")
        NotificationManager.error(topic, message, props.timeout);
      else {
        console.log("error sent type notifications");
      }
    },
  }));

  return (
    <div>
      <NotificationContainer />
    </div>
  );
});
export default Notifications;
