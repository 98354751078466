import React from 'react'
import _ from 'lodash'

import Config from '../../../config'
import SensorChart from '../SensorChart'
import VariableChart from '../VariableChart'

export default function ChartRendering({
    chartData,
    data,
    graphMax,
    graphMin,
    setGraphMax,
    setGraphMin,
    setTickAmount,
    setValueFormat,
    tickAmount,
    valueFormat,
    stationData,
    enableTimeSelect = true,
}) {
    const chartDataSource = chartData.chartSource
    if (chartDataSource === Config.TypeOfSource.GaugeSource.sensor) {
        const chartType = chartData.chartType
        switch (chartType) {
            case Config.ComponentType.ChartType.line:
                return (
                    <SensorChart.LineChart
                        data={data}
                        chartData={chartData}
                        stationData={stationData}
                        graphMax={graphMax}
                        graphMin={graphMin}
                        setGraphMax={setGraphMax}
                        setGraphMin={setGraphMin}
                        setTickAmount={setTickAmount}
                        setValueFormat={setValueFormat}
                        tickAmount={tickAmount}
                        valueFormat={valueFormat}
                        enableTimeSelect={enableTimeSelect}
                    />
                )
            case Config.ComponentType.ChartType.area:
                return (
                    <SensorChart.AreaChart
                        data={data}
                        chartData={chartData}
                        stationData={stationData}
                        graphMax={graphMax}
                        graphMin={graphMin}
                        setGraphMax={setGraphMax}
                        setGraphMin={setGraphMin}
                        setTickAmount={setTickAmount}
                        setValueFormat={setValueFormat}
                        tickAmount={tickAmount}
                        valueFormat={valueFormat}
                        enableTimeSelect={enableTimeSelect}
                    />
                )

            default:
                return <div>ไม่มีกราฟแบบที่ท่านต้องการ</div>
        }
    } else {
        const chartVariableId = chartData.chartVariableId
        const chartType = chartData.chartType
        switch (chartType) {
            case Config.ComponentType.ChartType.line:
                return (
                    <VariableChart.LineChart
                        data={data}
                        chartData={chartData}
                        variableId={chartVariableId}
                        stationData={stationData}
                        graphMax={graphMax}
                        graphMin={graphMin}
                        setGraphMax={setGraphMax}
                        setGraphMin={setGraphMin}
                        setTickAmount={setTickAmount}
                        setValueFormat={setValueFormat}
                        tickAmount={tickAmount}
                        valueFormat={valueFormat}
                        enableTimeSelect={enableTimeSelect}
                    />
                )
            case Config.ComponentType.ChartType.area:
                return (
                    <VariableChart.AreaChart
                        data={data}
                        variableId={chartVariableId}
                        chartData={chartData}
                        stationData={stationData}
                        graphMax={graphMax}
                        graphMin={graphMin}
                        setGraphMax={setGraphMax}
                        setGraphMin={setGraphMin}
                        setTickAmount={setTickAmount}
                        setValueFormat={setValueFormat}
                        tickAmount={tickAmount}
                        valueFormat={valueFormat}
                        enableTimeSelect={enableTimeSelect}
                    />
                )

            default:
                return <div>ไม่มีกราฟแบบที่ท่านต้องการ</div>
        }
    }
}
