import React from "react";
import { Table, Thead, Tr, Th, Td, Tbody, Button } from "@chakra-ui/react";
import _ from "lodash";
import moment from "moment";
import { Link } from "react-router-dom";
import { printPDF } from "./EstimateBillReport";
export default function EstimateBillLists({ electricConsumption, systemInfo }) {
  const genEstimateBillLists = () =>
    _.map(electricConsumption, (_electricConsumption, index) => (
      <Tr key={_electricConsumption._id}>
        <Td>{index + 1}</Td>
        <Td>
          {" "}
          <Link to={"/electric-bill-estimator/" + _electricConsumption._id}>
            {moment(_electricConsumption.date).format("MM/YYYY")}{" "}
          </Link>
          <i
            className="fas fa-print mx-2 cursor-pointer"
            onClick={() => {
              printPDF(_electricConsumption, systemInfo);
            }}
          ></i>
          <Link to={"/electric-bill-estimator/" + _electricConsumption._id}>
        
            <span className="text-xs text-blue-500 hover:text-blue-700">
              รายละเอียด
            </span>
          </Link>
          {/* <Button
            leftIcon={}
            colorScheme="blue"
            size="sm"
            className="mx-2"
            onClick={() => {}}
          ></Button> */}
        </Td>
        <Td>{parseFloat(_electricConsumption.all).toFixed(2) + " หน่วย"}</Td>

        <Td>
          {parseFloat(_electricConsumption.max_on_peak).toFixed(2) +
            " กิโลวัตต์ "}
        </Td>
        <Td>
          {parseFloat(_electricConsumption.needPower).toFixed(2) + " บาท"}
        </Td>
        <Td>{parseFloat(_electricConsumption.summary).toFixed(2) + " บาท "}</Td>
      </Tr>
    ));

  return (
    <div>
      <Table size="sm" variant="striped" className="whitespace-no-wrap">
        <Thead>
          <Tr>
            <Th>#</Th>
            <Th>เดือน</Th>
            <Th>หน่วย</Th>
            <Th> ความต้องการพลังไฟฟ้าช่วง On Peak</Th>
            <Th>ค่าความต้องการไฟฟ้า Peak </Th>
            <Th>ราคาค่าไฟฟ้าทั้งหมด (รวมภาษี)</Th>
          </Tr>
        </Thead>
        <Tbody>{genEstimateBillLists()}</Tbody>
      </Table>
    </div>
  );
}
