import _ from 'lodash'
import FetchingGraphData from '../../FetchingGraphData'
export default function OneSensorDataFinding(
    stationData,
    subvariableData,
    dataArr
) {
    // Find  Sensor
    const selectedSensor = _.find(
        stationData?.sensors,
        (sensor) => sensor?.refString === subvariableData?.sensorRef
    )
    console.log('Selected Sensor ', selectedSensor)
    const selectedSensorData = FetchingGraphData(dataArr, selectedSensor?._id)
    return selectedSensorData
}
