import axios from "axios";
import { USER_ALL, USER_GET, USER_PUT, USER_DEL } from "../types";

export const userAll = () => {
  return async (dispatch) => {
    await axios.get(process.env.REACT_APP_API_URL + "/user").then((res) => {
      console.log("Request Server to Get All Users");
      dispatch({ type: USER_ALL, payload: res.data });
    });
  };
};

export const userGet = (payload) => {
  return async (dispatch) => {
    await axios
      .get(process.env.REACT_APP_API_URL + "/user/" + payload)
      .then((res) => {
        console.log("Request Server to Get an Users");
        if (res.data) {
          dispatch({ type: USER_GET, payload: res.data });
        } else {
          dispatch({ type: USER_GET, payload: null });
        }
      });
  };
};

export const userPut = (id, payload) => {
  return async (dispatch) => {
    await axios
      .put(process.env.REACT_APP_API_URL + "/user/" + id, payload)
      .then((res) => {
        console.log("Request Server to put an Users");

        dispatch({ type: USER_GET, payload: null });
      });
  };
};
export const userDelete = (payload) => {
  return async (dispatch) => {
    await axios
      .delete(process.env.REACT_APP_API_URL + "/user/" + payload)
      .then((res) => {
        console.log("Request Server to Delete an Users");
        dispatch({ type: USER_DEL, payload: null });
      });
  };
};
