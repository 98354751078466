import React, { useState } from 'react'
import {
    Badge,
    Box,
    Button,
    FormControl,
    Input,
    Select,
    Table,
    Tbody,
    Th,
    Thead,
    Tr,
} from '@chakra-ui/react'
import { useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import _ from 'lodash'

import * as actions from '../../../redux/actions'
import SubVariableAddingComponent from '../SubVariables/SubVariableAddingComponent'
import OperationAddingComponent from '../Operations/OperationListAddingComponent'
import SubvariableShowing from '../SubVariables/SubvariableShowing'
import OperationListShowing from '../Operations/OperationListShowing'
import VariableMainRendering from '../Function/VariableMainRendering'
import { DataType } from '../../../config/DataType'
import Gauge from '../../Gagues/common'

export default function VariableMakingComponent({
    index,
    stationData,
    variableData,
}) {
    const [isEditorMode, setIsEditorMode] = useState(false)
    const { register, handleSubmit } = useForm()
    const [rendered, setRendered] = useState(false)
    const [allSubVariables, setAllSubVariables] = useState(
        variableData.multiLevelVariables
    )
    const [operationList, setOperationList] = useState(
        variableData.multiLevelOperations
    )

    const [selectedDataType, setSelectedDataType] = useState(
        variableData.wantedDataType
    )
    const dispatch = useDispatch()

    // Merged the Sensor Data and Make new Station Data to Database
    const prepareUpdateDatabase = (data) => {
        const selectedVariable = stationData.variables[index]

        const selectedVariableIndex = index
        const mergedvariableData = _.merge(selectedVariable, data)
        let editedStation = stationData
        editedStation.variables[selectedVariableIndex] = mergedvariableData
        dispatch(actions.stationPut(stationData._id, editedStation)).then(
            () => {
                dispatch(actions.stationAll())
            }
        )
        setIsEditorMode(false)
    }

    const prepareDeleteVariable = () => {
        let confirm = window.confirm('ยืนยันการลบตัวแปร')
        if (confirm) {
            const selectedSensorIndex = index
            let editedStation = stationData
            if (editedStation.variables) {
                editedStation.variables.splice(selectedSensorIndex, 1)
            }
            dispatch(actions.stationPut(stationData._id, editedStation)).then(
                () => {
                    dispatch(actions.stationAll())
                }
            )
            setIsEditorMode(false)
        }
    }

    const deleteSubvariable = (index) => {
        let tempSubvariableList = allSubVariables
        tempSubvariableList.splice(index, 1)
        setAllSubVariables(tempSubvariableList)
        setRendered(!rendered)
    }

    const deleteOperation = (index) => {
        let tempOperationList = operationList
        tempOperationList.splice(index, 1)
        setOperationList(tempOperationList)
        setRendered(!rendered)
    }

    return (
        <Box borderRadius="lg" padding="4" className="bg-white my-2">
            <form onSubmit={handleSubmit(prepareUpdateDatabase)}>
                <div className="flex gap-3">
                    <div className="w-5/6">
                        <h5 className="text-lg font-bold font-sans">
                            {variableData.variableName}{' '}
                            <Badge colorScheme="teal" size="sm">
                                {variableData.wantedDataType}{' '}
                            </Badge>{' '}
                        </h5>
                    </div>

                    <div className="flex gap-1 ">
                        {isEditorMode === true ? (
                            <>
                                <Button
                                    size="sm"
                                    type="button"
                                    colorScheme="gray"
                                    onClick={() => setIsEditorMode(false)}
                                >
                                    ยกเลิก
                                </Button>{' '}
                                <Button
                                    size="sm"
                                    colorScheme="green"
                                    type="submit"
                                >
                                    บันทึก
                                </Button>
                            </>
                        ) : (
                            <>
                                <Button
                                    size="sm"
                                    colorScheme="yellow"
                                    onClick={() => setIsEditorMode(true)}
                                    type="button"
                                >
                                    แก้ไข
                                </Button>
                                <Button
                                    size="sm"
                                    colorScheme="red"
                                    type="button"
                                    onClick={() => prepareDeleteVariable()}
                                >
                                    ลบ
                                </Button>
                            </>
                        )}
                    </div>
                </div>

                <div className="w-full">
                    {isEditorMode === true ? (
                        <>
                            <FormControl>
                                <label className="text-sm font-bold">
                                    {' '}
                                    ชื่อตัวแปร
                                </label>
                                <Input
                                    type="text"
                                    name="variableName"
                                    size="sm"
                                    defaultValue={variableData.variableName}
                                    ref={register}
                                />
                            </FormControl>
                            <FormControl>
                                <label className="text-sm font-bold">
                                    {' '}
                                    ชนิดตัวแปร
                                </label>
                                <Select
                                    defaultValue={variableData.wantedDataType}
                                    size="sm"
                                    ref={register}
                                    name="wantedDataType"
                                    onChange={(event) =>
                                        setSelectedDataType(event.target.value)
                                    }
                                >
                                    <option value="">เลือกชนิดของตัวแปร</option>
                                    <option value={DataType.boolean}>
                                        Boolean
                                    </option>
                                    <option value={DataType.number}>
                                        Number
                                    </option>
                                </Select>
                            </FormControl>
                            <br />

                            <div className="">
                                <div className="w-full lg:w-5/6">
                                    <label className="text-sm font-bold py-2">
                                        รายการตัวแปรย่อย{' '}
                                    </label>
                                    <Table>
                                        <Thead>
                                            <Tr>
                                                <Th>ชื่อตัวแปรย่อย</Th>
                                                <Th>ที่มาของค่า</Th>
                                                <Th>ค่า</Th>
                                                <Th></Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {_.map(
                                                allSubVariables,
                                                (subVariable, index) => (
                                                    <SubvariableShowing
                                                        deleteSubvariable={
                                                            deleteSubvariable
                                                        }
                                                        subVariable={
                                                            subVariable
                                                        }
                                                        index={index}
                                                        key={index}
                                                    />
                                                )
                                            )}
                                            <SubVariableAddingComponent
                                                allVariables={allSubVariables}
                                                setAllVariables={
                                                    setAllSubVariables
                                                }
                                                rendered={rendered}
                                                setRendered={setRendered}
                                                stationData={stationData}
                                            />
                                        </Tbody>
                                    </Table>
                                </div>
                                <br />
                                <div className="w-full lg:w-5/6 ">
                                    <label className="text-sm font-bold py-2">
                                        การดำเนินงาน{' '}
                                    </label>
                                    <Table>
                                        <Thead>
                                            <Tr>
                                                <Th>ลำดับที่</Th>
                                                <Th>ตัวแปรด้านซ้าย</Th>
                                                <Th>การดำเนินงาน</Th>
                                                <Th>ตัวแปรด้านขวา</Th>
                                            </Tr>
                                            {_.map(
                                                operationList,
                                                (eachOperation, index) => (
                                                    <OperationListShowing
                                                        deleteOperation={
                                                            deleteOperation
                                                        }
                                                        eachOperation={
                                                            eachOperation
                                                        }
                                                        index={index}
                                                        key={index}
                                                    />
                                                )
                                            )}
                                        </Thead>
                                        <Tbody>
                                            <OperationAddingComponent
                                                allSubvariables={
                                                    allSubVariables
                                                }
                                                operationList={operationList}
                                                rendered={rendered}
                                                setOperationList={
                                                    setOperationList
                                                }
                                                setRendered={setRendered}
                                            />
                                        </Tbody>
                                    </Table>
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="flex flex-wrap ">
                                <div className="lg:w-1/3 font-sans">
                                    {variableData.wantedDataType ===
                                        DataType.boolean && (
                                        <Gauge.StaticGauge.StaticBoolean
                                            staticData={VariableMainRendering(
                                                stationData,
                                                variableData.id
                                            )}
                                            graphLabel={
                                                variableData.variableName
                                            }
                                        />
                                    )}

                                    {variableData.wantedDataType ===
                                        DataType.number && (
                                        <Gauge.StaticGauge.StaticNumber
                                            staticData={VariableMainRendering(
                                                stationData,
                                                variableData.id
                                            )}
                                            graphLabel={
                                                variableData.variableName
                                            }
                                        />
                                    )}
                                </div>
                                <div className="lg:w-2/3">
                                    <ul className="list-inside list-disc ml-6">
                                        <li>
                                            <span className="text-sm font-semibold text-gray-700">
                                                รูปแบบย่อยของเซ็นเซอร์ :{' '}
                                            </span>
                                            <span className="text-sm font-semibold text-blue-700">
                                                {variableData.wantedDataType}{' '}
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </form>
        </Box>
    )
}
