import StaticBoolean from './EditingStaticBooleanDisplay'
import StaticMeter from './EditStaticMeterDataDisplay'
import StaticNumber from './EditStaticNumberDisplay'
import StaticString from './EditStaticStringDisplay'
import StaticBooleanWater from './EditStaticBooleanWaterDisplay'

export default {
    StaticBoolean,
    StaticMeter,
    StaticNumber,
    StaticString,
    StaticBooleanWater,
}
