import _ from 'lodash'
import GenerateMathematicSymbol from '../../../util/GenerateMathematicSymbol'
import SubVariableDataFinding from './SubVariableDataFinding'
import MathematicResolver from '../../../util/LogicResolve'

// Main Rendering the value of Variable in Realtime

export default function VariableMainRendering(stationData, variableId) {
    /// Find the variable that want to display value

    const selectedVariable = _.find(
        stationData.variables,
        (variable) => variable.id === variableId
    )
    // console.log(
    //     'หาข้อมูลจากตัวแปรไอดี ',
    //     variableId,
    //     'ได้ค่า',
    //     selectedVariable
    // )

    if (selectedVariable) {
        const subVariable = selectedVariable?.multiLevelVariables
        const subOperation = selectedVariable?.multiLevelOperations

        //Display variable info
        console.log('Selected Variable is ', selectedVariable.variableName)
        console.log(
            'They are ',
            _.size(subOperation),
            ' step of operation inside '
        )

        let latestStateData = 0

        // Main Function
        for (let i = 0; i < _.size(subOperation); i++) {
            console.log('-----------------')
            console.log('การดำเนินการครั้งที่', i + 1)
            console.log(
                'Operation : ',
                subOperation[i].leftVariable,
                '',
                GenerateMathematicSymbol(subOperation[i].operation),
                '',
                subOperation[i].rightVariable
            )
            if (i === 0) {
                // Find Left variable
                // console.log(
                //     'กำลังหาตัวแปรด้านซ้าย ',
                //     subOperation[i].leftVariable
                // )
                const leftVariable = _.find(
                    subVariable,
                    (subVar) =>
                        subVar.subVariableName === subOperation[i].leftVariable
                )
                // console.log('ตัวแปรด้านซ้าย มาจาก', leftVariable?.typeOfSource)

                // Calculate Data on the Left
                const leftVariableData = SubVariableDataFinding(
                    stationData,
                    leftVariable
                )
                // console.log('ได้ค่าฝั่งซ้ายมือเป็น ', leftVariableData)

                // Find Right variable
                // console.log(
                //     'กำลังหาตัวแปรด้านขวา ',
                //     subOperation[i].rightVariable
                // )
                const rightVariable = _.find(
                    subVariable,
                    (subVar) =>
                        subVar.subVariableName === subOperation[i].rightVariable
                )
                // console.log('ตัวแปรด้านขวา มาจาก', rightVariable?.typeOfSource)
                // Calculate Data on the Right
                const rightVariableData = SubVariableDataFinding(
                    stationData,
                    rightVariable
                )
                // console.log('ได้ค่าฝั่งขวามือเป็น ', rightVariableData)

                const operatedData = MathematicResolver(
                    subOperation[i].operation,
                    leftVariableData,
                    rightVariableData
                )
                // console.log('ในขั้นตอนนี้  คำนวณค่าได้เป็น ==> ', operatedData)
                latestStateData = operatedData
            } else {
                // When is not start period we need to find only right subvariable
                // and it due with latestStateData that I had declare variable above

                const tempLatestStateData = latestStateData
                // Find Right variable
                // console.log(
                //     'กำลังหาตัวแปรด้านขวา ',
                //     subOperation[i].rightVariable
                // )
                const rightVariable = _.find(
                    subVariable,
                    (subVar) =>
                        subVar.subVariableName === subOperation[i].rightVariable
                )
                // console.log('ตัวแปรด้านขวา มาจาก', rightVariable?.typeOfSource)
                // Calculate Data on the Right
                const rightVariableData = SubVariableDataFinding(
                    stationData,
                    rightVariable
                )
                //   console.log('ได้ค่าฝั่งขวามือเป็น ', rightVariableData)

                const operatedData = MathematicResolver(
                    subOperation[i].operation,
                    tempLatestStateData,
                    rightVariableData
                )
                // console.log('ในขั้นตอนนี้  คำนวณค่าได้เป็น ==> ', operatedData)
                latestStateData = operatedData
            }

            if (i === _.size(subOperation) - 1) {
                // console.log(
                //     'ถึงขั้นสุดท้าย การคำนวณเสร็จสิ้น ได้ผลลัพธ์เป็น ',
                //     latestStateData
                // )
                return latestStateData
            }
        }
    } else {
        return false
    }
}
