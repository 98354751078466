import React from 'react'
import { Box } from '@chakra-ui/react'

export default function StaticStringDisplay({ staticData, graphLabel = '' }) {
    return (
        <Box
            maxW="sm"
            borderWidth="1px"
            borderRadius="lg"
            className=" ml-4 my-2 p-2 w-full  md:w-full  h-64"
        >
            <h5 className="font-bold text-2xl"> {graphLabel} </h5>
            <div>
                <h1 className=" font-bold text-4xl text-blue-500 mb-0 ">
                    {staticData}{' '}
                </h1>
            </div>
        </Box>
    )
}
