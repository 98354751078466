import _ from 'lodash'
import { DataType } from '../../../../config/DataType'
import MathematicResolver from '../../../../util/LogicResolve'

// It work only 2 sensors
export default function MultiSensorDataFinding(stationData, subvariableData) {
    // Find Left Sensor
    const leftSensor = _.find(
        stationData.sensors,
        (sensor) => sensor.refString === subvariableData.leftSensorRef
    )
    let leftSensorData
    if (leftSensor.sensorType === DataType.boolean) {
        leftSensorData = leftSensor.booleanStatus
    } else if (leftSensor.sensorType === DataType.number) {
        leftSensorData = leftSensor.numericStatus
    } else {
        return 0
    }

    // Find Right Sensor
    const rightSensor = _.find(
        stationData.sensors,
        (sensor) => sensor.refString === subvariableData.rightSensorRef
    )
    let rightSensorData
    if (rightSensor.sensorType === DataType.boolean) {
        rightSensorData = rightSensor.booleanStatus
    } else if (rightSensor.sensorType === DataType.number) {
        rightSensorData = rightSensor.numericStatus
    } else {
        return 0
    }

    // Find Control
    const operation = subvariableData.operation
    if (subvariableData.isReverseLeft === true) {
        if (typeof leftSensorData === 'boolean') {
            leftSensorData = !leftSensorData
        }
    }
    if (subvariableData.isReverseRight === true) {
        if (typeof rightSensorData === 'boolean') {
            rightSensorData = !rightSensorData
        }
    }

    const dataAfterResolve = MathematicResolver(
        operation,
        leftSensorData,
        rightSensorData
    )
    console.log(' Data After Resolve the Equation', dataAfterResolve)
    return dataAfterResolve
}
