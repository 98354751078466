import { BooleanOperation } from '../../config/MathOperation'

export default function BooleanLogicResolve(operation, leftData, rightData) {
    // Javascript จะมีระบบแปลตัวแปร เป็น Boolean เองอยู่แล้ว ถึงแม้เราจะโยน Int มาก็ตาม
    let result
    switch (operation) {
        case BooleanOperation.AND:
            console.log('AND')
            result = leftData && rightData
            break
        case BooleanOperation.OR:
            console.log('OR')
            result = leftData || rightData
            break
        case BooleanOperation.NOR:
            console.log('NOR')
            result = !(leftData || rightData)
            break
        case BooleanOperation.NAND:
            console.log('NAND')
            result = !(leftData && rightData)
            break
        default:
            break
    }
    return result
}
