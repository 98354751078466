import React, { useState } from 'react'
import {
    Box,
    Checkbox,
    FormControl,
    Input,
    Select,
    Button,
} from '@chakra-ui/react'
import _ from 'lodash'

import Config from '../../../config'

export default function DiagramAttributeMaking({
    attr,
    index,
    register,
    stationData,
    onDeleteAttribute,
}) {
    const [selectedSourceType, setSelectedSourceType] = useState(
        attr.dataSource
    )
    const [selectedSecondSourceType, setSelectedSecondSourceType] = useState(
        attr.secondDataSource
    )
    const [selectedPreferDataType, setSelectedPreferDataType] = useState(
        attr.preferDataType
    )

    const genRefStringList = () => {
        return _.map(stationData.sensors, (sensor) => (
            <option value={sensor.refString}>{sensor.refString}</option>
        ))
    }

    const genVariableList = () => {
        return _.map(stationData.variables, (variable) => (
            <option value={variable.id}>{variable.variableName}</option>
        ))
    }

    const colorList = () => (
        <>
            <option value="red">แดง</option>
            <option value="yellow">เหลือง</option>
            <option value="green">เขียว</option>
            <option value="blue">น้ำเงิน</option>
            <option value="purple">ม่วง</option>
            <option value="pink">ชมพู</option>
            <option value="gray">เทา</option>
        </>
    )

    return (
        <Box
            key={index}
            padding="4"
            borderRadius="lg"
            borderWidth="2"
            className="text-sm font-semibold border mb-2"
        >
            <div className="flex justify-between">
                <h5 className="mb-2">รายการที่ {index + 1} </h5>
                <Button
                    colorScheme="red"
                    size="sm"
                    onClick={() => {
                        onDeleteAttribute(index)
                    }}
                >
                    ลบ
                </Button>
            </div>
            <FormControl>
                <label>ชื่อของค่า</label>
                <Input
                    type="text"
                    name={`attribute[${index}].label`}
                    defaultValue={attr.label}
                    ref={register}
                    size="sm"
                />
            </FormControl>
            <FormControl>
                <label>รูปแบบของค่า</label>
                <Select
                    name={`attribute[${index}].preferDataType`}
                    defaultValue={attr.preferDataType}
                    size="sm"
                    ref={register}
                    value={selectedPreferDataType}
                    onChange={(event) =>
                        setSelectedPreferDataType(event.target.value)
                    }
                >
                    <option value="">เลือกรูปแบบของค่าที่จะแสดง</option>
                    <option value={Config.DataType.DataType.boolean}>
                        Boolean
                    </option>
                    <option value={Config.DataType.DataType.number}>
                        ตัวเลข
                    </option>
                    <option value={Config.DataType.DataType.string}>
                        ตัวอักษร
                    </option>
                    <option value={Config.DataType.DataType.twoStepBoolean}>
                        Boolean 2 ขั้น
                    </option>
                </Select>
            </FormControl>

            <FormControl>
                <label>ที่มาของค่า</label>
                <Select
                    name={`attribute[${index}].dataSource`}
                    defaultValue={attr.dataSource}
                    size="sm"
                    ref={register}
                    value={selectedSourceType}
                    onChange={(event) =>
                        setSelectedSourceType(event.target.value)
                    }
                >
                    <option value="">เลือกที่มาของค่า</option>
                    <option value={Config.TypeOfSource.GaugeSource.sensor}>
                        เซ็นเซอร์
                    </option>
                    <option value={Config.TypeOfSource.GaugeSource.variable}>
                        ตัวแปร
                    </option>
                </Select>
            </FormControl>
            {selectedSourceType === Config.TypeOfSource.GaugeSource.sensor ? (
                <FormControl>
                    <label>เซ็นเซอร์ที่ต้องการนำมาแสดง</label>
                    <Select
                        name={`attribute[${index}].sensorRef`}
                        defaultValue={attr.sensorRef}
                        ref={register}
                        size="sm"
                    >
                        <option value="">เลือกที่มาของค่า</option>
                        {genRefStringList()}
                    </Select>
                </FormControl>
            ) : (
                <FormControl>
                    <label>ตัวแปรที่ต้องการนำมาแสดง</label>
                    <Select
                        name={`attribute[${index}].variableId`}
                        defaultValue={attr.variableId}
                        ref={register}
                        size="sm"
                    >
                        <option value="">เลือกที่มาของค่า</option>
                        {genVariableList()}
                    </Select>
                </FormControl>
            )}
            {selectedPreferDataType === Config.DataType.DataType.number && (
                <>
                    <FormControl>
                        <label>หน่วยของค่า</label>
                        <Input
                            type="text"
                            defaultValue={attr.unit}
                            name={`attribute[${index}].unit`}
                            ref={register}
                            size="sm"
                        />
                    </FormControl>
                </>
            )}
            {selectedPreferDataType === Config.DataType.DataType.boolean && (
                <>
                    <FormControl>
                        <Checkbox
                            defaultChecked={attr.isActiveHigh}
                            name={`attribute[${index}].isActiveHigh`}
                            ref={register}
                            size="sm"
                        >
                            ค่า Active High
                        </Checkbox>
                    </FormControl>
                    <FormControl>
                        <label>คำที่แสดงเมื่อค่า Active</label>
                        <Input
                            type="text"
                            defaultValue={attr.labelActive}
                            name={`attribute[${index}].labelActive`}
                            ref={register}
                            size="sm"
                        />
                    </FormControl>
                    <FormControl>
                        <label>คำที่แสดงเมื่อค่า Inactive</label>
                        <Input
                            type="text"
                            defaultValue={attr.labelInactive}
                            name={`attribute[${index}].labelInactive`}
                            ref={register}
                            size="sm"
                        />
                    </FormControl>
                </>
            )}
            {selectedPreferDataType ===
                Config.DataType.DataType.twoStepBoolean && (
                <>
                    <FormControl>
                        <label>ที่มาของค่าที่สอง</label>
                        <Select
                            name={`attribute[${index}].secondDataSource`}
                            defaultValue={attr.secondDataSource}
                            size="sm"
                            ref={register}
                            value={selectedSecondSourceType}
                            onChange={(event) =>
                                setSelectedSecondSourceType(event.target.value)
                            }
                        >
                            <option value="">เลือกที่มาของค่า</option>
                            <option
                                value={Config.TypeOfSource.GaugeSource.sensor}
                            >
                                เซ็นเซอร์
                            </option>
                            <option
                                value={Config.TypeOfSource.GaugeSource.variable}
                            >
                                ตัวแปร
                            </option>
                        </Select>
                    </FormControl>

                    {selectedSecondSourceType ===
                    Config.TypeOfSource.GaugeSource.sensor ? (
                        <FormControl>
                            <label>เซ็นเซอร์ที่ต้องการนำมาแสดง</label>
                            <Select
                                name={`attribute[${index}].secondSensorRef`}
                                defaultValue={attr.secondSensorRef}
                                ref={register}
                                size="sm"
                            >
                                <option value="">เลือกที่มาของค่า</option>
                                {genRefStringList()}
                            </Select>
                        </FormControl>
                    ) : (
                        <FormControl>
                            <label>ตัวแปรที่ต้องการนำมาแสดง</label>
                            <Select
                                name={`attribute[${index}].secondVariableId`}
                                defaultValue={attr.secondVariableId}
                                ref={register}
                                size="sm"
                            >
                                <option value="">เลือกที่มาของค่า</option>
                                {genVariableList()}
                            </Select>
                        </FormControl>
                    )}

                    <FormControl>
                        <div className="flex gap-1">
                            <div>
                                <label>
                                    คำที่แสดงเมื่อค่าเป็น{' '}
                                    <b className="text-green-500">จริง</b> และ{' '}
                                    <b className="text-green-500">จริง</b>
                                </label>
                                <Input
                                    type="text"
                                    defaultValue={
                                        attr.labelOnTwoStep
                                            ? attr.labelOnTwoStep.trueTrue
                                            : ''
                                    }
                                    name={`attribute[${index}].labelOnTwoStep.trueTrue`}
                                    ref={register}
                                    size="sm"
                                />
                            </div>
                            <div>
                                <label>สีที่แสดง</label>
                                <Select
                                    defaultValue={
                                        attr.colorOnTwoStep
                                            ? attr.colorOnTwoStep.trueTrue
                                            : ''
                                    }
                                    name={`attribute[${index}].colorOnTwoStep.trueTrue`}
                                    ref={register}
                                    size="sm"
                                >
                                    <option value="">เลือกสี</option>
                                    {colorList()}
                                </Select>
                            </div>
                        </div>
                    </FormControl>
                    <FormControl>
                        <div className="flex gap-1">
                            <div>
                                {' '}
                                <label>
                                    คำที่แสดงเมื่อค่าเป็น{' '}
                                    <b className="text-green-500">จริง</b> และ{' '}
                                    <b className="text-red-500">เท็จ</b>
                                </label>
                                <Input
                                    type="text"
                                    defaultValue={
                                        attr.labelOnTwoStep
                                            ? attr.labelOnTwoStep.trueFalse
                                            : ''
                                    }
                                    name={`attribute[${index}].labelOnTwoStep.trueFalse`}
                                    ref={register}
                                    size="sm"
                                />
                            </div>
                            <div>
                                <label>สีที่แสดง</label>
                                <Select
                                    defaultValue={
                                        attr.colorOnTwoStep
                                            ? attr.colorOnTwoStep.trueFalse
                                            : ''
                                    }
                                    name={`attribute[${index}].colorOnTwoStep.trueFalse`}
                                    ref={register}
                                    size="sm"
                                >
                                    <option value="">เลือกสี</option>
                                    {colorList()}
                                </Select>
                            </div>
                        </div>
                    </FormControl>
                    <FormControl>
                        <div className="flex gap-1">
                            <div>
                                <label>
                                    คำที่แสดงเมื่อค่าเป็น{' '}
                                    <b className="text-red-500">เท็จ</b> และ{' '}
                                    <b className="text-green-500">จริง</b>
                                </label>
                                <Input
                                    type="text"
                                    defaultValue={
                                        attr.labelOnTwoStep
                                            ? attr.labelOnTwoStep.falseTrue
                                            : ''
                                    }
                                    name={`attribute[${index}].labelOnTwoStep.falseTrue`}
                                    ref={register}
                                    size="sm"
                                />
                            </div>
                            <div>
                                <label>สีที่แสดง</label>
                                <Select
                                    defaultValue={
                                        attr.colorOnTwoStep
                                            ? attr.colorOnTwoStep.falseTrue
                                            : ''
                                    }
                                    name={`attribute[${index}].colorOnTwoStep.falseTrue`}
                                    ref={register}
                                    size="sm"
                                >
                                    <option value="">เลือกสี</option>
                                    {colorList()}
                                </Select>
                            </div>
                        </div>
                    </FormControl>
                    <FormControl>
                        <div className="flex gap-1">
                            <div>
                                {' '}
                                <label>
                                    คำที่แสดงเมื่อค่าเป็น{' '}
                                    <b className="text-red-500">เท็จ</b> และ{' '}
                                    <b className="text-red-500">เท็จ</b>
                                </label>
                                <Input
                                    type="text"
                                    defaultValue={
                                        attr.labelOnTwoStep
                                            ? attr.labelOnTwoStep.falseFalse
                                            : ''
                                    }
                                    name={`attribute[${index}].labelOnTwoStep.falseFalse`}
                                    ref={register}
                                    size="sm"
                                />
                            </div>
                            <div>
                                <label>สีที่แสดง</label>
                                <Select
                                    defaultValue={
                                        attr.colorOnTwoStep
                                            ? attr.colorOnTwoStep.falseFalse
                                            : ''
                                    }
                                    name={`attribute[${index}].colorOnTwoStep.falseFalse`}
                                    ref={register}
                                    size="sm"
                                >
                                    <option value="">เลือกสี</option>
                                    {colorList()}
                                </Select>
                            </div>
                        </div>
                    </FormControl>
                </>
            )}
        </Box>
    )
}
