import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import _ from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import * as actions from '../../../redux/actions'
import { Button } from '@chakra-ui/react'
import SpinnerLoading from 'components/Loading/SpinnerLoading'

export default function CreateTemplate() {
  const { register, handleSubmit, reset } = useForm()
  const [isModalConfirmOpen, setIsModalConfirmOpen] = useState(false)
  const [templateId, setTemplateId] = useState('')
  const [formData, setFormData] = useState()
  const allTemplates = useSelector((state) => state.stationTemplate)
  const dispatch = useDispatch()
  const history = useHistory()
  useEffect(() => {
    dispatch(actions.stationTemplateAll())
    return () => {}
  }, [])

  const onSubmit = async (data, e) => {
    e.preventDefault()
    console.log('From Data', data)
    dispatch(actions.stationTemplatePost(data))
    history.goBack()
  }

  return allTemplates && allTemplates.arr ? (
    <>
      <div className="container mx-auto px-4 h-full">
        <div className="flex content-center items-center justify-center h-full">
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-100 border-0">
              <div className="rounded-t mb-0 px-6 py-6">
                <div className="text-center mb-3">
                  <h6 className="text-gray-600 text-base font-sans font-bold">
                    สร้างแม่แบบสถานี
                  </h6>
                </div>
                <hr className="mt-6 border-b-1 border-gray-400" />
              </div>
              <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <h6 className="text-gray-500 text-base font-sans mt-3 mb-6 font-bold uppercase">
                    ข้อมูลสถานี
                  </h6>
                  <div className="flex flex-wrap">
                    <div className="w-full px-4">
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-gray-700 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          ชื่อแม่แบบ
                        </label>
                        <input
                          name="templateName"
                          type="text"
                          required
                          ref={register}
                          className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                          placeholder="แม่แบบสถานี"
                        />
                      </div>
                    </div>

                    <div className="w-full px-4">
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase  text-gray-700 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          รายละเอียดของแม่แบบ
                        </label>
                        <textarea
                          name="templateDescription"
                          type="textarea"
                          ref={register}
                          className="px-3 py-3  placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                          placeholder="แม่แบบของสถานีประจำหมู่บ้าน ใกล้วัด ..."
                        />
                      </div>
                    </div>

                    <div className="w-full px-4">
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-gray-700 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          รหัสประจำแม่แบบ
                        </label>

                        <input
                          name="templateId"
                          type="text"
                          ref={register}
                          className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                          placeholder="001"
                        />
                      </div>
                    </div>

                    {/** End of Input */}
                  </div>

                  <div className="text-center px-4 gap-0 py-3 ">
                    <Button
                      isFullWidth
                      colorScheme="blue"
                      variant="solid"
                      type="submit"
                    >
                      สร้างแม่แบบสถานี{' '}
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <>
      <SpinnerLoading />
    </>
  )
}
