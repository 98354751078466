import React from 'react'
import { Tr, Td, Button } from '@chakra-ui/react'
import GenerateMathematicSymbol from '../../../util/GenerateMathematicSymbol'

export default function OperationListShowing({
    index,
    eachOperation,
    deleteOperation,
}) {
    return (
        <>
            <Tr key={index} className="text-sm">
                <Td> {index + 1} </Td>
                <Td>
                    {' '}
                    {eachOperation.leftVariable
                        ? eachOperation.leftVariable
                        : 'นำค่ามาจากส่วนที่ ' + index}
                </Td>
                <Td> {GenerateMathematicSymbol(eachOperation.operation)}</Td>
                <Td> {eachOperation.rightVariable}</Td>
                <Td>
                    <Button
                        size="sm"
                        colorScheme="red"
                        onClick={() => {
                            deleteOperation(index)
                        }}
                    >
                        ลบ
                    </Button>
                </Td>
            </Tr>
        </>
    )
}
