import _ from 'lodash'
import { VariableTypeOfSource } from '../../../../config/VariableTypeOfSource'
import { DataType } from '../../../../config/DataType'

export default function OneSensorDataFinding(stationData, subvariableData) {
    // Find Data from station
    const selectedSensor = _.find(
        stationData.sensors,
        (sensor) => sensor.refString === subvariableData.sensorRef
    )
    if (selectedSensor.sensorType === DataType.boolean) {
        return selectedSensor.booleanStatus
    } else if (selectedSensor.sensorType === DataType.number) {
        return selectedSensor.numericStatus
    } else {
        return 0
    }
}
