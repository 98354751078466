import React, { useEffect, useState } from "react"

import { useDispatch, useSelector } from "react-redux"
import * as actions from "../../redux/actions"
import { Link, useParams, useHistory } from "react-router-dom"
// components
import { Button, Collapse } from "@chakra-ui/react"
import DashboardMonitorComponent from "./Components/DashboardMonitorComponent"
import SpinnerLoading from "components/Loading/SpinnerLoading"
import PlaceHolderLogo from '../../assets/img/eiotlogo.png'
import _ from "lodash"

export default function StationDashboard({ sidebarStatus, setIsShowSidebar }) {
  const [isLoading, setIsLoading] = useState(false)
  const [currentStation, setcurrentStation] = useState()
  const [isFetchNewOne, setisFetchNewOne] = useState(true)
  const [isStationInfoShow, setIsStationInfoShow] = useState(false)
  const dispatch = useDispatch()
  const params = useParams()
  const allStations = useSelector((state) => state.station)
  const me = useSelector((state) => state.me)
  const history = useHistory()
  useEffect(() => {
    if (me && me._id && currentStation) {
      if (me.duty === "ALLAREA" || me.duty === currentStation._id) {
      } else {
        console.log("ผู้ใช้ไม่มีสิทธิเข้าใช้")
        history.push("/dashboards")
      }
    }
    return () => { }
  }, [me, currentStation])

  useEffect(() => {
    if (isFetchNewOne === true) {
      dispatch(actions.stationAll())
      //FIXME: Uncommen to Enable Auto Refresh
      setisFetchNewOne(false)
    }
    return () => { }
  }, [isFetchNewOne])

  // ดึงข้อมูลสถานีทั้งหมดมา เพราะว่า จะต้องเอาไปโชว์ใน Sidebar
  // จากนั้นค่อยหาเอาตัวหนึ่งที่ไอดีมันถูก
  useEffect(() => {
    if (allStations) {
      const tempcurrentStatus = _.find(
        allStations.arr,
        (station) => station._id === params.stationId
      )
      setcurrentStation(tempcurrentStatus)
      console.log("Curent Station", currentStation)
    }
    return () => { }
  }, [params, allStations])

  useEffect(() => {
    setIsLoading(true)
    return () => { }
  }, [])

  useEffect(() => {
    if (isFetchNewOne === false) {
      setTimeout(() => {
        setisFetchNewOne(true)
      }, 10000)
    }
    return () => { }
  }, [isFetchNewOne])

  const handleRefreshStation = () => {
    dispatch(actions.stataionRefresh(currentStation._id))
  }

  return isLoading && currentStation ? (
    <div className="relative flex flex-col min-w-0 break-words bg-gray-100 w-full mb-6 shadow-lg rounded">
      <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
        <div className="flex flex-wrap items-center">
          <div className="relative w-full max-w-full flex-grow flex-1">
            <h6 className="uppercase text-gray-500 mb-1 text-xs font-semibold font-sans">
              เฝ้าระวังและตรวจสอบระบบ
                        </h6>
            <h2 className="text-gray-800 text-2xl font-bold font-sans">
              แดชบอร์ด {currentStation.name}
            </h2>
          </div>
        </div>
      </div>
      <div className="flex justify-between p-4">
        <div className="flex justify-items-start">
          <Button
            colorScheme="blue"
            variant="solid"
            size="sm"
            onClick={() => handleRefreshStation()}
          >
            รีเฟรช
                    </Button>
        </div>
        <div className="flex justify-items-end gap-1">
          <Button
            colorScheme="gray"
            variant="solid"
            size="sm"
            onClick={() => setIsStationInfoShow(!isStationInfoShow)}
          >
            ข้อมูลสถานี
                    </Button>
          <Button
            colorScheme="gray"
            variant="solid"
            size="sm"
            onClick={() => setIsShowSidebar(!sidebarStatus)}
          >
            เปิด/ปิดแถบข้าง
                    </Button>
        </div>
      </div>
      <div className="p-4 w-full font-sans">
        <Collapse in={isStationInfoShow} animateOpacity>
          <div className="bg-gray-200 border rounded-md p-2">
            <div className="flex mx-2 ">
              <div className="w-1/6 flex justify-center">
                <img
                  src={
                    currentStation.image
                      ? currentStation.image
                      : PlaceHolderLogo
                  }
                  alt={currentStation.name}
                  className="h-24"
                />
              </div>
              <div className="w-2/6 my-2">
                <h3 className="text-gray-800 text-xl font-bold font-sans">
                  {currentStation.name}
                </h3>
                <p className="text-gray-800 text-base  font-sans">
                  {currentStation.description}
                </p>
                <h6 className="text-gray-800 text-base font-bold font-sans">
                  {currentStation.location}
                </h6>
              </div>
              <div className="w-2/6 my-2">
                <h6 className="text-gray-800 text-base font-bold font-sans">
                  แผนที่สถานี
                                </h6>
                <iframe
                  src={
                    'https://www.google.com/maps/embed/v1/place?key=AIzaSyCW6HXYlqqnvyEVcgjXfdFVZvHxz7l-btQ&q=' +
                    currentStation.location +
                    '&region=th&language=th'
                  }
                  width="380"
                  height="200"
                  allowfullscreen=""
                  loading="lazy"
                ></iframe>
              </div>
            </div>
          </div>
        </Collapse>
        <DashboardMonitorComponent currentStation={currentStation} />
      </div>
    </div>
  ) : (
      <SpinnerLoading />
    )
}
