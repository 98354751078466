import _ from 'lodash'
import { VariableTypeOfSource } from '../../../config/VariableTypeOfSource'
import { DataType } from '../../../config/DataType'

// Find Data from Each Variable Type
import OneSensorDataFinding from './FomVariableType/OneSensor'
import MultiSensorDataFinding from './FomVariableType/MultiSensor'
import SensorAndVariableDataFinding from './FomVariableType/SensorAndVariable'
// Main Rendering the value of Variable in Realtime

export default function subVariableDataFinding(stationData, subvariableData) {
    if (subvariableData) {
        if (subvariableData.typeOfSource === VariableTypeOfSource.constant) {
            return subvariableData.constantValue
        } else if (
            subvariableData.typeOfSource === VariableTypeOfSource.sensor
        ) {
            //  console.log('ตรวจพบการหาค่าจาก 1 Sensor')
            return OneSensorDataFinding(stationData, subvariableData)
        } else if (
            subvariableData.typeOfSource === VariableTypeOfSource.multiSensor
        ) {
            // console.log('ตรวจพบการหาค่าจากหลาย Sensor')
            return MultiSensorDataFinding(stationData, subvariableData)
        } else if (
            subvariableData.typeOfSource ===
            VariableTypeOfSource.sensorAndConstant
        ) {
            // console.log('ตรวจพบการหาค่าจาก 1 Sensor 1 ค่าคงที่')
            return SensorAndVariableDataFinding(stationData, subvariableData)
        } else {
            // console.log(
            //     'Type of Source of sub varaible is invalid ',
            //     stationData.typeOfSource
            // )
            return 0
        }
    } else {
        return 0
    }
}
