import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../redux/actions";
import { Link, useParams, useHistory } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
// components
import { Box, Button, Flex } from '@chakra-ui/react'
import SpinnerLoading from 'components/Loading/SpinnerLoading'
import ChartRenderingComponent from '../../components/Graphs/Functions/ChartRendering'

export default function StatisticGraph() {
  const [isLoading, setIsLoading] = useState(false)
  const [currentStation, setcurrentStation] = useState()
  const [currentDateRange, setcurrentDateRange] = useState([
    undefined,
    undefined,
  ])
  const [selectedChart, setSelectedChart] = useState(null)
  const dispatch = useDispatch()
  const params = useParams()
  const allStations = useSelector((state) => state.station)
  const allLogs = useSelector((state) => state.log)
  const me = useSelector((state) => state.me)
  const system = useSelector((state) => state.system)
  const [isGraphShowing, setIsGraphShowing] = useState(false)
  const [tickAmount, setTickAmount] = useState(8)
  const [valueFormat, setValueFormat] = useState('DD MMM YYYY')
  const history = useHistory()
  useEffect(() => {
    if (me && me._id && currentStation) {
      if (me.duty === 'ALLAREA' || me.duty === currentStation._id) {
        //   dispatch(actions.logOnEachStation(currentStation._id))
      } else {
        console.log('ผู้ใช้ไม่มีสิทธิเข้าใช้')
        history.push('/dashboards')
      }
    }
    return () => { }
  }, [me, currentStation])

  useEffect(() => {
    dispatch(actions.stationAll()).then(() => setIsLoading(true))
    return () => { }
  }, [])

  useEffect(() => {
    if (allStations) {
      const tempcurrentStatus = _.find(
        allStations.arr,
        (station) => station._id === params.stationId
      )
      setcurrentStation(tempcurrentStatus)
      console.log('Curent Station', currentStation)
    }
    return () => { }
  }, [params, allStations])

  const logOnSpecificDate = () => {
    if (
      currentDateRange === undefined ||
      currentDateRange[0] === undefined ||
      currentDateRange[1] === undefined
    ) {
      window.alert('ขออภัย คุณต้องกำหนดช่วงเวลาก่อน')
    } else {
      setIsLoading(false)
      dispatch(
        actions.logOnDaySpecific(
          currentStation?._id,
          currentDateRange[0],
          currentDateRange[1]
        )
      ).then(() => {
        setTimeout(() => {
          setIsLoading(true)
          setIsGraphShowing(!isGraphShowing)
        }, 1000)
      })
    }
  }
  const renderGraph = () => {
    return _.map(currentStation.charts, (chart) => (
      <div id={chart.id}>
        {chart.id === selectedChart ? (
          <>
            {' '}
            <ChartRenderingComponent
              chartData={chart}
              data={allLogs}
              graphMax={currentDateRange[1]}
              graphMin={currentDateRange[0]}
              setGraphMax={undefined}
              setGraphMin={undefined}
              setTickAmount={setTickAmount}
              setValueFormat={setValueFormat}
              tickAmount={undefined}
              valueFormat={valueFormat}
              stationData={currentStation}
              enableTimeSelect={false}
            />
          </>
        ) : (
            <></>
          )}
      </div>
    ))
  }
  const genChartList = () => {
    if (_.size(currentStation.sensors)) {
      return _.map(currentStation.charts, (chart, index) => (
        <option key={index} value={chart.id}>
          {chart.chartLabel}
        </option>
      ))
    } else {
      return (
        <option value="" selected disabled>
          {''}
        </option>
      )
    }
  }
  return isLoading && currentStation ? (
    <div className="relative flex flex-col min-w-0 break-words bg-gray-100 w-full mb-6 shadow-lg rounded print-area">
      <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
        <div className="flex flex-wrap items-center print-area">
          <div className="relative w-full max-w-full flex-grow flex-1">
            <p className="print-only text-xl">
              {system?.name} | {system?.systemOwner}
              <hr />
            </p>
            <h6 className="uppercase text-gray-500 mb-1 text-xs font-semibold font-sans print-disable">
              ข้อมูลและสถิติ
                        </h6>
            <h2 className="text-gray-800 text-2xl font-bold font-sans">
              กราฟ {currentStation.name}
            </h2>
            <p className="text-base text-gray-500 mb-1  font-sans ">
              {currentStation.location}
            </p>
            <p className="print-only">
              ข้อมูลจากวันที่{' '}
              {moment(currentDateRange[0]).format('DD MMMM YYYY')}{' '}
                            ถึง{' '}
              {moment(currentDateRange[1]).format('DD MMMM YYYY')}
            </p>
          </div>
        </div>
      </div>
      <div className="flex flex-wrap  p-4 gap-4 print-disable">
        {/* ช่วงเวลา */}
        <div className="w-full gap-2 flex flex-wrap my-2">
          <label className="block uppercase text-gray-700 text-xs font-bold my-2 mx-2">
            ช่วงเวลา
                    </label>
          <DateRangePicker
            onChange={(value) => {
              if (value) {
                let tempStart = value[0].getTime()
                let tempEnd = value[1].getTime()
                setcurrentDateRange([tempStart, tempEnd])
              } else {
                setcurrentDateRange([undefined, undefined])
              }
              setIsGraphShowing(false)
            }}
            value={currentDateRange}
          />
          <Button
            colorScheme="blue"
            size="sm"
            onClick={() => {
              logOnSpecificDate()
            }}
          >
            ดึงข้อมูล{' '}
          </Button>
        </div>

        {isGraphShowing && (
          <div className="gap-2 flex flex-wrap ">
            <label className="my-2 block uppercase text-gray-700 text-xs font-bold mb-2 mx-2">
              แสดงผลกราฟข้อมูล
                        </label>
            <select
              name="chartList"
              type="text"
              required
              className=" placeholder-gray-400 text-gray-700 bg-white rounded text-xs shadow focus:outline-none focus:shadow-outline  ease-linear transition-all duration-150"
              onChange={(e) => {
                console.log('selectedChart', e.target.value)
                setSelectedChart(e.target.value)
              }}
            >
              <option value="" disabled selected>
                กรุณาเลือกข้อมูล
                            </option>
              {genChartList()}
            </select>

            <Button
              colorScheme="red"
              size="sm"
              hidden={!isGraphShowing && !selectedChart}
              onClick={() => {
                setTimeout(() => {
                  window.print()
                }, 1000)
              }}
            >
              PDF
                        </Button>
          </div>
        )}
      </div>
      <div className="p-6">
        {isGraphShowing === false && selectedChart ? (
          <></>
        ) : (
            renderGraph()
          )}
      </div>
    </div>
  ) : (
      <SpinnerLoading />
    )
}
