import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../redux/actions";
import { Link, useParams, useHistory } from "react-router-dom";
import _ from "lodash";

// components
import { Box, Button, Flex } from '@chakra-ui/react'
import SpinnerLoading from 'components/Loading/SpinnerLoading'
import ChartRenderingComponent from '../../components/Graphs/Functions/ChartRendering'

// ! ตอนนี้กราฟ จะ Rander ได้เฉพาะข้อมูลที่เป็น Number ยังไม่สามารถ Rerender จากข้อมูลที่เป็น Boolean ได้
export default function StationGraph({ sidebarStatus, setIsShowSidebar }) {
  const [isLoading, setIsLoading] = useState(false)
  const [currentStation, setcurrentStation] = useState()
  const [isFetchNewOne, setisFetchNewOne] = useState(true)
  const [isFirstTime, setIsFirstTime] = useState(true)
  const dispatch = useDispatch()
  const params = useParams()
  const allStations = useSelector((state) => state.station)
  const allLogs = useSelector((state) => state.log)
  const [graphMin, setGraphMin] = useState(undefined)
  const [graphMax, setGraphMax] = useState(undefined)
  const [tickAmount, setTickAmount] = useState(8)
  const [valueFormat, setValueFormat] = useState('HH:mm')

  const me = useSelector((state) => state.me)
  const history = useHistory()

  useEffect(() => {
    if (me && me._id && currentStation) {
      if (me.duty === 'ALLAREA' || me.duty === currentStation._id) {
      } else {
        console.log('ผู้ใช้ไม่มีสิทธิเข้าใช้')
        history.push('/dashboards')
      }
    }
    return () => { }
  }, [me, currentStation])

  useEffect(() => {
    dispatch(actions.stationAll())
    return () => { }
  }, [])

  // ? เราจำเป็นต้อง Fetch ค่าจากทุุกสถานีมาก่อน แล้วค่อยใช้ Use State แบ่งเอา
  // เพราะเราต้องโชว์ใน Sidebar ทุกอัน
  useEffect(() => {
    if (allStations) {
      const tempcurrentStatus = _.find(
        allStations.arr,
        (station) => station._id === params.stationId
      )
      setcurrentStation(tempcurrentStatus)
      console.log('Curent Station', currentStation)
    }
    return () => { }
  }, [params, allStations])

  // ! Increase Performance โดยการให้ตอนแรกดึงเฉพาะ 20 ค่าล่าสุดมาก่อน
  // จากนั้น ค่อยให้มันดึงข้อมูลทั้งหมด (จากตารางแนวโน้มแต่ละตาราง) มาอีกครั้ง
  useEffect(() => {
    if (currentStation && isFetchNewOne === true) {
      if (isFirstTime === true) {
        dispatch(actions.logLastestTwenty(currentStation._id)).then(
          () => {
            setIsLoading(true)
            setisFetchNewOne(false)
            dispatch(actions.logByHour(currentStation._id))
            setIsFirstTime(false)
          }
        )
      } else {
        setTickAmount(10)
        setGraphMin(undefined)
        setGraphMax(undefined)
        dispatch(actions.logByHour(currentStation._id))
        setisFetchNewOne(false)
      }
    }
    return () => { }
  }, [isFetchNewOne, currentStation])

  useEffect(() => {
    if (isFetchNewOne === false) {
      setTimeout(() => {
        setisFetchNewOne(true)
      }, 300000)
      //ปรับกราฟใหม่
    }
    return () => { }
  }, [isFetchNewOne])

  // console.log(currentStation);

  return isLoading && currentStation ? (
    <div className="relative flex flex-col min-w-0 break-words bg-gray-100 w-full mb-6 shadow-lg rounded">
      <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
        <div className="flex flex-wrap items-center">
          <div className="relative w-full max-w-full flex-grow flex-1">
            <h6 className="uppercase text-gray-500 mb-1 text-xs font-semibold font-sans ">
              เฝ้าระวังและตรวจสอบระบบ
                        </h6>
            <h2 className="text-gray-800 text-2xl font-bold font-sans">
              กราฟ {currentStation.name}
            </h2>
            <p className="text-base text-gray-500 mb-1  font-sans ">
              {currentStation.location}
            </p>
          </div>
        </div>
      </div>
      <div className="flex justify-between p-4">
        <div className="flex justify-items-start"></div>
        <div className="flex justify-items-end">
          <Button
            colorScheme="gray"
            variant="solid"
            size="sm"
            onClick={() => setIsShowSidebar(!sidebarStatus)}
          >
            เปิด/ปิดแถบข้าง
                    </Button>
        </div>
      </div>
      <div className="p-4 w-full">
        {_.map(
          _.orderBy(currentStation.charts, 'orderLevel', 'asc'),
          (chart, index) => (
            <ChartRenderingComponent
              key={index}
              chartData={chart}
              data={allLogs}
              graphMax={graphMax}
              graphMin={graphMin}
              setGraphMax={setGraphMax}
              setGraphMin={setGraphMin}
              setTickAmount={setTickAmount}
              setValueFormat={setValueFormat}
              tickAmount={tickAmount}
              valueFormat={valueFormat}
              stationData={currentStation}
              enableTimeSelect={true}
            />
          )
        )}
      </div>
    </div>
  ) : (
      <SpinnerLoading />
    )
}
