import React from 'react'
import { Table, Thead, Tr, Th, Td, Tbody, Button } from '@chakra-ui/react'
import * as UserRoles from 'config/UserRoles'
import _ from 'lodash'
import { useSelector } from 'react-redux'

export default function UserLists({ user, handleDelelte, handleUpdate }) {
  const station = useSelector((state) => state.station)
  const genUserLists = () =>
    _.map(user, (_user, index) => (
      <Tr key={_user._id}>
        <Td>{index + 1}</Td>
        <Td>{_user.name}</Td>
        <Td>{_user.email}</Td>
        <Td>{_user.tel}</Td>
        <Td>
          <select
            onChange={(e) => {
              const data = { role: e.target.value }
              const confirm = window.confirm(
                'ยืนยันเปลี่ยนบทบาทบัญชีผู้ใช้จาก' +
                  _user.role +
                  'เป็น' +
                  e.target.value
              )
              if (confirm) handleUpdate(_user.id, data)
            }}
            defaultValue={_user.role}
            className="rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
          >
            {genUserRolesOptions()}
          </select>
        </Td>
        <Td>
          {' '}
          <select
            defaultValue={_user.duty}
            onChange={(e) => {
              const data = { duty: e.target.value }
              const confirm = window.confirm(
                'ยืนยันเปลี่ยนบทบาทบัญชีผู้ใช้ในการดูแลสถานี'
              )
              if (confirm) handleUpdate(_user.id, data)
            }}
            className="rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
          >
            <option value="ALLAREA">อนุญาตทุกสถานี</option>
            {genStationList()}
          </select>
        </Td>
        <Td>
          <Button
            colorScheme="red"
            size="sm"
            onClick={() => {
              if (_user.role == UserRoles.ROLES.SUPER_USER) {
                alert('ไม่สามารถลบบัญชี SUPERUSER')
              } else {
                const confirm = window.confirm('ยืนยันการลบบัญชีผู้ใช้')
                if (confirm) handleDelelte(_user.id)
              }
            }}
          >
            ลบ
          </Button>
        </Td>
      </Tr>
    ))

  const genStationList = () => {
    return _.map(station.arr, (eachStation, index) => (
      <option key={index} value={eachStation._id}>
        {eachStation.name}{' '}
      </option>
    ))
  }

  const genUserRolesOptions = () => (
    <>
      <option value={UserRoles.ROLES.USER}>{UserRoles.ROLES.USER}</option>
      <option value={UserRoles.ROLES.GENERAL}>{UserRoles.ROLES.GENERAL}</option>
      <option value={UserRoles.ROLES.ADMIN}>{UserRoles.ROLES.ADMIN}</option>
      <option value={UserRoles.ROLES.SUPER_USER}>
        {UserRoles.ROLES.SUPER_USER}
      </option>
    </>
  )
  return (
    <div>
      <Table size="sm" variant="striped" className="whitespace-no-wrap">
        <Thead>
          <Tr>
            <Th>#</Th>
            <Th>ชื่อ-สกุล</Th>
            <Th>Email</Th>
            <Th> เบอร์โทรศัพท์</Th>
            <Th> บทบาท</Th>
            <Th> ผู้ดูแลสถานี</Th>
            <Th> ดำเนินการ</Th>
          </Tr>
        </Thead>
        <Tbody>{genUserLists()}</Tbody>
      </Table>
    </div>
  )
}
