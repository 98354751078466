// common type
export const ME_GET = "ME_GET";
export const ME_RESET = "ME_RESET";

export const USER_ALL = "USER_ALL";
export const USER_GET = "USER_GET";
export const USER_POST = "USER_POST";
export const USER_PUT = "USER_PUT";
export const USER_DEL = "USER_DEL";

export const SYSTEM_GET = "SYSTEM_GET";
export const SYSTEM_POST = "SYSTEM_POST";
export const SYSTEM_PUT = "SYSTEM_PUT";
export const SYSTEM_DEL = "SYSTEM_DEL";

// feature type
export const STATION_ALL = "STATION_ALL";
export const STATION_GET = "STATION_GET";
export const STATION_POST = "STATION_POST";
export const STATION_PUT = "STATION_PUT";
export const STATION_DEL = "STATION_DEL";

export const SENSOR_ALL = "SENSOR_ALL";
export const SENSOR_GET = "SENSOR_GET";
export const SENSOR_POST = "SENSOR_POST";
export const SENSOR_PUT = "SENSOR_PUT";
export const SENSOR_DEL = "SENSOR_DEL";

export const LOG_ALL = "LOG_ALL";
export const LOG_GET = "LOG_GET";
export const LOG_POST = "LOG_POST";
export const LOG_PUT = "LOG_PUT";
export const LOG_DEL = "LOG_DEL";

export const STATION_TEMPLATE_ALL = "STATION_TEMPLATE_ALL";
export const STATION_TEMPLATE_GET = "STATION_TEMPLATE_GET";
export const STATION_TEMPLATE_POST = "STATION_TEMPLATE_POST";
export const STATION_TEMPLATE_PUT = "STATION_TEMPLATE_PUT";
export const STATION_TEMPLATE_DEL = "STATION_TEMPLATE_DEL";

export const ELECTRIC_CONSUMPTION_ALL = "ELECTRIC_CONSUMPTION_ALL";
export const ELECTRIC_CONSUMPTION_GET = "ELECTRIC_CONSUMPTION_GET";
export const ELECTRIC_CONSUMPTION_POST = "ELECTRIC_CONSUMPTION_POST";
export const ELECTRIC_CONSUMPTION_PUT = "ELECTRIC_CONSUMPTION_PUT";
export const ELECTRIC_CONSUMPTION_DEL = "ELECTRIC_CONSUMPTION_DEL";
