import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { Input, Select, Button, Tr, Td, Checkbox } from '@chakra-ui/react';
import {
    BooleanOperation,
    NumericOperation,
    BooleanNumericOperation,
} from '../../../config/MathOperation';
import { VariableTypeOfSource } from '../../../config/VariableTypeOfSource';

export default function SupVariableAddingComponent({
    allVariables,
    setAllVariables,
    stationData,
    rendered,
    setRendered,
}) {
    const sensorRefList = () => {
        return _.map(stationData.sensors, (sensor, index) => (
            <option value={sensor.refString} key={index}>
                {' '}
                {sensor.refString} (
                {_.upperFirst(_.lowerCase(sensor.sensorType))}){' '}
            </option>
        ));
    };

    const [typeOfSource, setTypeofSource] = useState('sensor');
    const [sensorRef, setSensorRef] = useState('');
    const [constantValue, setConstantValue] = useState(1);
    const [variableName, setVariableName] = useState('');
    const [isUseMultiStepEnable, setIsUseMultiStepEnable] = useState(false);
    const [leftSensorRef, setLeftSensorRef] = useState('');
    const [rightSensorRef, setRightSensorRef] = useState('');
    const [leftConstant, setLeftConstant] = useState('');
    const [isReverseLeft, setIsReverseLeft] = useState(false);
    const [isReverseRight, setIsReverseRight] = useState(false);
    const [insideOperation, setInsideOperation] = useState('');

    useEffect(() => {
        if (typeOfSource === 'sensor' || typeOfSource === 'constant') {
            setIsUseMultiStepEnable(false);
        } else if (
            typeOfSource === 'multiSensor' ||
            typeOfSource === 'sensorAndConstant'
        ) {
            setIsUseMultiStepEnable(true);
        }
        return () => {};
    }, [typeOfSource]);

    const gaugeOperation = () => (
        <>
            {_.map(BooleanOperation, (operator, index) => (
                <option value={operator} key={index}>
                    {operator}
                </option>
            ))}
            {_.map(BooleanNumericOperation, (operator, index) => (
                <option value={operator} key={index}>
                    {operator}
                </option>
            ))}
            {_.map(NumericOperation, (operator, index) => (
                <option value={operator} key={index}>
                    {operator}
                </option>
            ))}
            <option value="NOP">สิ้นสุดสมการ</option>
        </>
    );

    const handleAddVariable = () => {
        const tempJSONVariable = {
            subVariableName: variableName,
            sensorRef: sensorRef,
            constantValue: constantValue,
            typeOfSource: typeOfSource,
            isUseMultiStepEnable: isUseMultiStepEnable,
            leftSensorRef: leftSensorRef,
            rightSensorRef: rightSensorRef,
            leftConstant: leftConstant,
            isReverseLeft: isReverseLeft,
            isReverseRight: isReverseRight,
            operation: insideOperation,
        };
        let tempGagueVariableList = allVariables;
        tempGagueVariableList.push(tempJSONVariable);
        setAllVariables(tempGagueVariableList);
        setRendered(!rendered);
        // console.log(allVariables)
    };
    return (
        <Tr>
            <Td>
                <Input
                    type="text"
                    size="sm"
                    onChange={(event) => setVariableName(event.target.value)}
                />
            </Td>
            <Td>
                <Select
                    className="w-1/3"
                    size="sm"
                    onChange={(event) => setTypeofSource(event.target.value)}
                >
                    {_.map(VariableTypeOfSource, (variable, index) => (
                        <option value={variable} key={index}>
                            {variable}
                        </option>
                    ))}
                </Select>
            </Td>
            <Td>
                {typeOfSource === VariableTypeOfSource.sensor ? (
                    <>
                        <Select
                            className="w-1/3"
                            size="sm"
                            onChange={(event) =>
                                setSensorRef(event.target.value)
                            }
                        >
                            <option value="">เลือกเซ็นเซอร์</option>
                            {sensorRefList()}
                        </Select>
                    </>
                ) : (
                    <></>
                )}
                {typeOfSource === VariableTypeOfSource.multiSensor ? (
                    <>
                        <div className="flex gap-2">
                            <Checkbox
                                size="sm"
                                onChange={() =>
                                    setIsReverseLeft(!isReverseLeft)
                                }
                            >
                                นิเสธ
                            </Checkbox>
                            <Select
                                className=""
                                size="sm"
                                onChange={(event) =>
                                    setLeftSensorRef(event.target.value)
                                }
                            >
                                <option value="">เลือกเซ็นเซอร์</option>
                                {sensorRefList()}
                            </Select>

                            <Select
                                className=" mx-3"
                                size="sm"
                                onChange={(event) =>
                                    setInsideOperation(event.target.value)
                                }
                            >
                                <option value="">เลือกการดำเนินการ</option>
                                {gaugeOperation()}
                            </Select>

                            <Checkbox
                                size="sm"
                                className="ml-2"
                                onChange={() =>
                                    setIsReverseRight(!isReverseRight)
                                }
                            >
                                นิเสธ
                            </Checkbox>
                            <Select
                                className=""
                                size="sm"
                                onChange={(event) =>
                                    setRightSensorRef(event.target.value)
                                }
                            >
                                <option value="">เลือกเซ็นเซอร์</option>
                                {sensorRefList()}
                            </Select>
                        </div>
                    </>
                ) : (
                    <></>
                )}
                {typeOfSource === VariableTypeOfSource.constant ? (
                    <>
                        <Input
                            type="number"
                            className="w-1/3"
                            size="sm"
                            required={false}
                            onChange={(event) =>
                                setConstantValue(event.target.value)
                            }
                        />
                    </>
                ) : (
                    <></>
                )}
                {typeOfSource === VariableTypeOfSource.sensorAndConstant ? (
                    <>
                        <div className="flex gap-1">
                            <Input
                                type="number"
                                className="w-1/3"
                                size="sm"
                                required={false}
                                onChange={(event) =>
                                    setLeftConstant(event.target.value)
                                }
                            />

                            <Select
                                className="w-1/3"
                                size="sm"
                                onChange={(event) =>
                                    setInsideOperation(event.target.value)
                                }
                            >
                                <option value="">เลือกการดำเนินการ</option>
                                {gaugeOperation()}
                            </Select>

                            <Checkbox
                                size="sm"
                                onChange={() =>
                                    setIsReverseRight(!isReverseRight)
                                }
                            >
                                นิเสธ
                            </Checkbox>
                            <Select
                                className="w-1/3"
                                size="sm"
                                onChange={(event) =>
                                    setRightSensorRef(event.target.value)
                                }
                            >
                                <option value="">เลือกเซ็นเซอร์</option>
                                {sensorRefList()}
                            </Select>
                        </div>
                    </>
                ) : (
                    <></>
                )}
            </Td>
            <Td>
                <Button
                    colorScheme="blue"
                    size="sm"
                    className="self-center"
                    type="button"
                    onClick={() => handleAddVariable()}
                >
                    เพิ่ม
                </Button>
            </Td>
        </Tr>
    );
}
