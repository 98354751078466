import React from 'react'
import _ from 'lodash'
import GeneralGaugeSelector from '../../../components/Gagues/Functions/GeneralGaugeSelector'
import SensorDataRendering from '../../../components/Sensors/Functions/SensorDataRendering'
import VariableDataRendering from '../../../components/Variables/Function/VariableMainRendering'
import Config from '../../../config'

export default function DashboardMonitorComponent({ currentStation }) {
    let orderedGauge = _.orderBy(currentStation.gauges, 'orderLevel', 'asc')
    return (
        <>
            <div className="grid grid-cols-1 gap-x-4 gap-y-1 lg:grid-cols-4 md:gid-cols-3 mr-6 mb-4">
                {_.map(orderedGauge, (gauge, index) => {
                    if (
                        gauge.gaugeSource ===
                        Config.TypeOfSource.GaugeSource.sensor
                    ) {
                        return (
                            <GeneralGaugeSelector
                                gaugeData={gauge}
                                key={index}
                                stationData={currentStation}
                                staticValue={SensorDataRendering(
                                    currentStation,
                                    gauge
                                )}
                            />
                        )
                    } else {
                        return (
                            <GeneralGaugeSelector
                                gaugeData={gauge}
                                key={index}
                                stationData={currentStation}
                                staticValue={VariableDataRendering(
                                    currentStation,
                                    gauge.variableId
                                )}
                            />
                        )
                    }
                })}
            </div>
        </>
    )
}
