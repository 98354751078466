import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../redux/actions";
import { Link } from "react-router-dom";

// components
import { Button, Link as LinkUI, Box } from "@chakra-ui/react";

import SpinnerLoading from "components/Loading/SpinnerLoading";
import _ from "lodash";
import ControlLists from "./components/ControlLists";

export default function Dashboard() {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const stations = useSelector((state) => state.station);

  useEffect(() => {
    dispatch(actions.stationAll()).then(() => setIsLoading(true));
    return () => {};
  }, []);

  return isLoading ? (
    <div className="relative flex flex-col min-w-0 break-words bg-gray-100 w-full mb-6 shadow-lg rounded">
      <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
        <div className="flex flex-wrap items-center">
          <div className="relative w-full max-w-full flex-grow flex-1">
            <h6 className="uppercase text-gray-500 mb-1 text-xs font-semibold font-sans">
              การควบคุม
            </h6>
            <h2 className="text-gray-800 text-2xl font-bold font-sans ">
              การควบคุม
            </h2>
          </div>
        </div>
      </div>
      {/* <div className="flex justify-between p-4">
        <Link to="/dashboards">
          <Button colorScheme="blue" variant="solid" size="sm">
            ดูแดชบอร์ด
          </Button>
        </Link>
      </div> */}
      <div className="p-4 w-full">
        {_.size(stations.arr) ? (
          <div className="overflow-x-auto">
            {_.map(stations.arr, (station) => (
              <>
                <Box
                  borderRadius="lg"
                  borderWidth="1px"
                  padding="4"
                  className="mb-4"
                >
                  <Link to={"/controls/" + station._id}>
                    <h2 className="text-xl font-bold font-sans">
                      {" "}
                      <LinkUI>
                        {" "}
                        <i className="fas fa-search text-sm "> </i>{" "}
                        {station.name}{" "}
                      </LinkUI>{" "}
                    </h2>{" "}
                  </Link>
                  <h5 className="text-base text-gray-700 font-sans">
                    {" "}
                    {station.description} | {station.location}{" "}
                  </h5>
                </Box>
              </>
            ))}
          </div>
        ) : (
          <div>ไม่มีข้อมูล</div>
        )}
      </div>
    </div>
  ) : (
    <SpinnerLoading />
  );
}
