import React, { useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as actions from '../../redux/actions/'
import { firebaseAuth } from 'contexts/AuthContext'
import { useForm } from 'react-hook-form'
import * as UserRoles from 'config/UserRoles'
import _ from 'lodash'
import { Button } from '@chakra-ui/react'
import SpinnerComponent from '../../components/Loading/SpinnerLoading'

export default function CreateUser() {
  const { register, handleSubmit, reset, errors } = useForm()
  const { handleSignupByHRMS } = useContext(firebaseAuth)
  const stations = useSelector((state) => state.station)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(actions.stationAll())
    return () => {}
  }, [])

  const onSubmit = async (data, e) => {
    if (data.password === data.confirm_password) {
      e.preventDefault()
      console.log(data)
      await handleSignupByHRMS(data.email, data.password, data)
      alert('ลงทะเบียนสำเร็จ')
      reset()
    } else {
      alert('รหัสผ่านไม่ตรงกัน')
    }
  }
  const genPositionOption = () => (
    <>
      <option value={UserRoles.ROLES.USER}>{UserRoles.ROLES.USER}</option>
      <option value={UserRoles.ROLES.GENERAL}>{UserRoles.ROLES.GENERAL}</option>
      <option value={UserRoles.ROLES.AMDIN}>{UserRoles.ROLES.ADMIN}</option>
      <option value={UserRoles.ROLES.SUPER_USER}>
        {UserRoles.ROLES.SUPER_USER}
      </option>
    </>
  )

  const genDutyOption = () => {
    return _.map(stations.arr, (station, index) => (
      <option value={station._id} key={index}>
        {' '}
        {station.name}{' '}
      </option>
    ))
  }

  if (stations.arr)
    return (
      <>
        <div className="container mx-auto px-4 h-full">
          <div className="flex content-center items-center justify-center h-full">
            <div className="w-full lg:w-6/12 px-4">
              <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-100 border-0">
                <div className="rounded-t mb-0 px-6 py-6">
                  <div className="text-center mb-3">
                    <h6 className="text-gray-600 text-base font-bold font-sans">
                      เพิ่มผู้ใช้
                    </h6>
                  </div>
                  <hr className="mt-6 border-b-1 border-gray-400" />
                </div>
                <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <h6 className="text-gray-500 text-base mt-3 mb-6 font-bold uppercase font-sans">
                      ข้อมูลผู้ใช้
                    </h6>
                    <div className="flex flex-wrap">
                      <div className="w-full px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            Email
                          </label>
                          <input
                            name="email"
                            type="email"
                            required
                            ref={register}
                            className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                            placeholder="example@email.com"
                          />
                        </div>
                      </div>
                      <div className="w-full  px-4">
                        <div className="relative w-full  mb-3">
                          <label
                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            รหัสผ่าน
                          </label>
                          <input
                            name="password"
                            type="password"
                            required
                            className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                            placeholder="Password"
                            ref={register}
                          />
                        </div>
                      </div>
                      <div className="w-full  px-4">
                        <div className="relative w-full  mb-3">
                          <label
                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            ยืนยันรหัสผ่าน
                          </label>
                          <input
                            name="confirm_password"
                            type="password"
                            required
                            className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                            placeholder="Confirm Password"
                            ref={register}
                          />
                        </div>
                      </div>
                      <div className="w-full  px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            ชื่อ-สกุล
                          </label>
                          <input
                            name="name"
                            type="text"
                            required
                            ref={register}
                            className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                            placeholder="ชื่อสกุล"
                          />
                        </div>
                      </div>

                      <div className="w-full  px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            เบอร์โทรศัพท์
                          </label>
                          <input
                            name="tel"
                            type="text"
                            required
                            ref={register}
                            className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                            placeholder="เบอร์โทรศัพท์"
                          />
                        </div>
                      </div>
                      <div className="w-full  px-4">
                        <div className="relative w-full  mb-3">
                          <label
                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            ที่อยู่
                          </label>
                          <textarea
                            name="address"
                            required
                            ref={register}
                            rows="3"
                            className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                            placeholder="ที่อยู่"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="w-full  px-4">
                      <div className="relative w-full  mb-3">
                        <label
                          className="block uppercase text-gray-700 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          บทบาท
                        </label>
                        <select
                          name="role"
                          required
                          ref={register}
                          className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                        >
                          {genPositionOption()}
                        </select>
                      </div>
                    </div>

                    <div className="w-full  px-4">
                      <div className="relative w-full  mb-3">
                        <label
                          className="block uppercase text-gray-700 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          บทบาท
                        </label>
                        <select
                          name="duty"
                          required
                          ref={register}
                          className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                        >
                          <option value="ALLAREA"> อนุญาติทุกสถานี </option>
                          {genDutyOption()}
                        </select>
                      </div>
                    </div>

                    <div className="text-center px-4 gap-0 py-3 ">
                      <Button
                        isFullWidth
                        colorScheme="blue"
                        variant="solid"
                        type="submit"
                      >
                        Create Account
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  else {
    return <SpinnerComponent />
  }
}
