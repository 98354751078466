import React, { useState, useEffect } from 'react'
import Xarrow from 'react-xarrows'
import _ from 'lodash'

import Config from '../../../config'
import VariableRendering from '../../Variables/Function/VariableMainRendering'

export default function DiagramLineRenderingComponent({
    yourRef,
    nextRef,
    diagramData,
    stationData,
}) {
    const [lineLogicControl, setLineLogicControl] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const sensorOutedLineData = diagramData?.outedLine
    const logicControlSource = sensorOutedLineData.enableSource

    // Find Data
    useEffect(() => {
        if (logicControlSource === Config.TypeOfSource.GaugeSource.sensor) {
            // Finding this sensor in station
            const selectedSensor = _.find(
                stationData.sensors,
                (eachSensor) =>
                    eachSensor.refString === sensorOutedLineData.enableSensorRef
            )
            const selectedSensorData = selectedSensor?.booleanStatus
            setLineLogicControl(selectedSensorData)
            setIsLoading(true)
        } else {
            const variableResult = VariableRendering(
                stationData,
                sensorOutedLineData.enableVariableId
            )
            setLineLogicControl(variableResult)
            setIsLoading(true)
        }
        return () => {}
    }, [])

    if (isLoading) {
        if (sensorOutedLineData.enable === true)
            return (
                <Xarrow
                    start={yourRef}
                    end={nextRef}
                    path="smooth"
                    startAnchor="right"
                    endAnchor="middle"
                    headSize="0"
                    lineColor={
                        lineLogicControl === true
                            ? 'DarkTurquoise'
                            : 'LightGray'
                    }
                    dashness={
                        lineLogicControl === true
                            ? { strokeLen: 4, nonStrokeLen: 8, animation: 3 }
                            : false
                    }
                    strokeWidth="7"
                    className="  lg:overflow-x-visible z-10 "
                    divContainerProps={{
                        style: { position: 'relative' },
                    }}
                />
            )
        else {
            return <></>
        }
    } else {
        return <></>
    }
}
