import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { Button } from '@chakra-ui/react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useHistory, Link } from 'react-router-dom'
import hash from 'object-hash'

import SpinnerLoading from 'components/Loading/SpinnerLoading'
import * as actions from '../../../../redux/actions'
import VariableMakingComponent from '../../../../components/Variables/Making/VariableMakingComponents'
import VariableTemplate from '../../../../template/VariableTemplate.json'

export default function ManagementVariable() {
    const [isLoading, setIsLoading] = useState(false)
    const params = useParams()
    const allStations = useSelector((state) => state.station)
    const dispatch = useDispatch()
    const history = useHistory()
    const [currentStation, setCurrentStation] = useState()

    useEffect(() => {
        dispatch(actions.stationAll()).then(() => {})
        return () => {}
    }, [params])

    useEffect(() => {
        if (allStations) {
            const tempcurrentStatus = _.find(
                allStations.arr,
                (station) => station._id === params.id
            )
            setCurrentStation(tempcurrentStatus)
            console.log('Curent Station', currentStation)
        }
        setIsLoading(true)
        return () => {}
    }, [params, allStations])

    const genVariableList = () => {
        return _.map(currentStation.variables, (variable, index) => (
            <>
                <VariableMakingComponent
                    key={index}
                    index={index}
                    variableData={variable}
                    stationData={currentStation}
                />
                {/* {VariableMainRendering(currentStation, variable._id)} */}
            </>
        ))
    }

    const handleCreateVariables = () => {
        setIsLoading(false)
        const tempStation = currentStation
        const tempVariableTemplate = VariableTemplate
        tempVariableTemplate.id = hash({
            date: new Date(),
            randomNum: Math.random(),
        })
        if (_.isArray(tempStation.variables)) {
            console.log('Old Variables List ', tempStation.variables)
            tempStation.variables.push(tempVariableTemplate)
            console.log('New Variables List ', tempStation.variables)
        }
        console.log('New Station Data will be', tempStation)
        dispatch(actions.stationPut(currentStation._id, tempStation)).then(
            () => {
                dispatch(actions.stationAll()).then(() => {})
            }
        )
    }

    if (currentStation && isLoading) {
        return (
            <>
                <div className="relative flex flex-col min-w-0 break-words bg-gray-100 w-full mb-6 shadow-lg rounded">
                    <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
                        <div className="flex flex-wrap items-center">
                            <div className="relative w-full max-w-full flex-grow flex-1">
                                <h6 className="uppercase text-gray-500 mb-1 text-xs font-semibold font-sans">
                                    จัดการระบบ
                                </h6>
                                <h2 className="text-gray-800 text-2xl font-bold font-sans">
                                    จัดการตัวแปรในสถานี {currentStation.name}
                                </h2>
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-start gap-1 p-4">
                        <Button
                            colorScheme="blue"
                            variant="solid"
                            size="sm"
                            onClick={() => handleCreateVariables()}
                        >
                            เพิ่ม
                        </Button>

                        {/* <Link to={'/system/dashboard/arrange/' + params.id}> */}
                        {/* <Button colorScheme="blue" variant="solid" size="sm">
              ปรับการจัดเรียง
            </Button> */}
                        {/* </Link> */}
                    </div>
                    <div className="p-4 w-full">{genVariableList()}</div>
                </div>
            </>
        )
    } else {
        return <SpinnerLoading />
    }
}
