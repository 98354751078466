import React from 'react'
import _ from 'lodash'
import { Checkbox, FormControl, Input, Select } from '@chakra-ui/react'
import Config from '../../../../../config'

export default function EditStaticBooleanWaterDisplay({
    gaugeData,
    register,
    stationData,
    gaugeSource,
}) {
    const genRefStringList = () => {
        return _.map(stationData.sensors, (sensor) => (
            <option value={sensor.refString}>{sensor.refString}</option>
        ))
    }

    const genVariableList = () => {
        return _.map(stationData.variables, (variable) => (
            <option value={variable.id}>{variable.variableName}</option>
        ))
    }

    return (
        <>
            {gaugeSource === Config.TypeOfSource.GaugeSource.sensor ? (
                <FormControl>
                    <label className="text-sm font-bold">
                        {' '}
                        เซ็นเซอร์ที่ต้องการเชื่อมต่อ{' '}
                    </label>
                    <Select
                        defaultValue={gaugeData.sensorRefString}
                        ref={register}
                        size="sm"
                        name="sensorRefString"
                    >
                        {genRefStringList()}
                    </Select>
                </FormControl>
            ) : (
                <FormControl>
                    <label className="text-sm font-bold">
                        ตัวแปรที่ต้องการจะเชื่อมต่อ
                    </label>
                    <Select
                        defaultValue={gaugeData.variableId}
                        ref={register}
                        size="sm"
                        name="variableId"
                    >
                        {genVariableList()}
                    </Select>
                </FormControl>
            )}

            <FormControl>
                <label className="text-sm font-bold">
                    {' '}
                    คำที่จะแสดงเมื่อเป็นค่า High{' '}
                </label>
                <Input
                    type="text"
                    name="valueLabel.high"
                    size="sm"
                    defaultValue={gaugeData.valueLabel?.high}
                    ref={register}
                />
            </FormControl>
            <FormControl>
                <label className="text-sm font-bold">
                    {' '}
                    ค่าที่จะแสดงเมื่อเป็นค่า Low{' '}
                </label>
                <Input
                    type="text"
                    name="valueLabel.low"
                    size="sm"
                    defaultValue={gaugeData.valueLabel?.low}
                    ref={register}
                />
            </FormControl>
            <FormControl>
                <Checkbox
                    type="text"
                    name="isActiveHigh"
                    size="sm"
                    defaultChecked={gaugeData.isActiveHigh}
                    ref={register}
                >
                    <label className="text-sm font-bold">
                        {' '}
                        ค่า Active High{' '}
                    </label>
                </Checkbox>
            </FormControl>
        </>
    )
}
