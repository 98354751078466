import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import _ from "lodash";
import { Button } from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux";
import SensorComponent from "../../../../components/Sensors/SensorMakingComponent";
import SpinnerLoading from "components/Loading/SpinnerLoading";

import { useParams, useHistory, Link } from "react-router-dom";
import * as actions from "../../../../redux/actions";

export default function EditSensor() {
  const { register, handleSubmit, reset } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const params = useParams();
  const allStations = useSelector((state) => state.station);
  const [station, setStation] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.stationAll()).then(() => setIsLoading(true));
    return () => { };
  }, [params]);

  useEffect(() => {
    if (allStations) {
      const tempcurrentStatus = _.find(
        allStations.arr,
        (station) => station._id === params.id
      );
      setStation(tempcurrentStatus);
    }
    return () => { };
  }, [allStations]);

  const genSensorList = () => {
    return _.map(station.sensors, (sensor, index) => {
 
      return (    <div key={index}>
        <SensorComponent
          sensorData={sensor}
          stationData={station}
          placeToEdit="station"
        />
      </div>)
    }
    )

  };
  if (station && isLoading) {
    return (
      <>
        <div className="relative flex flex-col min-w-0 break-words bg-gray-100 w-full mb-6 shadow-lg rounded">
          <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
            <div className="flex flex-wrap items-center">
              <div className="relative w-full max-w-full flex-grow flex-1">
                <h6 className="uppercase text-gray-500 mb-1 text-xs font-semibold font-sans">
                  จัดการระบบ
                </h6>
                <h2 className="text-gray-800 text-2xl font-bold font-sans">
                  เซ็นเซอร์ใน{station.name}
                </h2>
              </div>
            </div>
          </div>
          <div className="flex justify-start gap-1 p-4">
            <Link to={"/system/stations/sensors/add/" + params.id}>
              <Button colorScheme="blue" variant="solid" size="sm">
                เพิ่ม
              </Button>
            </Link>
          </div>
          <div className="p-4 w-full">{genSensorList()}</div>
        </div>
      </>
    );
  } else {
    return <SpinnerLoading />;
  }
}
