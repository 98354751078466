import React, { useState } from 'react'
import {
    Badge,
    Box,
    Button,
    Code,
    Divider,
    FormControl,
    FormLabel,
    Input,
    Select,
    Switch,
} from '@chakra-ui/react'
import { useDispatch } from 'react-redux'
import * as actions from '../../redux/actions'
import { useForm } from 'react-hook-form'
import _ from 'lodash'

export default function SensorComponent({
    stationData,
    sensorData,
    placeToEdit = 'station',
}) {
    const [isEditorMode, setIsEditorMode] = useState(false)
    const { register, handleSubmit } = useForm()
    const dispatch = useDispatch()

    const prepareUpdateDatabase = (data) => {
        // Find Sensor that user have edit
        const selectedSensor = _.find(
            stationData.sensors,
            (sensor) => sensor._id === sensorData._id
        )
        const selectedSensorIndex = _.findIndex(
            stationData.sensors,
            (sensor) => sensor._id === sensorData._id
        )

        // Merged the Sensor Data and Make new Station Data to Database
        const mergedSensorData = _.merge(selectedSensor, data)
        let editedStation = stationData
        editedStation.sensors[selectedSensorIndex] = mergedSensorData

        // is on Template or on Station FIXME: Magic Code
        if (placeToEdit === 'template') {
            console.log('This is Template not station')
            dispatch(
                actions.stationTemplatePut(stationData._id, editedStation)
            ).then(() => {
                dispatch(actions.stationAll())
            })
        } else {
            console.log('This is Station')
            console.log('Station Data ', stationData)

            dispatch(actions.stationPut(stationData._id, editedStation)).then(
                () => {
                    dispatch(actions.stationGet(stationData._id)).then(() => {
                        setIsEditorMode(false)
                    })
                }
            )
        }
    }

    return (
        <Box borderRadius="lg" padding="4" className="bg-white my-2">
            <form onSubmit={handleSubmit(prepareUpdateDatabase)}>
                <div className="flex gap-3">
                    <div className="w-5/6">
                        <h5 className="text-lg font-bold font-sans">
                            {' '}
                            {sensorData.name}{' '}
                            <Badge colorScheme="teal" size="sm">
                                {sensorData.sensorType}{' '}
                            </Badge>{' '}
                        </h5>
                    </div>
                    {/************ Control Button **********/}
                    <div className="flex gap-1 ">
                        {isEditorMode === true ? (
                            <>
                                <Button
                                    size="sm"
                                    type="button"
                                    colorScheme="gray"
                                    onClick={() => setIsEditorMode(false)}
                                >
                                    ยกเลิก
                                </Button>{' '}
                                <Button
                                    size="sm"
                                    colorScheme="green"
                                    type="submit"
                                >
                                    บันทึก
                                </Button>
                            </>
                        ) : (
                            <>
                                {sensorData.sensorType === 'BOOLEAN' && (
                                    <Button
                                        size="sm"
                                        colorScheme="yellow"
                                        onClick={() => setIsEditorMode(true)}
                                        type="button"
                                    >
                                        แก้ไข
                                    </Button>
                                )}
                                {sensorData.sensorType === 'NUMBER' && (
                                    <Button
                                        size="sm"
                                        colorScheme="yellow"
                                        onClick={() => setIsEditorMode(true)}
                                        type="button"
                                    >
                                        แก้ไข
                                    </Button>
                                )}
                            </>
                        )}
                    </div>
                </div>
                {/************ End of Control Button **********/}
                <div className="flex gap-3">
                    <div>
                        {/************ Editor Mode **********/}
                        {isEditorMode === true ? (
                            <>
                                {/* BOOLEAN TYPE */}
                                {sensorData.sensorType === 'BOOLEAN' && (
                                    <div className="mx-4">
                                        <FormControl display="flex">
                                            <FormLabel>
                                                <div className="block uppercase text-gray-700 text-sm font-bold my-3">
                                                    เปิด/ปิด ใช้ค่าเทรชโฮลด์
                                                </div>
                                            </FormLabel>
                                            <Switch
                                                className="my-2"
                                                size="lg"
                                                name="enableThreshold"
                                                ref={register}
                                                defaultChecked={
                                                    sensorData.enableThreshold
                                                }
                                            />
                                        </FormControl>
                                        <FormControl>
                                            <Select
                                                name="triggerThresholdValue"
                                                ref={register}
                                                defaultValue={
                                                    sensorData.triggerThresholdValue
                                                }
                                            >
                                                <option value={true}>
                                                    เปิด
                                                </option>
                                                <option value={false}>
                                                    ปิด
                                                </option>
                                            </Select>
                                        </FormControl>
                                    </div>
                                )}
                                {/* NUMBER TYPE */}
                                {sensorData.sensorType === 'NUMBER' && (
                                    <div className="mx-4">
                                        <FormControl display="flex">
                                            <FormLabel>
                                                <div className="block uppercase text-gray-700 text-sm font-bold my-3">
                                                    เปิด/ปิด ใช้ค่าเทรชโฮลด์
                                                </div>
                                            </FormLabel>
                                            <Switch
                                                className="my-2"
                                                size="lg"
                                                name="enableThreshold"
                                                ref={register}
                                                defaultChecked={
                                                    sensorData.enableThreshold
                                                }
                                            />
                                        </FormControl>
                                        <FormControl>
                                            <label className="text-sm font-bold">
                                                ค่าเทรชโฮลด์ด้านบน
                                            </label>
                                            <div className="flex flex-wrap">
                                                <div className="w-1/2">
                                                    <Input
                                                        type="text"
                                                        name="upperThresholdValue"
                                                        size="sm"
                                                        ref={register}
                                                        defaultValue={
                                                            sensorData?.upperThresholdValue
                                                        }
                                                    />
                                                </div>
                                                <div className="w-1/2">
                                                    <label className="m-2 text-sm font-bold">
                                                        {sensorData?.valueUnit}
                                                    </label>
                                                </div>
                                            </div>
                                        </FormControl>
                                        <FormControl>
                                            <label className="text-sm font-bold">
                                                ค่าเทรชโฮลด์ด้านล่าง
                                            </label>
                                            <div className="flex">
                                                <div className="w-1/2">
                                                    <Input
                                                        type="text"
                                                        name="lowerThresholdValue"
                                                        size="sm"
                                                        ref={register}
                                                        defaultValue={
                                                            sensorData.lowerThresholdValue
                                                        }
                                                    />
                                                </div>
                                                <div className="w-1/2">
                                                    <label className="p-2 text-sm font-bold">
                                                        {sensorData.valueUnit}
                                                    </label>
                                                </div>
                                            </div>
                                        </FormControl>
                                    </div>
                                )}
                            </>
                        ) : (
                            <>
                                {/************ Display Mode **********/}
                                <ul className="mx-4">
                                    {sensorData.sensorType === 'BOOLEAN' && (
                                        <>
                                            <li>
                                                <span className="text-sm font-semibold text-gray-700">
                                                    เปิด/ปิด ใช้ค่าเทรชโฮลด์ :
                                                </span>
                                                {sensorData.enableThreshold ? (
                                                    <span className="text-md font-semibold text-green-500">
                                                        {' '}
                                                        เปิด{' '}
                                                    </span>
                                                ) : (
                                                    <span className="text-md font-semibold text-red-500">
                                                        {' '}
                                                        ปิด{' '}
                                                    </span>
                                                )}
                                            </li>
                                        </>
                                    )}

                                    {sensorData.sensorType === 'NUMBER' && (
                                        <>
                                            <li>
                                                <span className="text-sm font-semibold text-gray-700">
                                                    เปิด/ปิด ใช้ค่าเทรชโฮลด์ :
                                                </span>
                                                {sensorData.enableThreshold ? (
                                                    <span className="text-md font-semibold text-green-500">
                                                        {' '}
                                                        เปิด{' '}
                                                    </span>
                                                ) : (
                                                    <span className="text-md font-semibold text-red-500">
                                                        {' '}
                                                        ปิด{' '}
                                                    </span>
                                                )}
                                            </li>
                                            {sensorData.enableThreshold && (
                                                <>
                                                    <li>
                                                        <span className="text-sm font-semibold text-gray-700">
                                                            ค่าเทรชโฮลด์ด้านบน :
                                                        </span>
                                                        <span className="text-md font-semibold text-red-600">
                                                        {sensorData?.upperThresholdValue ? sensorData?.upperThresholdValue.toFixed(
                                                                2
                                                            ) : ""}
                                                            <span className="text-md font-semibold text-gray-700">
                                                         
                                                                {
                                                                    sensorData?.valueUnit
                                                                }
                                                            </span>
                                                        </span>
                                                    </li>

                                                    <li>
                                                        <span className="text-sm font-semibold text-gray-700">
                                                            ค่าเทรชโฮลด์ด้านล่าง
                                                            :
                                                        </span>
                                                        <span className="text-md font-semibold text-red-600">
                                                            {sensorData?.lowerThresholdValue ? sensorData?.lowerThresholdValue.toFixed(
                                                                2
                                                            ) : ""}

                                                            <span className="text-md font-semibold text-gray-700">
                                                                {sensorData?.valueUnit}
                                                            </span>
                                                        </span>
                                                    </li>
                                                </>
                                            )}
                                        </>
                                    )}
                                </ul>
                            </>
                        )}
                    </div>
                </div>
            </form>
        </Box>
    )
}
