import _ from 'lodash'

import Config from '../../../../config'
// Find Data from Each Variable Type
import { MultiSensor, OneSensor, SensorAndVariable } from './FromVariableType'
// Main Rendering the value of Variable in Realtime

export default function subVariableDataFinding(
    stationData,
    subvariableData,
    dataArr
) {
    // console.log(
    //     'Sub Variable Data Fetching with Data [StationData]',
    //     stationData,
    //     '[SubVariableData]',
    //     subvariableData,
    //     '[Data Arr]',
    //     dataArr
    // )
    if (subvariableData) {
        if (
            subvariableData.typeOfSource ===
            Config.TypeOfSource.VariableTypeOfSource.constant
        ) {
            return subvariableData.constantValue
        } else if (
            subvariableData.typeOfSource ===
            Config.TypeOfSource.VariableTypeOfSource.sensor
        ) {
            console.log('ตรวจพบการหาค่าจาก 1 Sensor')
            return OneSensor(stationData, subvariableData, dataArr)
        } else if (
            subvariableData.typeOfSource ===
            Config.TypeOfSource.VariableTypeOfSource.multiSensor
        ) {
            console.log('ตรวจพบการหาค่าจากหลาย Sensor')
            return MultiSensor(stationData, subvariableData, dataArr)
        } else if (
            subvariableData.typeOfSource ===
            Config.TypeOfSource.VariableTypeOfSource.sensorAndConstant
        ) {
            console.log('ตรวจพบการหาค่าจาก 1 Sensor 1 ค่าคงที่')
            return SensorAndVariable(stationData, subvariableData, dataArr)
        } else {
            console.log(
                'Type of Source of sub varaible is invalid ',
                stationData.typeOfSource
            )
            return []
        }
    } else {
        return []
    }
}
