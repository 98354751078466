import React from 'react'

import Config from '../../../config'
import {
    DisplayBooleanAttribute,
    DisplayStringAttribute,
    DisplayNumberAttribute,
    DisplayTwoStepBooleanAttribute,
} from '../Display/Attribute'

export default function DiagramAttributeDisplay({
    stationData,
    attributeData,
}) {
    if (attributeData.preferDataType === Config.DataType.DataType.boolean) {
        return (
            <DisplayBooleanAttribute
                attributeData={attributeData}
                stationData={stationData}
            />
        )
    } else if (
        attributeData.preferDataType === Config.DataType.DataType.number
    ) {
        return (
            <DisplayNumberAttribute
                attributeData={attributeData}
                stationData={stationData}
            />
        )
    } else if (
        attributeData.preferDataType === Config.DataType.DataType.twoStepBoolean
    ) {
        return (
            <DisplayTwoStepBooleanAttribute
                attributeData={attributeData}
                stationData={stationData}
            />
        )
    } else {
        return (
            <DisplayStringAttribute
                attributeData={attributeData}
                stationData={stationData}
            />
        )
    }
}
