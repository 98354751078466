import { DataType } from '../../../config/DataType'
import _ from 'lodash'

/**
 *
 * @function SensorDataRendering
 * @description Render Data from sensor by use gauge as selector
 * @param {Station} stationData Station that can be find sensor
 * @param {Gauge} gaugeData Configuration of Gauge
 * @returns Component
 */
export default function SensorDataRendering(stationData, gaugeData) {
    const refString = gaugeData.sensorRefString
    const selectedSensor = _.find(
        stationData.sensors,
        (sensor) => sensor.refString === refString
    )
    console.log(' Sensor is found ', gaugeData.gaugeLabel)
    if (selectedSensor) {
        console.log('Selected Sensor Data Rendering')
        if (gaugeData.sensorType === DataType.boolean) {
            console.log('Is Boolean', selectedSensor.booleanStatus)
            return selectedSensor.booleanStatus
        } else if (gaugeData.sensorType === DataType.number) {
            console.log('Is Number', selectedSensor.numericStatus)
            return selectedSensor.numericStatus
        } else if (gaugeData.sensorType === DataType.string) {
            console.log('Is String', selectedSensor.stringStatus)
            return selectedSensor.stringStatus
        } else {
            console.log('Nothing Found', selectedSensor)
            return undefined
        }
    } else {
        return 0
    }
}
