import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import currencyFormatter from "currency-formatter";
import _ from "lodash";
import moment from "moment";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfMake.fonts = {
  THSarabunNew: {
    normal: "THSarabunNew.ttf",
    bold: "THSarabunNew Bold.ttf",
    italics: "THSarabunNew-Italic.ttf",
    bolditalics: "THSarabunNew-BoldItalic.ttf",
  },
  Roboto: {
    normal: "Roboto-Regular.ttf",
    bold: "Roboto-Medium.ttf",
    italics: "Roboto-Italic.ttf",
    bolditalics: "Roboto-MediumItalic.ttf",
  },
};
const historyList = (consumption) => {
  return _.map(consumption.history, (history, index) => [
    {
      text: `${moment(history.date).format("L")}`,
      border: [true, false, true, true],
      colSpan: 1,
      fontSize: "12",
    },
    {
      text: `${currencyFormatter.format(history.all, { code: "" })} หน่วย`,
      border: [true, false, true, true],
      colSpan: 1,
      fontSize: "12",
    },
    {
      text: `${currencyFormatter.format(history.onPeak, { code: "" })} หน่วย`,
      border: [true, false, true, true],
      colSpan: 1,
      fontSize: "12",
    },
    {
      text: `${currencyFormatter.format(history.offPeak, { code: "" })} หน่วย`,
      border: [true, false, true, true],
      colSpan: 1,
      fontSize: "12",
    },
    {
      text: `${currencyFormatter.format(history.holiday, { code: "" })} หน่วย`,
      border: [true, false, true, true],
      colSpan: 1,
      fontSize: "12",
    },
    {
      text: `${currencyFormatter.format(history.power, { code: "" })} บาท`,
      border: [true, false, true, true],
      colSpan: 1,
      fontSize: "12",
    },
  ]);
};

export const printPDF = (consumption, systemInfo) => {
  const history = historyList(consumption);
  var docDefinition = {
    header: {
      columns: [
        {
          text: `${moment().format("ll")}`,
          alignment: "right",
          margin: [10, 10, 10, 10],
          fontSize: "12",
        },
      ],
    },
    footer: function (currentPage, pageCount) {
      // you can apply any logic and return any valid pdfmake element

      return [
        {
          text: currentPage.toString() + " of " + pageCount,
          alignment: "right",
          margin: [10, 10, 10, 10],
          fontSize: "12",
        },
      ];
    },

    pageSize: "A4",
    pageOrientation: "portrait",
    pageMargins: [40, 40, 40, 40],
    defaultStyle: {
      font: "THSarabunNew",
      fontSize: "16",
    },
    info: {
      title: `ประมาณการบิลค่าไฟฟ้าประจำเดือน ${moment(consumption.date).format(
        "MMMM"
      )}/${parseInt(moment(consumption.date).format("YYYY"))}`,
    },
    content: [
      {
        alignment: "justify",
        columns: [
          {
            image: "company_logo",
            width: 60,
            margin: [0, 10, 0, 10],
          },
          [
            {
              text: systemInfo.systemOwner,
              fontSize: "18",
              bold: true,
            },

            {
              text: `${systemInfo.name}`,
              fontSize: "14",
            },
            {
              text: `${systemInfo.description}`,
              fontSize: "12",
            },
          ],
        ],
      },

      {
        //หัวข้อใบเสนอราคาคา
        alignment: "center",
        margin: [0, 20, 0, 20],
        text: `ประมาณการบิลค่าไฟฟ้าประจำเดือน ${moment(consumption.date).format(
          "MMMM"
        )}/${parseInt(moment(consumption.date).format("YYYY"))}`,
        fontSize: "23",
        bold: true,
      },
      {
        text: `ประจำเดือน : ${moment(consumption.date).format(
          "MMMM"
        )}/${parseInt(moment(consumption.date).format("YYYY"))}`,
        fontSize: "16",
        bold: true,
      },
      {
        margin: [0, 0, 0, 10],
        layout: {
          fillColor: function (rowIndex, node, columnIndex) {
            return rowIndex % 2 === 0 ? "#ebebeb" : "#f5f5f5";
          },
        },
        table: {
          widths: ["100%"],
          heights: [20, 10],
          body: [
            [
              {
                text: `ค่าพลังงานไฟฟ้าทั้งหมด : ${currencyFormatter.format(
                  consumption.power,
                  { code: "" }
                )} บาท`,
                fontSize: "14",
              },
            ],
            [
              {
                text: `ค่าความต้องการไฟฟ้า PEAK : ${currencyFormatter.format(
                  consumption.needPower,
                  { code: "" }
                )} บาท`,
                fontSize: "14",
              },
            ],
            [
              {
                text: `ค่าบริการ : ${currencyFormatter.format(
                  consumption.service,
                  { code: "" }
                )} บาท`,
                fontSize: "14",
              },
            ],
            [
              {
                text: `รวมค่าไฟฟ้าฐาน : ${currencyFormatter.format(
                  consumption.base,
                  { code: "" }
                )} บาท`,
                fontSize: "16",
                bold: true,
              },
            ],
            [
              {
                text: `ค่า ft : ${currencyFormatter.format(consumption.ft, {
                  code: "",
                })} บาท`,
                fontSize: "14",
              },
            ],
            [
              {
                text: `ค่าเพาเวอร์แฟคเตอร์ : ${currencyFormatter.format(
                  consumption.powerFactor,
                  { code: "" }
                )} บาท`,
                fontSize: "14",
              },
            ],
            [
              {
                text: `รวมค่าไฟฟ้าก่อนรวมภาษี : ${currencyFormatter.format(
                  consumption.summary - consumption.vat,
                  { code: "" }
                )} บาท`,
                fontSize: "16",
                bold: true,
              },
            ],
            [
              {
                text: `ค่าภาษี  : ${currencyFormatter.format(consumption.vat, {
                  code: "",
                })} บาท`,
                fontSize: "14",
              },
            ],
            [
              {
                text: `ค่าไฟฟ้าทั้งหมด (รวมภาษี) : ${currencyFormatter.format(
                  consumption.summary,
                  { code: "" }
                )} บาท`,
                fontSize: "18",
                bold: true,
                fillColor: "#c2dec2",
              },
            ],
          ],
        },
      },
      {
        text: `ข้อมูลเพิ่ม`,
        fontSize: "16",
        bold: true,
      },
      {
        margin: [0, 0, 0, 10],
        layout: {
          fillColor: function (rowIndex, node, columnIndex) {
            return rowIndex % 2 === 0 ? "#ebebeb" : "#f5f5f5";
          },
        },
        table: {
          widths: ["100%"],
          heights: [20, 10],
          body: [
            [
              {
                text: `ช่วงที่ความต้องการพลังไฟฟ้าช่วง On Peak สูงสุด : ${moment(
                  consumption.date_max_on_peak
                ).format("LLLL")} น.`,
                fontSize: "14",
              },
            ],
            [
              {
                text: `ความต้องการพลังไฟฟ้าสูงสุดช่วง On Peak : ${currencyFormatter.format(
                  consumption.max_on_peak,
                  {
                    code: "",
                  }
                )} กิโลวัตต์`,
                fontSize: "14",
              },
            ],
            [
              {
                text: `ความต้องการพลังไฟฟ้าสูงสุดช่วง Off Peak : ${currencyFormatter.format(
                  consumption.max_off_peak,
                  {
                    code: "",
                  }
                )} กิโลวัตต์`,
                fontSize: "14",
              },
            ],
            [
              {
                text: `ความต้องการพลังไฟฟ้าสูงสุดช่วง Holiday : ${currencyFormatter.format(
                  consumption.max_holiday,
                  {
                    code: "",
                  }
                )} กิโลวัตต์`,
                fontSize: "14",
              },
            ],

            [
              {
                text: `ปริมาณการใช้พลังงานไฟฟ้าทั้งหมด : ${currencyFormatter.format(
                  consumption.all,
                  {
                    code: "",
                  }
                )} หน่วย`,
                fontSize: "14",
              },
            ],

            [
              {
                text: `ปริมาณการใช้พลังงานไฟฟ้าช่วง On Peak : ${currencyFormatter.format(
                  consumption.onPeak,
                  {
                    code: "",
                  }
                )} หน่วย`,
                fontSize: "14",
              },
            ],
            [
              {
                text: `ปริมาณการใช้พลังงานไฟฟ้าช่วง Off Peak : ${currencyFormatter.format(
                  consumption.offPeak,
                  {
                    code: "",
                  }
                )} หน่วย`,
                fontSize: "14",
              },
            ],

            [
              {
                text: `ปริมาณการใช้พลังงานไฟฟ้าช่วง Holiday : ${currencyFormatter.format(
                  consumption.holiday,
                  {
                    code: "",
                  }
                )} หน่วย`,
                fontSize: "14",
              },
            ],
          ],
        },
      },
      {
        text: `ประมาณการค่าไฟฟ้ารายวัน`,
        fontSize: "16",
        bold: true,
      },

      {
        style: "tableExample",
        layout: {
          fillColor: function (rowIndex, node, columnIndex) {
            return rowIndex === 0 ? "#c2dec2" : null;
          },
        },
        table: {
          widths: ["13%", "12%", "15%", "15%", "15%", "30%"],
          heights: [10, 10, 10, 10, 30, 10, 25],
          headerRows: 1,
          body: [
            [
              {
                text: "วันที่",
                colSpan: 1,
                fontSize: 14,
              },
              {
                text: "ทั้งหมด",
                colSpan: 1,
                fontSize: 14,
              },
              {
                text: "ช่วง ON PEAK",
                colSpan: 1,
                fontSize: 14,
              },
              {
                text: "ช่วง OFF PEAK",
                colSpan: 1,
                fontSize: 14,
              },
              {
                text: "ช่วง HOLIDAY",
                colSpan: 1,
                fontSize: 14,
              },
              {
                text: "ประมาณค่าพลังงานไฟฟ้ารายวัน",
                colSpan: 1,
                fontSize: 14,
              },
            ],
            ...history,
          ],
        },
      },
    ],
    images: {
      company_logo: systemInfo.logo,
    },
  };
  pdfMake.createPdf(docDefinition).open();
};
