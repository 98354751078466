import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../redux/actions";
import { Link, useParams, useHistory } from "react-router-dom";

// components
import { Button, Switch } from "@chakra-ui/react";

import SpinnerLoading from "components/Loading/SpinnerLoading";
import _ from "lodash";
import ControlLists from "./components/ControlLists";

export default function Dashboard() {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingControlsRes, setIsLoadingControlsRes] = useState(false);
  const allStations = useSelector((state) => state.station);
  const [currentStation, setcurrentStation] = useState(false);
  const [isControlEnable, setIsControlEnable] = useState(true);
  const [fetchingNewOne, setFetchingNewOne] = useState(true);
  const dispatch = useDispatch();
  const params = useParams();
  const me = useSelector((state) => state.me);
  const history = useHistory();

  useEffect(() => {
    if (me && me._id && currentStation) {
      if (me.duty === "ALLAREA" || me.duty === currentStation._id) {
      } else {
        console.log("ผู้ใช้ไม่มีสิทธิเข้าใช้");
        history.push("/dashboards");
      }
    }
    return () => {};
  }, [me, currentStation]);

  // รีเฟรชทุกๆ 5 วินาที
  useEffect(() => {
    if (fetchingNewOne) {
      dispatch(actions.stationAll()).then(() => {
        setIsLoadingControlsRes(true);
        setFetchingNewOne(!fetchingNewOne);
      });
    } else if (fetchingNewOne === false)
      setTimeout(() => {
        setIsLoading(false);
        setIsLoadingControlsRes(false);
        setFetchingNewOne(!fetchingNewOne);
      }, 4000);
    return () => {};
  }, [fetchingNewOne]);

  useEffect(() => {
    if (allStations) {
      const tempcurrentStatus = _.find(
        allStations.arr,
        (station) => station._id === params.stationId
      );
      // alert("current stations ")
      setcurrentStation(tempcurrentStatus);
      // console.log("Curent Station", tempcurrentStatus);
    }
    return () => {};
  }, [params, allStations, fetchingNewOne]);

  return currentStation ? (
    <div className="relative flex flex-col min-w-0 break-words bg-gray-100 w-full mb-6 shadow-lg rounded">
      <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
        <div className="flex flex-wrap items-center">
          <div className="relative w-full max-w-full flex-grow flex-1">
            <h6 className="uppercase text-gray-500 mb-1 text-xs font-semibold font-sans">
              การควบคุม
            </h6>
            <h2 className="text-gray-800 text-2xl font-bold font-sans">
              การควบคุม {currentStation.name}{" "}
            </h2>
            <p className="text-base text-gray-500 mb-1 font-sans  ">
              {currentStation.location}
            </p>
          </div>
        </div>
      </div>
      {/* <div className='flex justify-start p-4 '>
        <div className='flex justify-center gap-4 border-2 border-gray-400 px-4 py-2 rounded-lg bg-gray-300'>
          <h4>ล็อกหน้าจอ</h4>
          <Switch
            size='lg'
            defaultChecked={isControlEnable}
            onChange={(event) => setIsControlEnable(!isControlEnable)}
          />
        </div>
      </div> */}

      {isLoadingControlsRes ? (
        <>
          <div className="p-4 w-full">
            <div className="overflow-x-auto">
              <ControlLists
                setIsLoadingControlsRes={setIsLoadingControlsRes}
                currentStation={currentStation}
                isControlEnable={isControlEnable}
              />
            </div>
          </div>
        </>
      ) : (
        <div className="p-4 w-full">
          <div className="overflow-x-auto">
            <SpinnerLoading />
          </div>
        </div>
      )}
    </div>
  ) : (
    <SpinnerLoading />
  );
}
