import React, { useState } from 'react'
import {
    Badge,
    Box,
    Button,
    FormControl,
    Input,
    Select,
} from '@chakra-ui/react'
import { useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import _ from 'lodash'

import * as actions from '../../../redux/actions'
import GeneralGagueSelector from '../Functions/GeneralGaugeSelector'
import EditingGeneralGagueSelector from '../Functions/EditingGeneralGagueSelector'
import SensorDataRendering from '../../Sensors/Functions/SensorDataRendering'
import VariableDataRendering from '../../Variables/Function/VariableMainRendering'
import Config from '../../../config'

export default function GaugeMakingComponent({ stationData, gaugeData }) {
    const [isEditorMode, setIsEditorMode] = useState(false)
    const [selectedGagueType, setSelectedGagueType] = useState(
        gaugeData?.gaugeType
    )
    const [sensorType, setSensorType] = useState(gaugeData?.sensorType)
    const [gaugeSource, setGaugeSource] = useState(gaugeData?.gaugeSource)
    const [additionalInfo, setAdditionalInfo] = useState()
    const { register, handleSubmit } = useForm()
    const dispatch = useDispatch()

    // Merged the Sensor Data and Make new Station Data to Database
    const prepareUpdateDatabase = (data) => {
        const selectedGauge = _.find(
            stationData.gauges,
            (gague) => gague?._id === gaugeData._id
        )
        const selectedGaugeIndex = _.findIndex(
            stationData.gauges,
            (gauge) => gauge?._id === gaugeData._id
        )
        console.log('Additional info', additionalInfo)
        const mergedgaugeData = _.merge(selectedGauge, data, additionalInfo)
        let editedStation = stationData
        editedStation.gauges[selectedGaugeIndex] = mergedgaugeData

        console.log('This is Station')
        console.log('Data', data)

        dispatch(actions.stationPut(stationData?._id, editedStation)).then(
            () => {
                dispatch(actions.stationAll())
            }
        )
        setIsEditorMode(false)
    }

    const prepateDeleteSensor = (gaugeId) => {
        let confirm = window.confirm('ยืนยันการลบเกจ')
        if (confirm) {
            const selectedSensorIndex = _.findIndex(
                stationData?.gauges,
                (gauge) => gauge?._id === gaugeId
            )
            let editedStation = stationData
            if (editedStation?.gauges) {
                console.log('Original Sensor Stack', editedStation?.gauges)
                editedStation.gauges.splice(selectedSensorIndex, 1)
                console.log('SPLICED')
                console.log('New Sensor Stack', editedStation?.gauges)
            }
            console.log('This is Station')
            dispatch(actions.stationPut(stationData?._id, editedStation)).then(
                () => {
                    dispatch(actions.stationAll())
                }
            )
            setIsEditorMode(false)
        }
    }

    return (
        <Box
            borderRadius="lg"
            padding="4"
            className="bg-white my-2 overflow-x-auto"
        >
            <form onSubmit={handleSubmit(prepareUpdateDatabase)}>
                <div className="flex gap-3">
                    <div className="w-5/6">
                        <h5 className="text-lg font-bold font-sans">
                            {gaugeData?.name}
                            <Badge colorScheme="teal" size="sm">
                                {gaugeData?.sensorType}
                            </Badge>
                        </h5>
                    </div>

                    <div className="flex gap-1 ">
                        {isEditorMode === true ? (
                            <>
                                <Button
                                    size="sm"
                                    type="button"
                                    colorScheme="gray"
                                    onClick={() => setIsEditorMode(false)}
                                >
                                    ยกเลิก
                                </Button>
                                <Button
                                    size="sm"
                                    colorScheme="green"
                                    type="submit"
                                >
                                    บันทึก
                                </Button>
                            </>
                        ) : (
                            <>
                                <Button
                                    size="sm"
                                    colorScheme="yellow"
                                    onClick={() => setIsEditorMode(true)}
                                    type="button"
                                >
                                    แก้ไข
                                </Button>
                                <Button
                                    size="sm"
                                    colorScheme="red"
                                    type="button"
                                    onClick={() =>
                                        prepateDeleteSensor(gaugeData._id)
                                    }
                                >
                                    ลบ
                                </Button>
                            </>
                        )}
                    </div>
                </div>

                <div className="flex gap-3">
                    <div className="w-full lg:w-1/3 font-sans mx-4">
                        <GeneralGagueSelector
                            gaugeData={gaugeData}
                            stationData={stationData}
                            staticValue={
                                gaugeData?.gaugeSource ===
                                Config.TypeOfSource.GaugeSource.sensor
                                    ? SensorDataRendering(
                                          stationData,
                                          gaugeData
                                      )
                                    : VariableDataRendering(
                                          stationData,
                                          gaugeData?.variableId
                                      )
                            }
                        />
                    </div>
                    <div className="w-full lg:w-2/3">
                        {isEditorMode === true ? (
                            <>
                                <FormControl>
                                    <label className="text-sm font-bold">
                                        ชื่อเกจ
                                    </label>
                                    <Input
                                        type="text"
                                        name="gaugeLabel"
                                        size="sm"
                                        defaultValue={gaugeData.gaugeLabel}
                                        ref={register}
                                    />
                                </FormControl>

                                <FormControl>
                                    <label className="text-sm font-bold">
                                        รูปแบบค่าที่จะแสดง
                                    </label>
                                    <Select
                                        defaultValue={gaugeData?.sensorType}
                                        ref={register}
                                        size="sm"
                                        name="sensorType"
                                        onChange={(event) =>
                                            setSensorType(event.target.value)
                                        }
                                    >
                                        <option value="">
                                            เลือกรูปแบบค่าที่จะแสดง
                                        </option>
                                        <option
                                            value={
                                                Config.DataType.DataType.boolean
                                            }
                                        >
                                            Boolean
                                        </option>
                                        <option
                                            value={
                                                Config.DataType.DataType.number
                                            }
                                        >
                                            Number
                                        </option>
                                        <option
                                            value={
                                                Config.DataType.DataType.string
                                            }
                                        >
                                            String
                                        </option>
                                    </Select>
                                </FormControl>

                                <FormControl>
                                    <label className="text-sm font-bold">
                                        รูปแบบเกจ
                                    </label>
                                    <Select
                                        defaultValue={gaugeData?.gaugeType}
                                        ref={register}
                                        size="sm"
                                        name="gaugeType"
                                        onChange={(event) => {
                                            setSelectedGagueType(
                                                event.target.value
                                            )
                                        }}
                                    >
                                        <option
                                            value={
                                                Config.ComponentType.GaugeType
                                                    .NoDisplay
                                            }
                                        >
                                            ไม่มีการแสดงค่า
                                        </option>
                                        <option
                                            value={
                                                Config.ComponentType.GaugeType
                                                    .StaticBoolean
                                            }
                                        >
                                            แสดงค่า Boolean
                                        </option>
                                        <option
                                            value={
                                                Config.ComponentType.GaugeType
                                                    .BooleanWaterDisplay
                                            }
                                        >
                                            ระดับน้ำจากค่า Boolean
                                        </option>
                                        <option
                                            value={
                                                Config.ComponentType.GaugeType
                                                    .StaticMeter
                                            }
                                        >
                                            หน้าปัดมิเตอร์
                                        </option>
                                        <option
                                            value={
                                                Config.ComponentType.GaugeType
                                                    .StaticNumber
                                            }
                                        >
                                            แสดงตัวเลข
                                        </option>
                                        <option
                                            value={
                                                Config.ComponentType.GaugeType
                                                    .StaticString
                                            }
                                        >
                                            แสดงข้อความ
                                        </option>
                                        <option
                                            value={
                                                Config.ComponentType.GaugeType
                                                    .MultiStepBooleanWaterDisplay
                                            }
                                        >
                                            ระดับน้ำจากค่า Boolean 2 ระดับ
                                        </option>
                                    </Select>
                                </FormControl>
                                <FormControl>
                                    <label className="text-sm font-bold">
                                        ที่มาของค่า
                                    </label>
                                    <Select
                                        defaultValue={gaugeData?.gaugeSource}
                                        ref={register}
                                        size="sm"
                                        name="gaugeSource"
                                        onChange={(event) =>
                                            setGaugeSource(event.target.value)
                                        }
                                    >
                                        <option value="">
                                            เลือกที่มาของข้อมูล
                                        </option>
                                        <option
                                            value={
                                                Config.TypeOfSource.GaugeSource
                                                    .sensor
                                            }
                                        >
                                            เซ็นเซอร์
                                        </option>
                                        <option
                                            value={
                                                Config.TypeOfSource.GaugeSource
                                                    .variable
                                            }
                                        >
                                            ตัวแปร
                                        </option>
                                    </Select>
                                </FormControl>
                                <EditingGeneralGagueSelector
                                    gaugeData={gaugeData}
                                    register={register}
                                    gaugeType={selectedGagueType}
                                    stationData={stationData}
                                    setAdditionalInfo={setAdditionalInfo}
                                    sensorType={sensorType}
                                    gaugeSource={gaugeSource}
                                />
                            </>
                        ) : (
                            <>
                                <p className="text-sm ">
                                    {' '}
                                    <span className="text-sm font-semibold text-gray-700">
                                        ชื่อเกจ
                                    </span>{' '}
                                    {gaugeData?.gaugeLabel}{' '}
                                </p>
                                <ul>
                                    <li>
                                        <span className="text-sm font-semibold text-gray-700">
                                            รูปของเซ็นเซอร์ :{' '}
                                        </span>
                                        <span className="text-sm font-semibold text-blue-700">
                                            {gaugeData?.sensorType}
                                        </span>
                                    </li>

                                    <li>
                                        <span className="text-sm font-semibold text-gray-700">
                                            รูปแบบเกจ :{' '}
                                        </span>
                                        <span className="text-sm font-semibold text-blue-700">
                                            {gaugeData?.gaugeType}
                                        </span>
                                    </li>
                                </ul>
                            </>
                        )}
                    </div>
                </div>
            </form>
        </Box>
    )
}
