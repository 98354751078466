import React from "react";
import { Input, InputLeftElement, InputGroup } from "@chakra-ui/react";
export default function EmloyeeSearch({ data, setData }) {
  const handleSearch = (word) => {
    console.log(data);
    const res = data.filter(
      (user) =>
        user.name.includes(word) ||
        user.email.includes(word)
    );
    setData(res);
  };
  return (
    <div>
      <InputGroup>
        <InputLeftElement
          pointerEvents="none"
          children={<i className="fa fa-search" aria-hidden="true"></i>}
        />
        <Input
          className="shadow-md"
          colorScheme="black"
          placeholder="ค้นหา"
          onChange={(e) => {
            data && handleSearch(e.target.value);
          }}
        />
      </InputGroup>
    </div>
  );
}
