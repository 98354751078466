import _ from 'lodash'
import NumberLogicResolve from './NumberLogicResolve'
import BooleanLogicResolve from './BooleanLogicResolve'
import BooleanNumericLogicResolve from './BooleanNumericLogicResolve'
import {
    BooleanNumericOperation,
    BooleanOperation,
    NumericOperation,
} from '../../config/MathOperation'

export default function LogicResolver(operation, leftData, rightData) {
    console.log(
        'Mathemetic Logic Resolver *---* ',
        leftData,
        operation,
        rightData
    )
    if (
        _.includes(
            [
                NumericOperation.ADD,
                NumericOperation.DIVIDE,
                NumericOperation.MULTIPLY,
                NumericOperation.SUBTRACT,
            ],
            operation
        )
    ) {
        console.log('Working on Number')
        return NumberLogicResolve(operation, leftData, rightData)
    } else if (
        _.includes(
            [
                BooleanOperation.AND,
                BooleanOperation.OR,
                BooleanOperation.NOR,
                BooleanOperation.NAND,
            ],
            operation
        )
    ) {
        console.log('Working on Boolean ')
        return BooleanLogicResolve(operation, leftData, rightData)
    } else if (
        _.includes(
            [
                BooleanNumericOperation.EQ,
                BooleanNumericOperation.GE,
                BooleanNumericOperation.GEQ,
                BooleanNumericOperation.LE,
                BooleanNumericOperation.LEQ,
                BooleanNumericOperation.NEQ,
            ],
            operation
        )
    ) {
        console.log('Working on Boolean Numeric')
        return BooleanNumericLogicResolve(operation, leftData, rightData)
    } else {
        console.log(
            'คุณเลือก',
            operation,
            'เป็น Operation ที่เราไม่สามารถ Resolve ได้'
        )
        return false
    }
}

export { NumberLogicResolve, BooleanLogicResolve, BooleanNumericLogicResolve }
