import React, { useRef, useState, useEffect } from 'react'
import _, { forEach } from 'lodash'
import { Box } from '@chakra-ui/react'
import Xarrow from 'react-xarrows'
import moment from 'moment'

/** Component */
import SpinnerComponent from '../../Loading/SpinnerLoading'
import DiagramComponentDispaly from '../Display/DiagramComponentDisplay'
import DiagramLineRenderingComponent from '../Function/DiagramLineRenderingComponent'
/** Create by Theethawat@IARC PSU
 * For Any Issue pls Contact 093-674-9432 or theethawat.s@outlook.co.th
 */

export default function GeneralStationCanvas({ stationData }) {
    const [isLoading, setIsLoading] = useState(true)

    const xArrowComponent = (index, diagramComponent, yourRef, nextRef) => (
        <DiagramLineRenderingComponent
            yourRef={yourRef}
            nextRef={nextRef}
            key={index}
            diagramData={diagramComponent}
            stationData={stationData}
        />
    )
    if (isLoading)
        return (
            <div>
                <div className="block  w-full relative  ">
                    <div className="flex flex-row flex-no-wrap ">
                        {_.map(
                            stationData.diagrams,
                            (diagramComponent, index) => (
                                <>
                                    <DiagramComponentDispaly
                                        key={index}
                                        diagramElement={diagramComponent}
                                        stationData={stationData}
                                        idRef={diagramComponent._id}
                                    />{' '}
                                    <div className=" w-12"></div>
                                    <div className=" w-12"></div>
                                </>
                            )
                        )}
                    </div>
                </div>
                <div>
                    {_.map(stationData.diagrams, (diagramComponent, index) =>
                        xArrowComponent(
                            index,
                            diagramComponent,
                            diagramComponent._id,
                            stationData.diagrams[index + 1]
                                ? stationData.diagrams[index + 1]._id
                                : ''
                        )
                    )}
                </div>
            </div>
        )
    else {
        return <SpinnerComponent />
    }
}
