import axios from 'axios'
import { STATION_ALL, STATION_GET, STATION_PUT, STATION_DEL } from '../types'

export const stationAll = () => {
    return async (dispatch) => {
        await axios
            .get(process.env.REACT_APP_API_URL + '/station')
            .then((res) => {
                console.log('Request Server to Get All Stations')
                dispatch({ type: STATION_ALL, payload: res.data })
            })
    }
}

export const stationGet = (payload) => {
    return async (dispatch) => {
        await axios
            .get(process.env.REACT_APP_API_URL + '/station/' + payload)
            .then((res) => {
                console.log('Request Server to Get an Stations')
                if (res.data) {
                    dispatch({ type: STATION_GET, payload: res.data })
                } else {
                    dispatch({ type: STATION_GET, payload: null })
                }
            })
    }
}

export const stataionRefresh = (payload) => {
    return async (dispatch) => {
        await axios
            .get(process.env.REACT_APP_API_URL + '/station/update/' + payload)
            .then((res) => {
                console.log('Request Update')
                // Let it wait Backend fetching data for a while
                setTimeout(() => {
                    dispatch(stationAll())
                }, 2000)
            })
    }
}

export const stationPut = (id, payload) => {
    return async (dispatch) => {
        await axios
            .put(process.env.REACT_APP_API_URL + '/station/' + id, payload)
            .then((res) => {
                console.log('Request Server to put an Stations')
                dispatch({ type: STATION_PUT, payload: null })
            })
    }
}

export const stationPost = (payload) => {
    return async (dispatch) => {
        await axios
            .post(process.env.REACT_APP_API_URL + '/station/', payload)
            .then((res) => {
                console.log('Request to Create New Station')
                dispatch(stationAll())
            })
    }
}
export const stationDelete = (payload) => {
    return async (dispatch) => {
        await axios
            .delete(process.env.REACT_APP_API_URL + '/station/' + payload)
            .then((res) => {
                console.log('Request Server to Delete an Stations')
                dispatch({ type: STATION_DEL, payload: null })
            })
    }
}
