import React, { useEffect, useState } from "react"

import { useDispatch, useSelector } from "react-redux"
import * as actions from "../../redux/actions"
import { Link } from "react-router-dom"

// components
import { Box, Button, Link as LinkUI } from "@chakra-ui/react"

import SpinnerLoading from "components/Loading/SpinnerLoading"
import _ from "lodash"
import DashboardMonitorComponent from "./Components/DashboardMonitorComponent"

export default function Dashboard({ sidebarStatus, setIsShowSidebar }) {
  const [isLoading, setIsLoading] = useState(false)
  const [fetchingNewOne, setFetchingNewOne] = useState(true)
  const [allowedDashboard, setAllowedDashboard] = useState()
  const stations = useSelector((state) => state.station)
  const me = useSelector((state) => state.me)
  const dispatch = useDispatch()

  useEffect(() => {
    if (fetchingNewOne) {
      dispatch(actions.stationAll())
      setIsLoading(true)
      setFetchingNewOne(!fetchingNewOne)
    }
    return () => {}
  }, [fetchingNewOne])

  // ตรวจสอบว่า User ที่เข้ามามี Permission ที่เข้าสถานีไหนได้บ้าง
  // ตอนนี้ยังเก็บเป็น Text อยู่ เก็บเฉพาะใน User ไม่ได้เก็บในสถานี
  // ถ้าอนุญาติหมดจะเป็น ALLAREA
  // TODO: ถ้าเป็น Array จะดีมาก
  useEffect(() => {
    if (me && me._id) {
      if (_.size(stations.arr)) {
        if (me.duty === "ALLAREA") {
          let allowedStationStack = stations.arr
          setAllowedDashboard(allowedStationStack)
        } else {
          let allowedStation = _.filter(
            stations.arr,
            (station) => station._id === me.duty
          )
          setAllowedDashboard(allowedStation)
        }
      }
    }
    return () => {}
  }, [stations, me])

  // รีเฟรชทุกๆ 5 วินาที
  useEffect(() => {
    if (fetchingNewOne === false)
      setTimeout(() => {
        setFetchingNewOne(!fetchingNewOne)
      }, 5000)
    return () => {}
  }, [fetchingNewOne])

  // console.log("station arr : ", stations.arr);
  return isLoading ? (
    <div className='relative flex flex-col min-w-0 break-words bg-gray-100 w-full mb-6 shadow-lg rounded'>
      <div className='rounded-t mb-0 px-4 py-3 bg-transparent'>
        <div className='flex flex-wrap items-center'>
          <div className='relative w-full max-w-full flex-grow flex-1'>
            <h6 className='uppercase text-gray-500 mb-1 text-xs font-semibold font-sans'>
              เฝ้าระวังและตรวจสอบระบบ
            </h6>
            <h2 className='text-gray-800 text-2xl font-bold font-sans'>
              แดชบอร์ด
            </h2>
          </div>
        </div>
      </div>

      {allowedDashboard && _.size(allowedDashboard) ? (
        <>
          <div className='flex justify-between p-4'>
            <div className='flex justify-items-start'></div>
            <div className='flex justify-items-end'>
              <Button
                colorScheme='gray'
                variant='solid'
                size='sm'
                onClick={() => setIsShowSidebar(!sidebarStatus)}
              >
                เปิด/ปิดแถบข้าง
              </Button>
            </div>
          </div>{" "}
          <div className='p-4 w-full'>
            {_.map(stations.arr, (station, index) => (
              <Box
                borderRadius='lg'
                borderWidth='1px'
                padding='4'
                className='mb-6 shadow-sm '
                key={index}
              >
                <Link to={"/dashboards/board/" + station._id}>
                  <h2 className='text-xl font-bold '>
                    {" "}
                    <LinkUI className='font-sans font-bold'>
                      {station.name}{" "}
                    </LinkUI>{" "}
                  </h2>{" "}
                </Link>
                <h5 className='text-base text-gray-700 font-sans'>
                  {" "}
                  {station.description} | {station.location}{" "}
                </h5>

                {/**ในหน้านี้ ถ้าเป็น Mobile จะให้แสดงเฉพาะลิสต์ของสถานี ไม่โชว์แดชบอร์ดเต็มๆ */}
                <div className='font-sans hidden md:block'>
                  <DashboardMonitorComponent currentStation={station} />
                </div>
              </Box>
            ))}
          </div>
        </>
      ) : (
        <div className='p-4 w-full'>ไม่มีข้อมูล</div>
      )}
    </div>
  ) : (
    <SpinnerLoading />
  )
}
