import React, { useState } from 'react'
import {
    Badge,
    Box,
    Button,
    Code,
    FormControl,
    Input,
    Select,
} from '@chakra-ui/react'
import { useDispatch } from 'react-redux'
import * as actions from '../../redux/actions'
import { useForm } from 'react-hook-form'
import _ from 'lodash'

export default function SensorComponent({
    stationData,
    sensorData,
    placeToEdit = 'station',
}) {
    const [isEditorMode, setIsEditorMode] = useState(false)
    const { register, handleSubmit } = useForm()
    const dispatch = useDispatch()
    // Merged the Sensor Data and Make new Station Data to Database
    const prepareUpdateDatabase = (data) => {
        const selectedSensor = _.find(
            stationData.sensors,
            (sensor) => sensor._id === sensorData._id
        )
        const selectedSensorIndex = _.findIndex(
            stationData.sensors,
            (sensor) => sensor._id === sensorData._id
        )
        const mergedSensorData = _.merge(selectedSensor, data)
        let editedStation = stationData
        editedStation.sensors[selectedSensorIndex] = mergedSensorData

        // is on Template or on Station FIXME: Magic Code

        if (placeToEdit === 'template') {
            console.log('This is Template not station')
            dispatch(actions.stationTemplatePut(stationData._id, editedStation))
        } else {
            console.log('This is Station')
            dispatch(actions.stationPut(stationData._id, editedStation)).then(
                () => {
                    dispatch(actions.stationAll())
                }
            )
        }

        setIsEditorMode(false)
        window.location.reload();
    }

    const prepateDeleteSensor = (sensorId) => {
        let confirm = window.confirm('ยืนยันการลบเซ็นเซอร์')
        if (confirm) {
            const selectedSensorIndex = _.findIndex(
                stationData.sensors,
                (sensor) => sensor._id === sensorId
            )

            let editedStation = stationData
            if (editedStation.sensors) {
                console.log('Original Sensor Stack', editedStation.sensors)
                editedStation.sensors.splice(selectedSensorIndex, 1)
                console.log('SPLICED')
                console.log('New Sensor Stack', editedStation.sensors)
            }

            // is on Template or on Station FIXME: Magic Code
            if (placeToEdit === 'template') {
                console.log('This is Template not station')
                dispatch(
                    actions.stationTemplatePut(stationData._id, editedStation)
                )
            } else {
                console.log('This is Station')
                dispatch(
                    actions.stationPut(stationData._id, editedStation)
                ).then(() => {
                    dispatch(actions.stationAll())
                })
            }
            setIsEditorMode(false)
        }
    }

    return (
        <Box borderRadius="lg" padding="4" className="bg-white my-2">
            <form onSubmit={handleSubmit(prepareUpdateDatabase)}>
                <div className="flex gap-3">
                    <div className="w-5/6">
                        <h5 className="text-lg font-bold font-sans">
                            {' '}
                            {sensorData.name}{' '}
                            <Badge colorScheme="teal" size="sm">
                                {sensorData.sensorType}{' '}
                            </Badge>{' '}
                        </h5>
                    </div>

                    <div className="flex gap-1 ">
                        {isEditorMode === true ? (
                            <>
                                <Button
                                    size="sm"
                                    type="button"
                                    colorScheme="gray"
                                    onClick={() => setIsEditorMode(false)}
                                >
                                    ยกเลิก
                                </Button>{' '}
                                <Button
                                    size="sm"
                                    colorScheme="green"
                                    type="submit"
                                >
                                    บันทึก
                                </Button>
                            </>
                        ) : (
                            <>
                                <Button
                                    size="sm"
                                    colorScheme="yellow"
                                    onClick={() => setIsEditorMode(true)}
                                    type="button"
                                >
                                    แก้ไข
                                </Button>
                                <Button
                                    size="sm"
                                    colorScheme="red"
                                    type="button"
                                    onClick={() =>
                                        prepateDeleteSensor(sensorData._id)
                                    }
                                >
                                    ลบ
                                </Button>
                            </>
                        )}
                    </div>
                </div>

                <div className="flex gap-3">
                    <div>
                        {isEditorMode === true ? (
                            <>
                                <FormControl>
                                    <label className="text-sm font-bold">
                                        {' '}
                                        ชื่อเซ็นเซอร์{' '}
                                    </label>
                                    <Input
                                        type="text"
                                        name="name"
                                        size="sm"
                                        defaultValue={sensorData.name}
                                        ref={register}
                                    />
                                </FormControl>

                                <FormControl>
                                    <label className="text-sm font-bold">
                                        {' '}
                                        คำอธิบายเซ็นเซอร์{' '}
                                    </label>
                                    <Input
                                        type="text"
                                        name="description"
                                        size="sm"
                                        defaultValue={sensorData.description}
                                        ref={register}
                                    />
                                </FormControl>

                                <FormControl>
                                    <label className="text-sm font-bold">
                                        Iot Reference String
                                    </label>
                                    <Input
                                        type="text"
                                        name="refString"
                                        size="sm"
                                        defaultValue={sensorData.refString}
                                        ref={register}
                                    />{' '}
                                </FormControl>

                                <FormControl>
                                    <label className="text-sm font-bold">
                                        {' '}
                                        ลักษณะพิเศษของเซ็นเซอร์{' '}
                                    </label>
                                    <Select
                                        defaultValue={sensorData.gaugeType}
                                        ref={register}
                                        size="sm"
                                        name="gaugeType"
                                    >
                                        <option value="">
                                            ไม่มีลักษณะพิเศษ
                                        </option>
                                        <option value="OnOffGauge">
                                            เซ็นเซอร์ที่มีการควบคุม
                                        </option>
                                        <option value="TriggerGague">
                                            เซ็นเซอร์ที่ควบคุมโดยใช้ Trigger
                                        </option>

                                        <option value="MajorControl">
                                            เซ็นเซอร์อนุญาติให้ควบคุมเซ็นเซอร์อื่น
                                            ๆ
                                        </option>
                                    </Select>
                                </FormControl>

                                {sensorData.sensorType === 'BOOLEAN' && (
                                    <>
                                        <FormControl>
                                            <label className="text-sm font-bold">
                                                Iot Control Reference String
                                            </label>
                                            <Input
                                                type="text"
                                                name="controlRefString"
                                                size="sm"
                                                defaultValue={
                                                    sensorData.controlRefString
                                                }
                                                ref={register}
                                            />{' '}
                                        </FormControl>
                                        <FormControl>
                                            <label className="text-sm font-bold">
                                                {' '}
                                                ต้องใช้ Pulse ในการ Control{' '}
                                            </label>
                                            <Select
                                                defaultValue={
                                                    sensorData.controlWithPulse
                                                }
                                                ref={register}
                                                size="sm"
                                                name="controlWithPulse"
                                            >
                                                <option value={true}>
                                                    ใช่
                                                </option>
                                                <option value={false}>
                                                    ไม่ใช่
                                                </option>
                                            </Select>
                                        </FormControl>
                                        <FormControl>
                                            <label className="text-sm font-bold">
                                                ค่าเริ่มต้น
                                            </label>
                                            <Select
                                                defaultValue={
                                                    sensorData.booleanDefaultValue
                                                }
                                                ref={register}
                                                size="sm"
                                                name="booleanDefaultValue"
                                            >
                                                <option value={Boolean(true)}>
                                                    True
                                                </option>
                                                <option value={Boolean(false)}>
                                                    False
                                                </option>
                                            </Select>
                                        </FormControl>
                                    </>
                                )}

                                {sensorData.sensorType === 'NUMBER' && (
                                    <>
                                        <FormControl>
                                            <label className="text-sm font-bold">
                                                หน่วยของค่าที่ได้{' '}
                                            </label>
                                            <Input
                                                type="text"
                                                name="valueUnit"
                                                size="sm"
                                                defaultValue={
                                                    sensorData.valueUnit
                                                }
                                                ref={register}
                                            />{' '}
                                        </FormControl>
                                    </>
                                )}
                            </>
                        ) : (
                            <>
                                <p className="text-sm ">
                                    {' '}
                                    {sensorData.description}{' '}
                                </p>
                                <ul>
                                    {sensorData.gaugeType !== '' ? (
                                        <li>
                                            <span className="text-sm font-semibold text-gray-700">
                                                ลักษณะพิเศษของเซ็นเซอร์ :{' '}
                                            </span>
                                            <span className="text-sm font-semibold text-blue-700">
                                                {sensorData.gaugeType === ''
                                                    ? 'ไม่มีลักษณะพิเศษ'
                                                    : sensorData.gaugeType}
                                            </span>
                                        </li>
                                    ) : (
                                        ''
                                    )}
                                    {sensorData.sensorType === 'BOOLEAN' && (
                                        <>
                                            <li>
                                                <span className="text-sm font-semibold text-gray-700">
                                                    การควบคุมโดยใช้ Pulse :{' '}
                                                </span>
                                                <span className="text-sm font-semibold text-blue-700">
                                                    {sensorData.controlWithPulse ===
                                                    true
                                                        ? 'ใช่'
                                                        : 'ไม่ใช่'}
                                                </span>
                                            </li>
                                            <li>
                                                <span className="text-sm font-semibold text-gray-700">
                                                    ค่าเริ่มต้น:{' '}
                                                </span>
                                                {sensorData.booleanDefaultValue ===
                                                true ? (
                                                    <>
                                                        <Badge colorScheme="green">
                                                            {' '}
                                                            True{' '}
                                                        </Badge>
                                                    </>
                                                ) : (
                                                    <>
                                                        {' '}
                                                        <Badge colorScheme="red">
                                                            {' '}
                                                            False{' '}
                                                        </Badge>
                                                    </>
                                                )}
                                            </li>{' '}
                                            <li>
                                                <span className="text-sm font-semibold text-gray-700">
                                                    IoT Control Reference String
                                                    :{' '}
                                                </span>
                                                <Code>
                                                    {' '}
                                                    {
                                                        sensorData.controlRefString
                                                    }
                                                </Code>
                                            </li>
                                        </>
                                    )}

                                    <li>
                                        <span className="text-sm font-semibold text-gray-700">
                                            IoT Reference String :{' '}
                                        </span>
                                        <Code> {sensorData.refString}</Code>
                                    </li>
                                </ul>
                            </>
                        )}
                    </div>
                </div>
            </form>
        </Box>
    )
}
