import _ from 'lodash'

import Config from '../../../../../config'
import MathematicResolver from '../../../../../util/LogicResolve'
import FetchingGraphData from '../../FetchingGraphData'

// It work only 2 sensors
export default function MultiSensorDataFinding(
    stationData,
    subvariableData,
    dataArr
) {
    const invert = (element) => {
        return { x: element.x, y: !element.y }
    }

    // Find Left Sensor
    const leftSensor = _.find(
        stationData?.sensors,
        (sensor) => sensor?.refString === subvariableData?.leftSensorRef
    )
    let leftSensorData = FetchingGraphData(dataArr, leftSensor?._id)

    // Find Right Sensor
    const rightSensor = _.find(
        stationData?.sensors,
        (sensor) => sensor?.refString === subvariableData?.rightSensorRef
    )

    let rightSensorData = FetchingGraphData(dataArr, rightSensor?._id)

    // Find Control
    const operation = subvariableData.operation
    if (subvariableData.isReverseLeft === true) {
        if (leftSensor?.sensorType === Config.DataType.DataType.boolean) {
            leftSensorData = _.flatMap(leftSensorData, invert)
        }
    }
    if (subvariableData?.isReverseRight === true) {
        if (rightSensor?.sensorType === Config.DataType.DataType.boolean) {
            rightSensorData = _.flatMap(rightSensorData, invert)
        }
    }

    const dataAfterResolve = _.map(
        leftSensorData,
        (leftSensorElement, index) => {
            return {
                x: leftSensorElement.x,
                y: MathematicResolver(
                    operation,
                    leftSensorElement?.y,
                    rightSensorData[index]?.y
                ),
            }
        }
    )

    console.log(' Data After Resolve the Equation', dataAfterResolve)
    return dataAfterResolve
}
