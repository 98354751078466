import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import IndexNavbar from "components/common/Navbars/IndexNavbar.js";
import Footer from "components/common/Footers/Footer.js";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../redux/actions";
import SpinnerComponent from "../components/Loading/SpinnerLoading";

export default function Index() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const system = useSelector((state) => state.system);
  const me = useSelector((state) => state.me);
  const storage_remember = JSON.parse(window.localStorage.eiot_remember);
  useEffect(() => {
    dispatch(actions.systemInfoGet()).then(() => {
      dispatch(actions.meGet(storage_remember.uid));
      dispatch(actions.meGet(storage_remember.uid)).then(() => {
      setIsLoading(true);
      });
    });
    return () => {};
  }, []);
  if (isLoading)
    return (
      <>
        <IndexNavbar fixed />
        <section className="container mx-auto items-center flex flex-wrap min-h-screen font-sans">
          <div className="w-full md:w-6/12 px-4 mr-auto ml-auto mt-20">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
              {system.enable_dashboard && (
                <div className="my-4  px-4">
                  <Link to={"/dashboards"}>
                    <div className="bg-blue-500 shadow-lg rounded-lg text-center py-8 px-4 lg:h-40">
                      <i className="fas fa-chart-area fa-3x text-white"></i>
                      <p className="text-lg text-white mt-4 font-semibold">
                        เฝ้าระวังและตรวจสอบระบบ
                      </p>
                    </div>
                  </Link>
                </div>
              )}

              {system.enable_statistics && (
                <div className="my-4  px-4">
                  <Link to={"/statistics"}>
                    <div className="bg-blue-800 shadow-lg rounded-lg text-center py-8 px-4 lg:h-40 ">
                      <i className="fas fa-database fa-3x text-white"></i>
                      <p className="text-lg text-white mt-4 font-semibold">
                        ข้อมูลและสถิติ
                      </p>
                    </div>
                  </Link>
                </div>
              )}
              {system.enable_controls && (
                <div className="my-4  px-4">
                  <Link to={"/controls"}>
                    <div className="bg-yellow-500 shadow-lg rounded-lg text-center  py-8 px-4 lg:h-40 ">
                      <i className="fas fa-calculator fa-3x text-white"></i>
                      <p className="text-lg text-white mt-4 font-semibold">
                        การควบคุม
                      </p>
                    </div>
                  </Link>
                </div>
              )}
              {system.enable_ebe && (
                <div className="my-4  px-4">
                  <Link to={"/electric-bill-estimator"}>
                    {" "}
                    {/* Electric Bill Estimator  */}
                    <div className="bg-purple-500 shadow-lg rounded-lg text-center  py-8 px-4 lg:h-40 ">
                      <i className="fas fa-file-invoice fa-3x text-white"></i>
                      <p className="text-lg text-white mt-4 font-semibold">
                        ประมาณการบิลค่าไฟฟ้า
                      </p>
                    </div>
                  </Link>
                </div>
              )}

              {system.enable_notify_module && (
                <div className="my-4  px-4">
                  <Link to={"/notify"}>
                    {" "}
                    <div className="bg-green-500 shadow-lg rounded-lg text-center  py-8 px-4 lg:h-40 ">
                      <i className="fas fa-bell fa-3x text-white"></i>
                      <p className="text-lg text-white mt-4 font-semibold">
                        การแจ้งเตือน
                      </p>
                    </div>
                  </Link>
                </div>
              )}
              {me.role === "SUPERUSER" && (
                <div className="my-4  px-4">
                  {" "}
                  <Link to={"/system"}>
                    <div className=" bg-gray-800 shadow-lg rounded-lg text-center  py-8 px-4 lg:h-40 ">
                      <i className="fas fa-cogs fa-3x text-white"></i>
                      <p className="text-lg text-white mt-4 font-semibold">
                        จัดการระบบ
                      </p>
                    </div>
                  </Link>
                </div>
              )}
            </div>
          </div>
        </section>
        <Footer />
      </>
    );
  else {
    return <SpinnerComponent />;
  }
}
