import StaticBoolean from './StaticBooleanDisplay'
import StaticMeter from './StaticMeter'
import StaticNumber from './StaticNumberDisplay'
import StaticString from './StaticStringDisplay'
import StaticBooleanWater from './StaticBooleanWaterDisplay'

const StaticGauge = {
    StaticBoolean,
    StaticMeter,
    StaticNumber,
    StaticString,
    StaticBooleanWater,
}
export default StaticGauge
