import React from 'react'
import { Box } from '@chakra-ui/react'
import _ from 'lodash'

import RenderAttributeComponent from '../Function/RenderAttributeComponent'

export default function DiagramComponentDispaly({
    diagramElement,
    stationData,
    idRef,
}) {
    let displaySize
    switch (diagramElement.imageSize) {
        case 0:
            displaySize = 8
            break
        case 20:
            displaySize = 12
            break
        case 40:
            displaySize = 24
            break
        case 60:
            displaySize = 48
            break
        case 80:
            displaySize = 72
            break
        case 100:
            displaySize = 96
            break
        default:
            break
    }

    return (
        <div className="relative  w-24 grid grid-rows-4 mb-0 mt-1">
            <div className=" row-start-1 row-span-2 h-full flex flex-row  ">
                <img
                    src={diagramElement.diagramImage}
                    className={`h-${displaySize} self-end `}
                    id={idRef}
                />
            </div>

            <Box
                borderRadius="lg"
                padding="4"
                borderColor="gray"
                className="w-32 bg-gray-100 border-gray-500  border my-2 row-start-3 row-end-4  z-30"
            >
                {diagramElement.diagramName}
                <br />
                {_.map(diagramElement.attribute, (eachAttribute, index) => (
                    <RenderAttributeComponent
                        key={index}
                        attributeData={eachAttribute}
                        stationData={stationData}
                    />
                ))}
            </Box>
        </div>
    )
}
