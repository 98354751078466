import _ from 'lodash'
export default function FetchingGraphData(dataArr, sensorId) {
  let tempValueStack = []
  // console.log('sensor Id ', sensorId)
  _.map(dataArr, (dataItem) => {
    _.map(dataItem.value, (sensor) => {
      // console.log('SensorID', sensor.sensorId)
      // console.log('SensorId is', sensorId)
      if (sensor.sensorId === sensorId) {
        // console.log('It is Matching !!!!!!!!!!!!!!!!!!!!!!!!!!!!!')
        // console.log('XAxis Data', dataItem.date)
        // console.log('YAXis Data', dataItem.value)
        tempValueStack.push({
          x: dataItem.date,
          y: sensor.value,
        })
      }
    })
  })
  return tempValueStack
}
