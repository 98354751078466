import React from 'react'
import {
    Box,
    Button,
    Divider,
    Flex,
    ListItem,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    UnorderedList,
    Wrap,
    WrapItem,
} from '@chakra-ui/react'
import _ from 'lodash'
import * as actions from '../../../../redux/actions'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

export default function ModalStationCreateConfirmation({
    isOpen,
    onClose,
    templateId,
    formData,
}) {
    const stationTemplate = useSelector((state) => state.stationTemplate)
    const templateList = stationTemplate.arr
    const dispatch = useDispatch()
    const history = useHistory()

    let selectedTemplate = _.find(
        templateList,
        (temp) => temp._id === templateId
    )

    const handleConfirmation = () => {
        const createRequestData = _.merge(formData, selectedTemplate)
        //console.log(createRequestData)
        dispatch(actions.stationPost(createRequestData)).then(() => {
            dispatch(actions.stationAll())
        })
        onClose()
        history.push('/system/stations/')
    }
    console.log(formData)
    return (
        <Modal isCentered isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader className="font-sans">
                    ยืนยันการสร้างสถานี
                </ModalHeader>

                <ModalBody>
                    {selectedTemplate ? (
                        <>
                            <h5 className="text-xl font-bold font-sans">
                                {selectedTemplate.name}
                            </h5>
                            <p className="text-sm text-gray-500">
                                {' '}
                                {selectedTemplate.description}{' '}
                            </p>
                            <div className="flex flex-wrap justify-center">
                                <div className="w-8/12 sm:w-4/12 px-4">
                                    <img
                                        src={selectedTemplate.illustrator}
                                        alt="..."
                                        className=" shadow rounded max-w-full  h-150-px w-auto align-middle border-none"
                                    />
                                </div>
                            </div>
                            <br />

                            <UnorderedList>
                                <div className="h-24 overflow-y-auto ">
                                    {_.map(
                                        selectedTemplate.sensors,
                                        (sensor, index) => (
                                            <ListItem
                                                className="mb-2"
                                                key={index}
                                            >
                                                <strong className="mr-1 ">
                                                    {' '}
                                                    {sensor.name}
                                                </strong>
                                                <br />
                                                <p className="text-sm">
                                                    {sensor.description}
                                                </p>
                                            </ListItem>
                                        )
                                    )}
                                </div>
                            </UnorderedList>
                        </>
                    ) : (
                        <>
                            <p>ไม่ได้เลือกแม่แบบ</p>
                        </>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button
                        colorScheme="blue"
                        type="button"
                        onClick={() => handleConfirmation()}
                    >
                        ยืนยัน
                    </Button>
                    <Button colorScheme="gray" type="button" onClick={onClose}>
                        ยกเลิก
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}
