import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import * as actions from "../../../redux/actions"
import { Link } from "react-router-dom"
import _ from "lodash"

// components
import { Button } from "@chakra-ui/react"
import SystemDataTemplate from "../../../template/SystemInfoTemplate.json"
import SpinnerLoading from "components/Loading/SpinnerLoading"
import EditSystemInfo from "./Components/EditSystemInfo"

export default function ManagementSystem() {
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()
  const systemInfo = useSelector((state) => state.system)

  useEffect(() => {
    dispatch(actions.systemInfoGet()).then(() => {
      setIsLoading(true)
    })

    return () => {}
  }, [])

  const uploadBootstrapTemplate = () => {
    //console.log(SystemDataTemplate);
    dispatch(actions.systemInfoPost(SystemDataTemplate))
  }

  return isLoading ? (
    <div className='relative flex flex-col min-w-0 break-words bg-gray-100 w-full mb-6 shadow-lg rounded'>
      <div className='rounded-t mb-0 px-4 py-3 bg-transparent'>
        <div className='flex flex-wrap items-center'>
          <div className='relative w-full max-w-full flex-grow flex-1'>
            <h6 className='uppercase text-gray-500 mb-1 text-xs font-semibold font-sans'>
              จัดการระบบ
            </h6>
            <h2 className='text-gray-800 text-2xl font-bold font-sans'>
              จัดการข้อมูลระบบ
            </h2>
          </div>
        </div>
      </div>
      <div className='p-4 w-full'>
        {systemInfo && systemInfo.name !== undefined ? (
          <>
            <EditSystemInfo systemInfo={systemInfo} />
          </>
        ) : (
          <>
            <div className=' grid place-content-center place-items-center w-full p-5 '>
              <div className='box-border  border border-gray-500 w-full p-12 text-center  rounded-lg '>
                <h2 className=' text-gray-700 text-xl font-sans font-semibold'>
                  คุณยังไม่มีข้อมูลระบบ
                </h2>
                <p className='text-lg font-sans '>
                  ต้องการเริ่มต้นตั้งค่าระบบจากแม่แบบหรือไม่
                </p>
                <Button
                  colorScheme='blue'
                  onClick={() => uploadBootstrapTemplate()}
                >
                  ยืนยัน
                </Button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  ) : (
    <SpinnerLoading />
  )
}
