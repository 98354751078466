import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import * as actions from '../../redux/actions'
import { Link, useParams, useHistory } from 'react-router-dom'
// components
import { Button } from '@chakra-ui/react'
import SpinnerLoading from 'components/Loading/SpinnerLoading'
import StationCanvas from '../../components/Diagram/Canvas/GeneralStationCanvas'
import _ from 'lodash'

export default function NewEachStationDiagram({
    sidebarStatus,
    setIsShowSidebar,
}) {
    const [isLoading, setIsLoading] = useState(false)
    const [currentStation, setcurrentStation] = useState()
    const [isFetchNewOne, setisFetchNewOne] = useState(true)
    const dispatch = useDispatch()
    const params = useParams()
    const allStations = useSelector((state) => state.station)
    const me = useSelector((state) => state.me)
    const history = useHistory()
    useEffect(() => {
        if (me && me._id && currentStation) {
            if (me.duty === 'ALLAREA' || me.duty === currentStation._id) {
            } else {
                console.log('ผู้ใช้ไม่มีสิทธิเข้าใช้')
                history.push('/dashboards')
            }
        }
        return () => { }
    }, [me, currentStation])

    useEffect(() => {
        if (isFetchNewOne === true) {
            dispatch(actions.stationAll())
            //FIXME: Uncommen to Enable Auto Refresh
            setisFetchNewOne(false)
        }
        return () => { }
    }, [isFetchNewOne])

    useEffect(() => {
        if (allStations) {
            const tempcurrentStatus = _.find(
                allStations.arr,
                (station) => station._id === params.stationId
            )
            setcurrentStation(tempcurrentStatus)
            setIsLoading(true)
            //console.log("Curent Station", currentStation);
        }
        return () => { }
    }, [params, allStations])

    useEffect(() => {
        if (isFetchNewOne === false) {
            setTimeout(() => {
                setisFetchNewOne(true)
            }, 30000)
        }
        return () => { }
    }, [isFetchNewOne])

    return isLoading && currentStation ? (
        <div className="relative flex flex-col min-w-0 break-words bg-gray-100 w-full mb-6 shadow-lg rounded">
            <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
                <div className="flex flex-wrap items-center">
                    <div className="relative w-full max-w-full flex-grow flex-1">
                        <h6 className="uppercase text-gray-500 mb-1 text-xs font-semibold font-sans">
                            แผนผังการทำงาน
                        </h6>
                        <h2 className="text-gray-800 text-2xl font-bold  font-sans">
                            แผนผังการทำงาน
                            {currentStation.name}
                        </h2>
                        <p className="text-base text-gray-500 mb-1 font-sans  ">
                            {currentStation.location}
                        </p>
                    </div>
                </div>
            </div>
            <div className="flex justify-between p-4 ">
                <div className="flex justify-items-start"></div>
                <div className="flex justify-items-end">
                    <Button
                        colorScheme="gray"
                        variant="solid"
                        size="sm"
                        onClick={() => setIsShowSidebar(!sidebarStatus)}
                    >
                        เปิด/ปิดแถบข้าง
                    </Button>
                </div>
            </div>
            <div className="p-4 w-full" style={{ overflow: 'auto' }}>
                <div style={{ minWidth: '1024px' }}>
                    <StationCanvas stationData={currentStation} />
                </div>
            </div>
        </div>
    ) : (
            <SpinnerLoading />
        )
}
