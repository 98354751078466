export const GaugeType = {
    MultiStepBooleanWaterDisplay: 'MultiStepBooleanWaterDisplay',
    StaticMeter: 'Meter Display',
    StaticBoolean: 'Boolean Display',
    StaticNumber: 'Number Display',
    StaticString: 'String Display',
    StaticBooleanWater: 'Boolean Water Display',
    NoDisplay: 'NoDisplay',

    // Deprecate
    Meter: 'Meter',
    BooleanDisplay: 'BooleanDisplay',
    NumberDisplay: 'NumberDisplay',
    StringDisplay: 'StringDisplay',
    BooleanWaterDisplay: 'BooleanWaterDisplay',

    // Readonly
    MultiStepBooleanDisplay: 'MultiStepBooleanDisplay',
    MultiStepNumberDisplay: 'MultiStepNumberDisplay',
}
