import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../redux/actions";
import { Link } from "react-router-dom";
import _ from "lodash";

// components

import SpinnerLoading from "components/Loading/SpinnerLoading";
import EditNotify from "./components/EditNotify";

export default function ManagementNotify() {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const systemInfo = useSelector((state) => state.system);

  useEffect(() => {
    dispatch(actions.systemInfoGet()).then(() => {
      setIsLoading(true);
    });
    return () => {};
  }, []);

  return isLoading ? (
    <div className="relative flex flex-col min-w-0 break-words bg-gray-100 w-full mb-6 shadow-lg rounded">
      <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
        <div className="flex flex-wrap items-center">
          <div className="relative w-full max-w-full flex-grow flex-1">
            <h6 className="uppercase text-gray-500 mb-1 text-xs font-semibold font-sans">
              จัดการระบบ
            </h6>
            <h2 className="text-gray-800 text-2xl font-bold font-sans">
              จัดการการแจ้งเตือน
            </h2>
          </div>
        </div>
      </div>
      <div className="p-4 w-full">
        {systemInfo && systemInfo.name !== undefined ? (
          <>
            <EditNotify systemInfo={systemInfo} />
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  ) : (
    <SpinnerLoading />
  );
}
