import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import _ from 'lodash'
import { Button } from '@chakra-ui/react'
import { useSelector, useDispatch } from 'react-redux'
import SpinnerLoading from 'components/Loading/SpinnerLoading'

import { useParams, useHistory } from 'react-router-dom'
import * as actions from '../../../redux/actions'

export default function AddSensorInTemplate() {
  const { register, handleSubmit, reset } = useForm()
  const [sensorType, setsensorType] = useState('BOOLEAN')
  const [isLoading, setIsLoading] = useState(false)
  const params = useParams()
  const template = useSelector((state) => state.stationTemplate)
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    dispatch(actions.stationTemplateGet(params.templateId))
    return () => {}
  }, [params])

  useEffect(() => {
    setIsLoading(true)
    return () => {}
  }, [])

  const onSubmit = async (data, e) => {
    let confirm = window.confirm('ยืนยันการเพิ่มเซ็นเซอร์')
    if (confirm) {
      e.preventDefault()
      console.log('From Data', data)
      let templateValue = template

      if (templateValue && templateValue.sensors) {
        data.controlRefString = data.refString ; 
        console.log(templateValue.sensors)
        templateValue.sensors.push(data)
        console.log('PUSHED')
      }

      console.log('New Added Value ', templateValue)
      dispatch(actions.stationTemplatePut(params.templateId, templateValue))
      history.goBack()
    }
  }

  if (template && isLoading) {
    return (
      <>
        <div className="container mx-auto px-4 h-full">
          <div className="flex content-center items-center justify-center h-full">
            <div className="w-full lg:w-6/12 px-4">
              <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-100 border-0">
                <div className="rounded-t mb-0 px-6 py-6">
                  <div className="text-center mb-3">
                    <h6 className="text-gray-600 text-base font-sans font-bold ">
                      เพิ่มเซ็นเซอร์ลงในแม่แบบ {template.templateName}
                    </h6>
                  </div>
                  <hr className="mt-6 border-b-1 border-gray-400" />
                </div>
                <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <h6 className="text-gray-500 text-base mt-3 mb-6 font-bold uppercase font-sans ">
                      ข้อมูลเซ็นเซอร์
                    </h6>
                    <div className="flex flex-wrap">
                      <div className="w-full px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            ชื่อเซ็นเซอร์
                          </label>
                          <input
                            name="name"
                            type="text"
                            required
                            ref={register}
                            className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                            placeholder="เซ็นเซอร์วัดระดับน้ำฝน"
                          />
                        </div>
                      </div>

                      <div className="w-full px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            เกี่ยวกับเซ็นเซอร์
                          </label>
                          <input
                            name="description"
                            type="text"
                            ref={register}
                            className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                            placeholder="เซ็นเซอร์วัดระดับน้ำฝนที่ตกลงมา ปักกลางแดด"
                          />
                        </div>
                      </div>

                      <div className="w-full px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            ไอดีของเซ็นเซอร์
                          </label>
                          <label
                            className="block  text-gray-500 text-xs font-normal mb-2"
                            htmlFor="grid-password"
                          >
                            สำหรับการลิงค์ในไดอะแกรม
                          </label>
                          <input
                            name="id"
                            type="text"
                            required
                            ref={register}
                            className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                            placeholder="00001"
                          />
                        </div>
                      </div>

                      <div className="w-full px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            ชื่อของตัวแปรอ้างอิง
                          </label>
                          <label
                            className="block  text-gray-500 text-xs font-normal mb-2"
                            htmlFor="grid-password"
                          >
                            เป็นข้อมูลทางเทคนิคที่ใช้เชื่อมต่อกับระบบ IoT
                            Realtime Database
                          </label>
                          <input
                            name="refString"
                            type="text"
                            ref={register}
                            className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                            placeholder="rain1"
                          />
                        </div>
                      </div>

                      <div className="w-full  px-4">
                        <div className="relative w-full  mb-3">
                          <label
                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            รูปแบบเซ็นเซอร์
                          </label>
                          <select
                            name="sensorType"
                            required
                            ref={register}
                            onChange={(event) =>
                              setsensorType(event.target.value)
                            }
                            className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                          >
                            <option value="BOOLEAN">BOOLEAN</option>
                            <option value="NUMBER">NUMBER</option>
                            <option value="STRING">STRING</option>
                          </select>
                        </div>
                      </div>

                      <div className="w-full  px-4">
                        <div className="relative w-full  mb-3">
                          <label
                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            รูปแบบย่อยของเซ็นเซอร์
                          </label>
                          <select
                            name="gaugeType"
                            required
                            ref={register}
                            className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                          >
                            <option value="OnOffGauge">เซ็นเซอร์เปิดปิด</option>
                            <option value="TriggerGague">
                              เซ็นเซอร์ Trigger
                            </option>
                            <option value="WaterLevelGauge">
                              เซ็นเซอร์ระดับน้ำ
                            </option>
                            <option value="AmpMeter">
                              เซ็นเซอร์วัดกระแสไฟฟ้า
                            </option>
                            <option value="NumberOnly">เซ็นเซอร์นับเลข</option>
                            <option value="Barometer">
                              เซ็นเซอร์วัดความดัน
                            </option>
                            <option value="FlowGague">
                              เซ็นเซอร์วัดการไหลของน้ำ
                            </option>
                            <option value="StatusOnlyOnOff">
                              เซ็นเซอร์แสดงสถานะเปิดปิด
                            </option>
                            <option value="MajorControl">
                              เซ็นเซอร์อนุญาติให้ควบคุมเซ็นเซอร์อื่น ๆ
                            </option>
                            <option value="NoDisplay">ไม่มีการแสดงค่า</option>
                          </select>
                        </div>
                      </div>

                      <div className="w-full  px-4">
                        <div className="relative w-full  mb-3">
                          <label
                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            รูปแบบกราฟที่ใช้แสดงผล
                          </label>
                          <select
                            name="chartType"
                            required
                            ref={register}
                            className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                          >
                            <option value="AreaRealtimeGraph">
                              กราฟเส้นทั่วไป
                            </option>
                            <option value="BaroGraph">กราฟความดัน</option>
                            <option value="AmpGraph">กราฟกระแสไฟฟ้า</option>
                            <option value="NoDisplay">ไม่แสดง</option>
                          </select>
                        </div>
                      </div>
                      {/** End of  General Input */}
                      {/** Specific Input */}
                      {sensorType === 'BOOLEAN' && (
                        <>
                          {/* <div className="w-full px-4">
                            <div className="relative w-full mb-3">
                              <label
                                className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                htmlFor="grid-password"
                              >
                                ชื่อของตัวแปรควบคุมเซ็นเซอร์ในอุปกรณ์ (ถ้ามี)
                              </label>
                              <label
                                className="block  text-gray-500 text-xs font-normal mb-2"
                                htmlFor="grid-password"
                              >
                                เป็นข้อมูลทางเทคนิคที่ใช้เชื่อมต่อกับระบบ IoT
                                Realtime Database
                              </label>
                              <input
                                name="controlRefString"
                                type="text"
                                ref={register}
                                className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                placeholder="rain1_control"
                              />
                            </div>
                          </div> */}
                          <div className="w-full  px-4">
                            <div className="relative w-full  mb-3">
                              <label
                                className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                htmlFor="grid-password"
                              >
                                การควบคุมโดยใช้ Pulse
                              </label>
                              <select
                                name="controlWithPulse"
                                required
                                ref={register}
                                className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                              >
                                {' '}
                                <option value={false}>ไม่ใช่</option>
                                <option value={true}>ใช่</option>
                              </select>
                            </div>
                          </div>
                        </>
                      )}
                      {sensorType === 'NUMBER' && (
                        <>
                          <div className="w-full px-4">
                            <div className="relative w-full mb-3">
                              <label
                                className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                htmlFor="grid-password"
                              >
                                ค่าที่ได้จากเซ็นเซอร์
                              </label>

                              <input
                                name="valueNote"
                                type="text"
                                ref={register}
                                className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                placeholder="ปริมาณน้ำฝน"
                              />
                            </div>
                          </div>
                          <div className="w-full px-4">
                            <div className="relative w-full mb-3">
                              <label
                                className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                htmlFor="grid-password"
                              >
                                หน่วยของค่าที่ได้
                              </label>

                              <input
                                name="valueUnit"
                                type="text"
                                ref={register}
                                className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                placeholder="ลูกบาศก์เซ็นติเมตร"
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </div>

                    <div className="text-center px-4 gap-0 py-3 ">
                      <Button
                        isFullWidth
                        colorScheme="blue"
                        variant="solid"
                        type="submit"
                      >
                        บันทึก{' '}
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  } else {
    return <SpinnerLoading />
  }
}
