import _ from 'lodash'
import MathematicResolver from '../../../../../util/LogicResolve'
import FetchingGraphData from '../../FetchingGraphData'
import Config from '../../../../../config'
// It work only 2 sensors
export default function SensorAndVariableDataFinding(
    stationData,
    subvariableData,
    dataArr
) {
    // Define Invert Function
    const invert = (element) => {
        return { x: element.x, y: !element.y }
    }

    // Find Right Sensor
    const rightSensor = _.find(
        stationData?.sensors,
        (sensor) => sensor?.refString === subvariableData?.rightSensorRef
    )

    let rightSensorData = FetchingGraphData(dataArr, rightSensor?._id)

    // Find Control
    const operation = subvariableData.operation
    if (subvariableData.isReverseRight === true) {
        if (rightSensor?.sensorType === Config.DataType.DataType.boolean) {
            rightSensorData = _.flatMap(rightSensorData, invert)
        }
    }

    // Find constant on the left
    const leftConstant = subvariableData.constantValue

    const dataAfterResolve = _.map(
        rightSensorData,
        (rightSensorElement, index) => {
            return {
                x: rightSensorElement?.x,
                y: MathematicResolver(
                    operation,
                    rightSensorElement?.y,
                    leftConstant
                ),
            }
        }
    )

    console.log('ค่าหลังจาก Resolve Equation ', dataAfterResolve)
    return dataAfterResolve
}
