import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import _ from 'lodash'
import { Button, FormControl, FormLabel, Switch } from '@chakra-ui/react'
import { useSelector, useDispatch } from 'react-redux'
import SpinnerLoading from 'components/Loading/SpinnerLoading'
import GaugeTypeData from 'config/GaugeType.json'
import ChartTypeData from 'config/ChartType.json'

import { useParams, useHistory } from 'react-router-dom'
import * as actions from '../../../../redux/actions'

export default function AddSensor() {
    const { register, handleSubmit, reset } = useForm()
    const [sensorType, setsensorType] = useState('BOOLEAN')
    const [isLoading, setIsLoading] = useState(false)
    const params = useParams()
    const station = useSelector((state) => state.station)
    const dispatch = useDispatch()
    const history = useHistory()

    useEffect(() => {
        dispatch(actions.stationGet(params.stationId))
        return () => {}
    }, [params])

    useEffect(() => {
        setIsLoading(true)
        return () => {}
    }, [])

    const onSubmit = async (data, e) => {
        let confirm = window.confirm('ยืนยันการเพิ่มเซ็นเซอร์')
        if (confirm) {
            e.preventDefault()
            console.log('From Data', data)
            let templateValue = station

            if (templateValue && templateValue.sensors) {
                data.controlRefString = data.refString
                console.log(templateValue.sensors)
                templateValue.sensors.push(data)
                console.log('PUSHED')
            }
            console.log('New Added Value ', templateValue)
            dispatch(actions.stationPut(params.stationId, templateValue)).then(
                () => {
                    dispatch(actions.stationAll()).then(() => {
                        history.goBack()
                    })
                }
            )
        }
    }

    if (station && isLoading) {
        return (
            <>
                <div className="container mx-auto px-4 h-full">
                    <div className="flex content-center items-center justify-center h-full">
                        <div className="w-full lg:w-6/12 px-4">
                            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-100 border-0">
                                <div className="rounded-t mb-0 px-6 py-6">
                                    <div className="text-center mb-3">
                                        <h6 className="text-gray-600 text-base font-bold font-sans">
                                            เพิ่มเซ็นเซอร์ลงในสถานี{' '}
                                            {station.name}
                                        </h6>
                                    </div>
                                    <hr className="mt-6 border-b-1 border-gray-400" />
                                </div>
                                <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <h6 className="text-gray-500 text-base mt-3 mb-6 font-bold uppercase font-sans ">
                                            ข้อมูลเซ็นเซอร์
                                        </h6>
                                        <div className="flex flex-wrap">
                                            <div className="w-full px-4">
                                                <div className="relative w-full mb-3">
                                                    <label
                                                        className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                                        htmlFor="grid-password"
                                                    >
                                                        ชื่อเซ็นเซอร์
                                                    </label>
                                                    <input
                                                        name="name"
                                                        type="text"
                                                        required
                                                        ref={register}
                                                        className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                                        placeholder="เซ็นเซอร์วัดระดับน้ำฝน"
                                                    />
                                                </div>
                                            </div>

                                            <div className="w-full px-4">
                                                <div className="relative w-full mb-3">
                                                    <label
                                                        className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                                        htmlFor="grid-password"
                                                    >
                                                        เกี่ยวกับเซ็นเซอร์
                                                    </label>
                                                    <input
                                                        name="description"
                                                        type="text"
                                                        ref={register}
                                                        className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                                        placeholder="เซ็นเซอร์วัดระดับน้ำฝนที่ตกลงมา ปักกลางแดด"
                                                    />
                                                </div>
                                            </div>

                                            <div className="w-full px-4">
                                                <div className="relative w-full mb-3">
                                                    <label
                                                        className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                                        htmlFor="grid-password"
                                                    >
                                                        ชื่อของตัวแปรอ้างอิง
                                                    </label>
                                                    <label
                                                        className="block  text-gray-500 text-xs font-normal mb-2"
                                                        htmlFor="grid-password"
                                                    >
                                                        เป็นข้อมูลทางเทคนิคที่ใช้เชื่อมต่อกับระบบ
                                                        IoT Database
                                                    </label>
                                                    <input
                                                        name="refString"
                                                        type="text"
                                                        ref={register}
                                                        className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                                        placeholder="rain1"
                                                    />
                                                </div>
                                            </div>

                                            <div className="w-full  px-4">
                                                <div className="relative w-full  mb-3">
                                                    <label
                                                        className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                                        htmlFor="grid-password"
                                                    >
                                                        รูปแบบเซ็นเซอร์
                                                    </label>
                                                    <select
                                                        name="sensorType"
                                                        required
                                                        ref={register}
                                                        onChange={(event) =>
                                                            setsensorType(
                                                                event.target
                                                                    .value
                                                            )
                                                        }
                                                        className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                                    >
                                                        <option value="BOOLEAN">
                                                            BOOLEAN
                                                        </option>
                                                        <option value="NUMBER">
                                                            NUMBER
                                                        </option>
                                                        <option value="STRING">
                                                            STRING
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>

                                            {/** End of  General Input */}
                                            {/** Specific Input
                                             * 2020/02/17 ปรับให้มันแยกเพิ่มอีกว่าส่วนไหนให้แสดงเฉพาะ Type ที่เป็น Boolean ส่วนไหนแสดงเฉพาะ Number มันจะได้ไม่มั่ว
                                             *
                                             */}
                                            {sensorType === 'BOOLEAN' && (
                                                <>
                                                    <div className="w-full  px-4">
                                                        <div className="relative w-full  mb-3">
                                                            <label
                                                                className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                                                htmlFor="grid-password"
                                                            >
                                                                รูปแบบย่อยของเซ็นเซอร์
                                                            </label>
                                                            <label
                                                                className="block  text-gray-500 text-xs font-normal mb-2"
                                                                htmlFor="grid-password"
                                                            >
                                                                จะมีผลต่อการที่เซ็นเซอร์ตัวนี้จะรับค่าในรูปแบบใด
                                                                จะควบคุมได้หรือไม่
                                                            </label>
                                                            <select
                                                                name="gaugeType"
                                                                required
                                                                ref={register}
                                                                className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                                            >
                                                                {_.map(
                                                                    GaugeTypeData,
                                                                    (
                                                                        eachGaugeType,
                                                                        index
                                                                    ) => (
                                                                        <option
                                                                            key={
                                                                                index
                                                                            }
                                                                            value={
                                                                                eachGaugeType.value
                                                                            }
                                                                        >
                                                                            {
                                                                                eachGaugeType.describe
                                                                            }{' '}
                                                                        </option>
                                                                    )
                                                                )}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="w-full  px-4">
                                                        <div className="relative w-full  mb-3">
                                                            <label
                                                                className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                                                htmlFor="grid-password"
                                                            >
                                                                การควบคุมโดยใช้
                                                                Pulse
                                                            </label>
                                                            <label
                                                                className="block  text-gray-500 text-xs font-normal mb-2"
                                                                htmlFor="grid-password"
                                                            ></label>
                                                            <select
                                                                name="controlWithPulse"
                                                                required
                                                                ref={register}
                                                                className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                                            >
                                                                {' '}
                                                                <option
                                                                    value={
                                                                        false
                                                                    }
                                                                >
                                                                    ไม่ใช่
                                                                </option>
                                                                <option
                                                                    value={true}
                                                                >
                                                                    ใช่
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="w-full  px-4">
                                                        <div className="relative w-full  mb-3">
                                                            <label
                                                                className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                                                htmlFor="grid-password"
                                                            >
                                                                ค่าเริ่มต้น
                                                            </label>
                                                            <select
                                                                name="booleanDefaultValue"
                                                                defaultValue={
                                                                    false
                                                                }
                                                                ref={register}
                                                                className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                                            >
                                                                <option
                                                                    value={
                                                                        false
                                                                    }
                                                                >
                                                                    False
                                                                </option>
                                                                <option
                                                                    value={true}
                                                                >
                                                                    True
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </>
                                            )}

                                            {sensorType === 'NUMBER' && (
                                                <>
                                                    {/* <div className="w-full  px-4">
                            <div className="relative w-full  mb-3">
                              <label
                                className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                htmlFor="grid-password"
                              >
                                รูปแบบกราฟที่ใช้แสดงผล
                              </label>
                              <select
                                name="chartType"
                                required
                                ref={register}
                                className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                              >
                                {_.map(
                                  ChartTypeData,
                                  (eachChartType, index) => (
                                    <option
                                      value={eachChartType.value}
                                      key={index}
                                    >
                                      {" "}
                                      {eachChartType.describe}{" "}
                                    </option>
                                  )
                                )}
                              </select>
                            </div>
                          </div> */}
                                                    {/* <div className="w-full px-4">
                            <div className="relative w-full mb-3">
                              <label
                                className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                htmlFor="grid-password"
                              >
                                ค่าที่ได้จากเซ็นเซอร์
                              </label>
                              <label
                                className="block  text-gray-500 text-xs font-normal mb-2"
                                htmlFor="grid-password"
                              >
                                จะเป็น Label ที่จะบ่งชี้ค่านี้ในกราฟ
                              </label>
                              <input
                                name="valueNote"
                                type="text"
                                ref={register}
                                className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                placeholder="เช่น ถ้าหากเป็นเซ็นเซอร์วัดปริมาณน้ำฝน ค่าที่ได้อาจจะเป็นปริมาณน้ำฝน"
                              />
                            </div>
                          </div>*/}
                                                    <div className="w-full px-4">
                                                        <div className="relative w-full mb-3">
                                                            <label
                                                                className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                                                htmlFor="grid-password"
                                                            >
                                                                หน่วยของค่าที่ได้
                                                            </label>

                                                            <input
                                                                name="valueUnit"
                                                                type="text"
                                                                ref={register}
                                                                className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                                                placeholder="ลูกบาศก์เซ็นติเมตร"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="w-full px-4 py-4">
                                                        <FormControl display="flex">
                                                            <FormLabel>
                                                                <div className="block uppercase text-gray-700 text-sm font-bold mb-2">
                                                                    เปิด/ปิด
                                                                    ใช้ค่าเทรสโฮลด์
                                                                </div>
                                                            </FormLabel>
                                                            <Switch
                                                                size="lg"
                                                                name="enableThreshold"
                                                                ref={register}
                                                            />
                                                        </FormControl>
                                                    </div>
                                                    <div className="w-full px-4">
                                                        <div className="relative w-full mb-3">
                                                            <label
                                                                className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                                                htmlFor="grid-password"
                                                            >
                                                                ค่าเทรชโฮลด์ด้านบน
                                                            </label>
                                                            <label
                                                                className="block  text-gray-500 text-xs font-normal mb-2"
                                                                htmlFor="grid-password"
                                                            >
                                                                หากเซ็นเซอร์มีค่าเกินกว่าค่าเทรสโฮลด์ที่กำหนดจะมีการแจ้งเตือนแก่ผู้ดูแลระบบ
                                                            </label>

                                                            <input
                                                                name="upperThresholdValue"
                                                                type="text"
                                                                ref={register}
                                                                className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                                                placeholder="ระบุค่าเป็น 200  หมายถึงระบบจะมีการแจ้งเตือนเมื่อเซ็นเซอร์มีการวัดปริมาณน้ำฝนมากกว่า 200 ลูกบาศก์เซ็นติเมตร"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="w-full px-4">
                                                        <div className="relative w-full mb-3">
                                                            <label
                                                                className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                                                htmlFor="grid-password"
                                                            >
                                                                ค่าเทรชโฮลด์ด้านล่าง
                                                            </label>
                                                            <label
                                                                className="block  text-gray-500 text-xs font-normal mb-2"
                                                                htmlFor="grid-password"
                                                            >
                                                                หากเซ็นเซอร์มีค่าต่ำกว่าค่าเทรสโฮลด์ที่กำหนดจะมีการแจ้งเตือนแก่ผู้ดูแลระบบ
                                                            </label>

                                                            <input
                                                                name="lowerThresholdValue"
                                                                type="text"
                                                                ref={register}
                                                                className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                                                placeholder="ระบุค่าเป็น 100  หมายถึงระบบจะมีการแจ้งเตือนเมื่อเซ็นเซอร์มีการวัดปริมาณน้ำฝนน้อยกว่า 100 ลูกบาศก์เซ็นติเมตร"
                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </div>

                                        <div className="text-center px-4 gap-0 py-3 ">
                                            <Button
                                                isFullWidth
                                                colorScheme="blue"
                                                variant="solid"
                                                type="submit"
                                            >
                                                บันทึก{' '}
                                            </Button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    } else {
        return <SpinnerLoading />
    }
}
