import _ from 'lodash'
import { DataType } from '../../../../config/DataType'
import MathematicResolver from '../../../../util/LogicResolve'

// It work only 2 sensors
export default function SensorAndVariableDataFinding(
    stationData,
    subvariableData
) {
    // Find Only Right Sensor left variable must me constant
    const rightSensor = _.find(
        stationData.sensors,
        (sensor) => sensor.refString === subvariableData.rightSensorRef
    )
    let rightSensorData
    if (rightSensor.sensorType === DataType.boolean) {
        rightSensorData = rightSensor.booleanStatus
    } else if (rightSensor.sensorType === DataType.number) {
        rightSensorData = rightSensor.numericStatus
    } else {
        return 0
    }

    // Find Control
    const operation = subvariableData.operation
    if (subvariableData.isReverseRight === true) {
        if (typeof rightSensorData === 'boolean') {
            rightSensorData = !rightSensorData
        }
    }

    // Find constant on the left
    const leftConstant = subvariableData.constantValue

    const dataAfterResolve = MathematicResolver(
        operation,
        leftConstant,
        rightSensorData
    )
    console.log('ค่าหลังจาก Resolve Equation ', dataAfterResolve)
    return dataAfterResolve
}
