import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import * as actions from '../../../redux/actions'
import { Link } from 'react-router-dom'

// components
// import EmployeeLists from "./components/EmployeeLists";
import { Button } from '@chakra-ui/react'
import UserSearch from 'components/Search/UserSearch'
import SpinnerLoading from 'components/Loading/SpinnerLoading'
import _ from 'lodash'
import UserLists from './components/UserLists'

export default function ManagementUsers() {
  const user = useSelector((state) => state.user)
  const [user_data, setUser] = useState(user.arr)
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(actions.userAll()).then(() => {})
    dispatch(actions.stationAll()).then(() => {})
    return () => {}
  }, [])

  useEffect(() => {
    setUser(user.arr)
    setIsLoading(true)
    return () => {}
  }, [user])
  const handleDelete = (id) => {
    dispatch(actions.userDelete(id)).then(() => {
      dispatch(actions.userAll()).then(() => {
        alert('ลบบัญชีผู้ใช้สำเร็จ')
      })
    })
  }
  const handleUpdate = (id, payload) => {
    dispatch(actions.userPut(id, payload)).then(() => {
      alert('แก้ไขบัญชีผู้ใช้สำเร็จ')
      dispatch(actions.userAll()).then(() => {})
    })
  }

  return isLoading ? (
    <div className="relative flex flex-col min-w-0 break-words bg-gray-100 w-full mb-6 shadow-lg rounded">
      <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
        <div className="flex flex-wrap items-center">
          <div className="relative w-full max-w-full flex-grow flex-1">
            <h6 className="uppercase text-gray-500 mb-1 text-xs font-semibold font-sans">
              จัดการระบบ
            </h6>
            <h2 className="text-gray-800 text-2xl font-bold font-sans">
              ผู้ใช้ทั้งหมด
            </h2>
          </div>
        </div>
      </div>
      <div className="flex justify-between p-4">
        <Link to={'/system/user/create'}>
          <Button colorScheme="blue" variant="solid" size="sm">
            เพิ่ม
          </Button>
        </Link>
        <UserSearch
          data={user.arr} //ข้อมูลทั้งหมด
          setData={setUser} // เซ็ต temp data ให้เหลือเท่าที่กรอง
        />
      </div>
      <div className="p-4 w-full">
        {_.size(user_data) ? (
          <div className="overflow-x-auto">
            <UserLists
              user={user.arr}
              handleDelelte={handleDelete}
              handleUpdate={handleUpdate}
            />
          </div>
        ) : (
          <div className="flex justify-center p-4">
            <div>ไม่มีข้อมูล</div>
          </div>
        )}
      </div>
    </div>
  ) : (
    <SpinnerLoading />
  )
}
