import React, { useState } from "react";
import _ from "lodash";
import { Select, Button, Tr, Td } from "@chakra-ui/react";
import {
  BooleanNumericOperation,
  BooleanOperation,
  NumericOperation,
} from "../../../config/MathOperation";

// สำหรับลำดับการดำเนินการ

export default function OperationAddingComponent({
  allSubvariables,
  rendered,
  setRendered,
  operationList,
  setOperationList,
}) {
  const [selectedOperation, setSelectedOperation] = useState("");
  const [leftVariable, setLeftVariable] = useState("");
  const [rightVariable, setRightVariable] = useState("");

  const gaugeVariableList = () =>
    _.map(allSubvariables, (variable) => (
      <>
        <option value={variable.subVariableName}>
          {variable.subVariableName}
        </option>
      </>
    ));

  const gaugeOperation = () => (
    <>
      {_.map(BooleanOperation, (operation, index) => (
        <option value={operation} key={index}>
          {operation}{" "}
        </option>
      ))}
      {_.map(NumericOperation, (operation, index) => (
        <option value={operation} key={index}>
          {operation}{" "}
        </option>
      ))}
      {_.map(BooleanNumericOperation, (operation, index) => (
        <option value={operation} key={index}>
          {operation}{" "}
        </option>
      ))}
    </>
  );

  const handlingAddOperation = () => {
    const tempOperationComponent = {
      leftVariable: _.size(operationList) === 0 ? leftVariable : null,
      rightVariable: rightVariable,
      operation: selectedOperation,
    };
    let tempOperationList = operationList;
    tempOperationList.push(tempOperationComponent);
    setOperationList(tempOperationList);
    console.log(operationList);
    setRendered(!rendered);
  };

  return (
    <Tr>
      <Td></Td>
      <Td>
        {_.size(operationList) === 0 ? (
          <>
            <Select
              className='w-1/3'
              size='sm'
              onChange={(event) => {
                setLeftVariable(event.target.value);
              }}
            >
              <option value=''>ตัวแปร</option>
              {gaugeVariableList()}
            </Select>
          </>
        ) : (
          <>
            <label className='text-sm'>
              ผลจากส่วนที่ {_.size(operationList)}{" "}
            </label>
          </>
        )}
      </Td>
      <Td>
        <Select
          className='w-1/3'
          size='sm'
          onChange={(event) => setSelectedOperation(event.target.value)}
        >
          <option value=''>เลือกตัวดำเนินการ</option>
          {gaugeOperation()}
        </Select>
      </Td>
      <Td>
        <Select
          className='w-1/3'
          size='sm'
          onChange={(event) => {
            setRightVariable(event.target.value);
          }}
        >
          <option value=''>เลือกตัวแปร</option>
          {gaugeVariableList()}
        </Select>
      </Td>
      <Td>
        <Button
          colorScheme='blue'
          size='sm'
          className='self-center'
          type='button'
          onClick={() => handlingAddOperation()}
        >
          เพิ่ม
        </Button>
      </Td>
    </Tr>
  );
}
