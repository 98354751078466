/*eslint-disable*/
import React, { useState, useEffect, useContext } from 'react'
import { firebaseAuth } from 'contexts/AuthContext'
import { Link, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Badge } from '@chakra-ui/react'
import AppLogo from '../../../assets/img/eiotlogo.png'

export default function Sidebar() {
    const [collapseShow, setCollapseShow] = React.useState('hidden')
    const { handleSignout } = useContext(firebaseAuth)
    const history = useHistory()
    const me = useSelector((state) => state.me)

    const system = useSelector((state) => state.system)
    const onSignOut = async () => {
        await handleSignout()
        history.push('/login')
    }

    useEffect(() => {
        if (me && me._id) {
        }
        return () => {}
    }, [me])

    if (system && system.isLoading === true)
        return (
            <>
                <nav className="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-no-wrap md:overflow-hidden shadow-xl bg-white flex flex-wrap items-center justify-between relative md:w-64 z-30 py-4 px-6 print-disable">
                    <div className="md:flex-col md:items-stretch md:min-h-full md:flex-no-wrap px-0 flex flex-wrap items-center justify-between w-full mx-auto">
                        {/* Toggler */}
                        <button
                            className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                            type="button"
                            onClick={() =>
                                setCollapseShow('bg-white m-2 py-3 px-6')
                            }
                        >
                            <i className="fas fa-bars"></i>
                        </button>
                        {/* Brand */}
                        <Link
                            className="md:block text-left md:pb-2 text-gray-700 mr-0 inline-block whitespace-no-wrap text-base uppercase font-sans font-bold p-4 px-0"
                            to="/"
                        >
                            <div className="flex flex-wrap gap-2 ">
                                <img
                                    src={system.logo ? system.logo : AppLogo}
                                    className="w-auto align-middle h-16  max-w-60-px"
                                />
                                <span className="self-center mt-4">
                                    {system.name}
                                </span>
                            </div>
                        </Link>

                        <div
                            className={
                                'md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded ' +
                                collapseShow
                            }
                        >
                            {/* Collapse header */}
                            <div className="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-gray-300">
                                <div className="flex flex-wrap">
                                    <div className="w-6/12">
                                        <Link
                                            className="md:block text-left md:pb-2 text-gray-700 mr-0 inline-block whitespace-no-wrap text-sm uppercase font-bold p-4 px-0"
                                            to="/"
                                        >
                                            <div className="flex gap-2">
                                                <img
                                                    src={system.logo}
                                                    className="w-12 h-12"
                                                />
                                                <span className="self-center">
                                                    {system.name}
                                                </span>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="w-6/12 flex justify-end">
                                        <button
                                            type="button"
                                            className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                                            onClick={() =>
                                                setCollapseShow('hidden')
                                            }
                                        >
                                            <i className="fas fa-times"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            {/* Heading */}
                            <h6 className="md:min-w-full text-gray-600 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
                                การแจ้งเตือน
                            </h6>
                            {/* Navigation */}

                            <ul className="md:flex-col md:min-w-full flex flex-col list-none">
                                <li className="items-center">
                                    <Link
                                        className={
                                            'text-xs uppercase py-3 font-bold block text-gray-800 hover:text-gray-600 '
                                        }
                                        to="/"
                                    >
                                        <i
                                            className={
                                                'fas fa-home mr-2 text-sm text-gray-400 '
                                            }
                                        ></i>{' '}
                                        หน้าแรก
                                    </Link>
                                </li>
                                <li className="items-center">
                                    <Link
                                        className={
                                            'text-xs uppercase py-3 font-bold block ' +
                                            (window.location.href.indexOf(
                                                '/notify'
                                            ) !== -1
                                                ? 'text-blue-500 hover:text-blue-600'
                                                : 'text-gray-800 hover:text-gray-600')
                                        }
                                        to="/notify"
                                    >
                                        <i
                                            className={
                                                'fas fa-file-invoice mr-2 text-sm ' +
                                                (window.location.href.indexOf(
                                                    '/notify'
                                                ) !== -1
                                                    ? 'opacity-75'
                                                    : 'text-gray-400')
                                            }
                                        ></i>{' '}
                                        การแจ้งเตือน
                                    </Link>
                                </li>
                            </ul>

                            <hr className="my-4 md:min-w-full" />
                            <ul className="md:flex-col md:min-w-full flex flex-col list-none md:mb-4">
                                <li className="items-center">
                                    <div className="text-gray-800 hover:text-gray-600 text-xs uppercase py-3 font-bold block ">
                                        <i className="fas fa-user text-gray-500 mr-2 text-sm"></i>{' '}
                                        {me.name}{' '}
                                        <Badge
                                            colorScheme="gray"
                                            className="mb-1"
                                        >
                                            {me.role}
                                        </Badge>
                                    </div>
                                </li>
                                <li className="items-center">
                                    <div
                                        className="text-gray-800 hover:text-gray-600 text-xs uppercase py-3 font-bold block cursor-pointer"
                                        onClick={() => {
                                            onSignOut()
                                        }}
                                    >
                                        <i className="fas fa-fingerprint text-gray-500 mr-2 text-sm"></i>{' '}
                                        Sign Out
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </>
        )
    else {
        return <></>
    }
}
