import React from 'react'
import _ from 'lodash'

import Config from '../../../../config'
import VariableMainRendering from '../../../Variables/Function/VariableMainRendering'

export default function DisplayTwoStepBooleanAttribute({
    stationData,
    attributeData,
}) {
    try {
        if (
            attributeData.dataSource === Config.TypeOfSource.GaugeSource.sensor
        ) {
            const sensorSourceData = _.find(
                stationData.sensors,
                (sensor) => sensor.refString === attributeData.sensorRef
            )

            if (sensorSourceData) {
                if (
                    attributeData.secondDataSource ===
                    Config.TypeOfSource.GaugeSource.sensor
                ) {
                    const secondSensorSourceData = _.find(
                        stationData.sensors,
                        (sensor) =>
                            sensor.refString === attributeData.secondSensorRef
                    )
                    return (
                        <div>
                            {attributeData.label}
                            <div className="flex ">
                                {sensorSourceData.booleanStatus === true &&
                                secondSensorSourceData.booleanStatus ===
                                    true ? (
                                    <>
                                        <div
                                            className={`rounded-full circle bg-${attributeData.colorOnTwoStep?.trueTrue}-600 w-2 my-2 h-1/2 align-middle`}
                                        ></div>
                                        <div className="w-5/6 pl-1">
                                            {
                                                attributeData.labelOnTwoStep
                                                    ?.trueTrue
                                            }
                                        </div>
                                    </>
                                ) : (
                                    <></>
                                )}
                                {sensorSourceData.booleanStatus === true &&
                                secondSensorSourceData.booleanStatus ===
                                    false ? (
                                    <>
                                        <div
                                            className={`rounded-full circle bg-${attributeData.colorOnTwoStep?.trueFalse}-600 w-2 my-2 h-1/2 align-middle`}
                                        ></div>
                                        <div className="w-5/6 pl-1">
                                            {
                                                attributeData.labelOnTwoStep
                                                    ?.trueFalse
                                            }
                                        </div>
                                    </>
                                ) : (
                                    <></>
                                )}
                                {sensorSourceData.booleanStatus === false &&
                                secondSensorSourceData.booleanStatus ===
                                    true ? (
                                    <>
                                        <div
                                            className={`rounded-full circle bg-${attributeData.colorOnTwoStep?.falseTrue}-600 w-2 my-2 h-1/2 align-middle`}
                                        ></div>
                                        <div className="w-5/6 pl-1">
                                            {
                                                attributeData.labelOnTwoStep
                                                    ?.falseTrue
                                            }
                                        </div>
                                    </>
                                ) : (
                                    <></>
                                )}
                                {sensorSourceData.booleanStatus === false &&
                                secondSensorSourceData.booleanStatus ===
                                    false ? (
                                    <>
                                        <div
                                            className={`rounded-full circle bg-${attributeData.colorOnTwoStep?.falseFalse}-600 w-2 my-2 h-1/2 align-middle`}
                                        ></div>
                                        <div className="w-5/6 pl-1">
                                            {
                                                attributeData.labelOnTwoStep
                                                    ?.falseFalse
                                            }
                                        </div>
                                    </>
                                ) : (
                                    <></>
                                )}
                            </div>
                        </div>
                    )
                } else {
                    const secondVariableData = VariableMainRendering(
                        stationData,
                        attributeData.secondVariableId
                    )
                    return (
                        <div>
                            {attributeData.label}
                            <div className="flex ">
                                {sensorSourceData.booleanStatus === true &&
                                secondVariableData === true ? (
                                    <>
                                        <div
                                            className={`rounded-full circle bg-${attributeData.colorOnTwoStep?.trueTrue}-600 w-2 my-2 h-1/2 align-middle`}
                                        ></div>
                                        <div className="w-5/6 pl-1">
                                            {
                                                attributeData.labelOnTwoStep
                                                    ?.trueTrue
                                            }
                                        </div>
                                    </>
                                ) : (
                                    <></>
                                )}
                                {sensorSourceData.booleanStatus === true &&
                                secondVariableData === false ? (
                                    <>
                                        <div
                                            className={`rounded-full circle bg-${attributeData.colorOnTwoStep?.trueFalse}-600 w-2 my-2 h-1/2 align-middle`}
                                        ></div>
                                        <div className="w-5/6 pl-1">
                                            {
                                                attributeData.labelOnTwoStep
                                                    ?.trueFalse
                                            }
                                        </div>
                                    </>
                                ) : (
                                    <></>
                                )}
                                {sensorSourceData.booleanStatus === false &&
                                secondVariableData === true ? (
                                    <>
                                        <div
                                            className={`rounded-full circle bg-${attributeData.colorOnTwoStep?.falseTrue}-600 w-2 my-2 h-1/2 align-middle`}
                                        ></div>
                                        <div className="w-5/6 pl-1">
                                            {
                                                attributeData.labelOnTwoStep
                                                    ?.falseTrue
                                            }
                                        </div>
                                    </>
                                ) : (
                                    <></>
                                )}
                                {sensorSourceData.booleanStatus === false &&
                                secondVariableData === false ? (
                                    <>
                                        <div
                                            className={`rounded-full circle bg-${attributeData.colorOnTwoStep?.falseFalse}-600 w-2 my-2 h-1/2 align-middle`}
                                        ></div>
                                        <div className="w-5/6 pl-1">
                                            {
                                                attributeData.labelOnTwoStep
                                                    ?.falseFalse
                                            }
                                        </div>
                                    </>
                                ) : (
                                    <></>
                                )}
                            </div>
                        </div>
                    )
                }
            } else {
                return <></>
            }
        } else {
            const variableData = VariableMainRendering(
                stationData,
                attributeData.variableId
            )

            if (
                attributeData.secondDataSource ===
                Config.TypeOfSource.GaugeSource.sensor
            ) {
                const secondSensorSourceData = _.find(
                    stationData.sensors,
                    (sensor) =>
                        sensor.refString === attributeData.secondSensorRef
                )
                return (
                    <div>
                        {attributeData.label}
                        <div className="flex ">
                            {variableData === true &&
                            secondSensorSourceData.booleanStatus === true ? (
                                <>
                                    <div
                                        className={`rounded-full circle bg-${attributeData.colorOnTwoStep?.trueTrue}-600 w-2 my-2 h-1/2 align-middle`}
                                    ></div>
                                    <div className="w-5/6 pl-1">
                                        {attributeData.labelOnTwoStep?.trueTrue}
                                    </div>
                                </>
                            ) : (
                                <></>
                            )}
                            {variableData === true &&
                            secondSensorSourceData.booleanStatus === false ? (
                                <>
                                    <div
                                        className={`rounded-full circle bg-${attributeData.colorOnTwoStep?.trueFalse}-600 w-2 my-2 h-1/2 align-middle`}
                                    ></div>
                                    <div className="w-5/6 pl-1">
                                        {
                                            attributeData.labelOnTwoStep
                                                ?.trueFalse
                                        }
                                    </div>
                                </>
                            ) : (
                                <></>
                            )}
                            {variableData === false &&
                            secondSensorSourceData.booleanStatus === true ? (
                                <>
                                    <div
                                        className={`rounded-full circle bg-${attributeData.colorOnTwoStep?.falseTrue}-600 w-2 my-2 h-1/2 align-middle`}
                                    ></div>
                                    <div className="w-5/6 pl-1">
                                        {
                                            attributeData.labelOnTwoStep
                                                ?.falseTrue
                                        }
                                    </div>
                                </>
                            ) : (
                                <></>
                            )}
                            {variableData === false &&
                            secondSensorSourceData.booleanStatus === false ? (
                                <>
                                    <div
                                        className={`rounded-full circle bg-${attributeData.colorOnTwoStep?.falseFalse}-600 w-2 my-2 h-1/2 align-middle`}
                                    ></div>
                                    <div className="w-5/6 pl-1">
                                        {
                                            attributeData.labelOnTwoStep
                                                ?.falseFalse
                                        }
                                    </div>
                                </>
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>
                )
            } else {
                const secondVariableData = VariableMainRendering(
                    stationData,
                    attributeData.secondVariableId
                )
                return (
                    <div>
                        {attributeData.label}
                        <div className="flex ">
                            {variableData === true &&
                            secondVariableData === true ? (
                                <>
                                    <div
                                        className={`rounded-full circle bg-${attributeData.colorOnTwoStep?.trueTrue}-600 w-2 my-2 h-1/2 align-middle`}
                                    ></div>
                                    <div className="w-5/6 pl-1">
                                        {attributeData.labelOnTwoStep?.trueTrue}
                                    </div>
                                </>
                            ) : (
                                <></>
                            )}
                            {variableData === true &&
                            secondVariableData === false ? (
                                <>
                                    <div
                                        className={`rounded-full circle bg-${attributeData.colorOnTwoStep?.trueFalse}-600 w-2 my-2 h-1/2 align-middle`}
                                    ></div>
                                    <div className="w-5/6 pl-1">
                                        {
                                            attributeData.labelOnTwoStep
                                                ?.trueFalse
                                        }
                                    </div>
                                </>
                            ) : (
                                <></>
                            )}
                            {variableData === false &&
                            secondVariableData === true ? (
                                <>
                                    <div
                                        className={`rounded-full circle bg-${attributeData.colorOnTwoStep?.falseTrue}-600 w-2 my-2 h-1/2 align-middle`}
                                    ></div>
                                    <div className="w-5/6 pl-1">
                                        {
                                            attributeData.labelOnTwoStep
                                                ?.falseTrue
                                        }
                                    </div>
                                </>
                            ) : (
                                <></>
                            )}
                            {variableData === false &&
                            secondVariableData === false ? (
                                <>
                                    <div
                                        className={`rounded-full circle bg-${attributeData.colorOnTwoStep?.falseFalse}-600 w-2 my-2 h-1/2 align-middle`}
                                    ></div>
                                    <div className="w-5/6 pl-1">
                                        {
                                            attributeData.labelOnTwoStep
                                                ?.falseFalse
                                        }
                                    </div>
                                </>
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>
                )
            }
        }
    } catch (error) {
        return <div></div>
    }
}
