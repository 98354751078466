import React from 'react'
import _ from 'lodash'

import Config from '../../../../config'
import VariableMainRendering from '../../../Variables/Function/VariableMainRendering'

export default function DisplayStringAttribute({ stationData, attributeData }) {
    if (attributeData.dataSource === Config.TypeOfSource.GaugeSource.sensor) {
        const sensorSourceData = _.find(
            stationData.sensors,
            (sensor) => sensor.refString === attributeData.sensorRef
        )
        return (
            <div>
                {attributeData.label}
                <br />
                {sensorSourceData.stringStatus}
            </div>
        )
    } else {
        const variableData = VariableMainRendering(
            stationData,
            attributeData.variableId
        )
        return (
            <div>
                {attributeData.label}
                <br />
                {variableData}
            </div>
        )
    }
}
