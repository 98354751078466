import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { Button } from '@chakra-ui/react'
import { useSelector, useDispatch } from 'react-redux'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import SpinnerLoading from 'components/Loading/SpinnerLoading'

import { useParams, useHistory, Link } from 'react-router-dom'
import * as actions from '../../../../redux/actions'
import Config from '../../../../config'

export default function EditDiagramOrder() {
    const [isLoading, setIsLoading] = useState(false)
    const [orderedDiagram, setOrderedDiagram] = useState([])
    const params = useParams()
    const allStations = useSelector((state) => state.station)
    const [currentStation, setCurrentStation] = useState()
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(actions.stationAll()).then(() => {
            setIsLoading(true)
        })
        return () => {}
    }, [params])

    useEffect(() => {
        if (allStations) {
            const tempcurrentStatus = _.find(
                allStations.arr,
                (station) => station._id === params.id
            )
            setCurrentStation(tempcurrentStatus)
            console.log('Curent Station', currentStation)
        }
        return () => {}
    }, [params, allStations])

    useEffect(() => {
        if (currentStation) {
            console.log('Order diagram by order level')
            let orderedDiagram = _.orderBy(
                currentStation.diagrams,
                'orderLevel',
                'asc'
            )
            setOrderedDiagram(orderedDiagram)
        }
    }, [currentStation])

    const saveOrdered = () => {
        let confirm = window.confirm('ยืนยันการจัดเรียง')
        if (confirm) {
            let newDiagramOrder = Array.from(orderedDiagram)
            _.map(newDiagramOrder, (diagram, index) => {
                diagram.orderLevel = index
            })
            // console.log('New Rendered ', newDiagramOrder)
            let removedOldOrderStation = currentStation
            delete removedOldOrderStation.diagrams
            let newStationData = _.merge(removedOldOrderStation, {
                diagrams: newDiagramOrder,
            })
            //console.log(newStationData)
            dispatch(
                actions.stationPut(newStationData._id, newStationData)
            ).then(() => {
                dispatch(actions.stationAll())
            })
        }
    }

    const genDiagramList = (provided, snapshot) => {
        return _.map(orderedDiagram, (selectedDiagram, index) => (
            <Draggable
                key={selectedDiagram._id}
                draggableId={selectedDiagram._id}
                index={index}
            >
                {(provided, snapshot) => (
                    <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className="font-sans"
                    >
                        <div className="p-4 m-2 border-gray-400 border-2 rounded-md">
                            <img
                                src={selectedDiagram.diagramImage}
                                className="h-12 mb-1"
                            />
                            <h6 className="text-center font-semibold">
                                {selectedDiagram.diagramName}{' '}
                            </h6>
                        </div>
                    </div>
                )}
            </Draggable>
        ))
    }

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list)
        const [removed] = result.splice(startIndex, 1)
        result.splice(endIndex, 0, removed)
        return result
    }

    const onBeforeCapture = () => {
        /*...*/
    }

    const onBeforeDragStart = () => {
        /*...*/
    }

    const onDragStart = () => {
        /*...*/
    }
    const onDragUpdate = () => {
        /*...*/
    }
    const onDragEnd = (result) => {
        // the only one that is required
        if (!result.destination && !result.source) {
            return
        } else {
            const dragableId = result.draggableId
            const sourcePosition = result.source?.index
            const destinationPosition = result.destination?.index
            console.log(
                'Source Position ' +
                    sourcePosition +
                    'Destination Position' +
                    destinationPosition
            )

            let reOrderItems = reorder(
                orderedDiagram,
                sourcePosition,
                destinationPosition
            )
            console.log('Reorder Item', reOrderItems)
            setOrderedDiagram(reOrderItems)
        }
    }

    if (currentStation && isLoading) {
        return (
            <>
                <div className="relative flex flex-col min-w-0 break-words bg-gray-100 w-full mb-6 shadow-lg rounded">
                    <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
                        <div className="flex flex-wrap items-center">
                            <div className="relative w-full max-w-full flex-grow flex-1">
                                <h6 className="uppercase text-gray-500 mb-1 text-xs font-semibold font-sans">
                                    จัดการระบบ
                                </h6>
                                <h2 className="text-gray-800 text-2xl font-bold font-sans">
                                    จัดการการจัดเรียงไดอะแกรม{' '}
                                    {currentStation.name}
                                </h2>
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-between p-4">
                        <div className="flex justify-items-start">
                            {' '}
                            <Button
                                colorScheme="green"
                                variant="solid"
                                size="sm"
                                onClick={() => saveOrdered()}
                            >
                                บันทึกการจัดเรียง
                            </Button>
                        </div>
                        <div className="flex justify-items-end"></div>
                    </div>
                    <div className="p-4 ">
                        <DragDropContext
                            onBeforeCapture={onBeforeCapture}
                            onBeforeDragStart={onBeforeDragStart}
                            onDragStart={onDragStart}
                            onDragUpdate={onDragUpdate}
                            onDragEnd={onDragEnd}
                        >
                            <Droppable
                                droppableId="orderDropable"
                                direction="horizontal"
                            >
                                {(provided, snapshot) => (
                                    <div
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                        className="flex overflow-x-auto"
                                    >
                                        {genDiagramList(provided, snapshot)}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </div>
                </div>
            </>
        )
    } else {
        return <SpinnerLoading />
    }
}
