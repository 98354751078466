import React from 'react'
import { Tr, Td, Button } from '@chakra-ui/react'
import GenerateMathematicSymbol from '../../../util/GenerateMathematicSymbol'
import { VariableTypeOfSource } from '../../../config/VariableTypeOfSource'
export default function SubvariableShowing({
    subVariable,
    index,
    deleteSubvariable,
}) {
    return (
        <>
            <Tr className="text-sm" key={index}>
                <Td> {subVariable.subVariableName} </Td>
                <Td> {subVariable.typeOfSource}</Td>
                <Td>
                    {subVariable.typeOfSource ===
                        VariableTypeOfSource.constant &&
                        subVariable.constantValue}
                    {subVariable.typeOfSource === VariableTypeOfSource.sensor &&
                        subVariable.sensorRef}
                    {subVariable.typeOfSource ===
                        VariableTypeOfSource.multiSensor && (
                        <>
                            {subVariable.isReverseLeft === true ? '!' : ''}{' '}
                            {subVariable.leftSensorRef}{' '}
                            {GenerateMathematicSymbol(subVariable.operation)}{' '}
                            {subVariable.isReverseRight === true ? '!' : ''}{' '}
                            {subVariable.rightSensorRef}
                        </>
                    )}
                    {subVariable.typeOfSource ===
                        VariableTypeOfSource.sensorAndConstant && (
                        <>
                            {subVariable.leftConstant}
                            {GenerateMathematicSymbol(
                                subVariable.operation
                            )}{' '}
                            {subVariable.isReverseRight === true ? '!' : ''}{' '}
                            {subVariable.rightSensorRef}
                        </>
                    )}
                </Td>
                <Td>
                    {' '}
                    <Button
                        size="sm"
                        colorScheme="red"
                        onClick={() => deleteSubvariable(index)}
                    >
                        ลบ
                    </Button>{' '}
                </Td>
            </Tr>
        </>
    )
}
