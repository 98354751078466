import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import _ from "lodash";
import {
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Switch,
} from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import SpinnerLoading from "components/Loading/SpinnerLoading";
import ImageUpload from "../../../../components/ImageUpload/ImageUpload";
import { uploadImageFile } from "../../../../util/uploadImage";
import { useHistory } from "react-router-dom";
import * as actions from "../../../../redux/actions";
import hash from "object-hash";

export default function EditSystemInfo({ systemInfo }) {
  const { register, handleSubmit, reset } = useForm();
  const [uploadedLogo, setUploadedLogo] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    return () => {};
  }, []);

  const onSubmit = async (data, e) => {
    console.log(uploadedLogo);
    let confirm = window.confirm("อัพเดทข้อมูล");
    if (confirm) {
      e.preventDefault();
      if (_.size(uploadedLogo)) {
        const filename = hash({ uploadedLogo, date: new Date() });
        uploadImageFile(filename, uploadedLogo[0].data_url).then(
          async (url) => {
            console.log("URL : " + url);

            data.logo = url;
            e.preventDefault();
            console.log(data);
            await dispatch(actions.systemInfoPut(systemInfo._id, data));
            alert("บันทึกสำเร็จ");
            setUploadedLogo([]);
          }
        );
      } else {
        await dispatch(actions.systemInfoPut(systemInfo._id, data));
        alert("บันทึกสำเร็จ");
        setUploadedLogo([]);
      }

      // history.goBack();
    }
  };

  if (systemInfo) {
    return (
      <>
        {" "}
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-wrap justify-between pb-8">
            <div className="w-full lg:w-6/12 px-4">
              <h6 className="text-gray-500 text-base mt-3 mb-6 font-bold uppercase font-sans ">
                จัดการข้อมูลระบบ
              </h6>
              <div className="flex flex-wrap">
                <div className="w-full px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-gray-700 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      ชื่อระบบ
                    </label>
                    <input
                      name="name"
                      type="text"
                      required
                      ref={register}
                      defaultValue={systemInfo.name}
                      className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                      placeholder="ชื่อระบบ"
                    />
                  </div>
                </div>

                <div className="w-full px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-gray-700 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      คำอธิบายเกี่ยวกับระบบ
                    </label>
                    <textarea
                      name="description"
                      type="text"
                      ref={register}
                      defaultValue={systemInfo.description}
                      className="px-3 py-3 placeholder-gray-400 text-gray-700  bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                      placeholder="ระบบไอโอทีนี้ประกอบไปด้วยเซ็นเซอร์วัดระดับน้ำฝน นำมาคำนวณผ่านคอมพิวเตอร์"
                    />
                  </div>
                </div>

                <div className="w-full px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-gray-700 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      ชื่อบริษัท / หน่วยงาน เจ้าของระบบ
                    </label>

                    <input
                      name="systemOwner"
                      type="text"
                      ref={register}
                      defaultValue={systemInfo.systemOwner}
                      className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                      placeholder="My Company Co.,ltd."
                    />
                  </div>
                </div>

                <div className="w-full px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-gray-700 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      เว็บไซต์เจ้าของระบบ
                    </label>

                    <input
                      name="systemOwnerURL"
                      type="text"
                      ref={register}
                      defaultValue={systemInfo.systemOwnerURL}
                      className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                      placeholder="https://www.psu.ac.th"
                    />
                  </div>
                </div>

                <div className="w-full px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-gray-700 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      ชื่อผู้สนับสนุนระบบ
                    </label>

                    <input
                      name="systemSponsor"
                      type="text"
                      ref={register}
                      defaultValue={systemInfo.systemSponsor}
                      className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                      placeholder="PSU Science Park"
                    />
                  </div>
                </div>

                <div className="w-full px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-gray-700 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      เว็บไซต์ผู้สนับสนุนระบบ
                    </label>

                    <input
                      name="systemSponsorURL"
                      type="text"
                      ref={register}
                      defaultValue={systemInfo.systemSponsorURL}
                      className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                      placeholder="https://psusp.or.th/"
                    />
                  </div>
                </div>

                <div className="w-full px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-gray-700 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      โลโก้ระบบ
                    </label>
                    <ImageUpload
                      images={uploadedLogo}
                      setImages={setUploadedLogo}
                      preview_size="250"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="w-full lg:w-6/12 px-4">
              <h6 className="text-gray-500 text-base mt-3 mb-6 font-bold uppercase font-sans ">
                การแสดงผลโมดูลระบบ
              </h6>

              <div className="w-full px-4 pb-3">
                <FormControl display="flex">
                  <FormLabel>
                    <div className="block uppercase text-gray-700 text-xs font-bold mb-2">
                      โมดูลเฝ้าระวังและตรวจสอบระบบ
                    </div>
                  </FormLabel>
                  <Switch
                    size="lg"
                    name="enable_dashboard"
                    ref={register}
                    defaultChecked={systemInfo.enable_dashboard}
                  />
                </FormControl>
              </div>

              <div className="w-full px-4 pb-3">
                <FormControl display="flex">
                  <FormLabel>
                    <div className="block uppercase text-gray-700 text-xs font-bold mb-2">
                      โมดูลข้อมูลและสถิติ
                    </div>
                  </FormLabel>
                  <Switch
                    size="lg"
                    name="enable_statistics"
                    ref={register}
                    defaultChecked={systemInfo.enable_statistics}
                  />
                </FormControl>
              </div>
              <div className="w-full px-4 pb-3">
                <FormControl display="flex">
                  <FormLabel>
                    <div className="block uppercase text-gray-700 text-xs font-bold mb-2">
                      โมดูลการควบคุม
                    </div>
                  </FormLabel>
                  <Switch
                    size="lg"
                    name="enable_controls"
                    ref={register}
                    defaultChecked={systemInfo.enable_controls}
                  />
                </FormControl>
              </div>

              <div className="w-full px-4 pb-3">
                <FormControl display="flex">
                  <FormLabel>
                    <div className="block uppercase text-gray-700 text-xs font-bold mb-2">
                      โมดูลประมาณการบิลค่าไฟฟ้า
                    </div>
                  </FormLabel>
                  <Switch
                    size="lg"
                    name="enable_ebe"
                    ref={register}
                    defaultChecked={systemInfo.enable_ebe}
                  />
                </FormControl>
              </div>

              
              <div className="w-full px-4 pb-3">
                <FormControl display="flex">
                  <FormLabel>
                    <div className="block uppercase text-gray-700 text-xs font-bold mb-2">
                      โมดูลการแจ้งเตือน
                    </div>
                  </FormLabel>
                  <Switch
                    size="lg"
                    name="enable_notify_module"
                    ref={register}
                    defaultChecked={systemInfo.enable_notify_module }
                  />
                </FormControl>
              </div>

              <div className="w-full px-4 pb-3">
                <FormControl display="flex">
                  <FormLabel>
                    <div className="block uppercase text-gray-700 text-xs font-bold mb-2">
                      โมดูลจัดการระบบ
                    </div>
                  </FormLabel>
                  <Switch
                    size="lg"
                    name="enable_system"
                    ref={register}
                    isDisabled={true}
                    defaultChecked={systemInfo.enable_system}
                  />
                </FormControl>
              </div>
            </div>
          </div>{" "}
          <div className="text-center px-4 gap-0 py-3 ">
            <Button
              isFullWidth
              colorScheme="blue"
              variant="solid"
              type="submit"
            >
              บันทึก{" "}
            </Button>
          </div>
        </form>{" "}
      </>
    );
  } else {
    return <SpinnerLoading />;
  }
}
